/* eslint-disable */
import React, { useState, useEffect } from "react";
import { ClearFormSetFutios, NavigationPointList  } from "../../redux/actions/adminActions";
import { useSelector, useDispatch } from "react-redux";
import DeleteModal from "../Common/DeleteModal";
import Pointlist from "./Pointlist";
import PointSettings from "./PointSettings";

const PointValue = (props) => {
    const { RouteName } = props;
    const { pointListNaviGation } = useSelector((state) => state.adminReducers);
    const Dispatch = useDispatch();
    const [ deleteCommonStateModal, setdeleteCommonStateModal ] = useState({
        title: "",
        description: "",
        open: false,
        delete_id: "",
        delete_url: ""
    });

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    return(<section className="Main_section">
         <div className="MainHeader">
            <ul className="MainHeader_Tab">
                <li className={(pointListNaviGation === undefined ? "Point_list" : pointListNaviGation) === "Point_list" ? "active" : ""} onClick={() => Dispatch(NavigationPointList("Point_list"))}>Point List</li>
                <li className={pointListNaviGation === "Pont_settings" ? "active" : ""} onClick={() => Dispatch(NavigationPointList("Pont_settings"))}>Point Settings</li>
            </ul>
        </div>
        {(pointListNaviGation === undefined ? "Point_list" : pointListNaviGation) === "Point_list" ? (
            <Pointlist />
        ) : (
            <PointSettings 
                deleteCommonStateModal={deleteCommonStateModal} 
                setdeleteCommonStateModal={setdeleteCommonStateModal} 
            />
        )}
        {deleteCommonStateModal?.open && (<DeleteModal setdeleteCommonStateModal={setdeleteCommonStateModal} deleteCommonStateModal={deleteCommonStateModal} />)}
    </section>)
}

export default PointValue;