/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Collapse, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, Link } from "react-router-dom";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../../Common/CommonPagination";
import { WebSocketContext } from "../../../App";
import Select from 'react-select';
import CommonDateTime from "../../Common/CommonDateTime";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import { API_URL } from "../../Shared/constant";
import CommonExport from "../../Common/CommonExport";

const StockLevel = (props) => {
    const { RouteName } = props;
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const { device_id, storedLevelReportList, wareHouselistall, productlistall, categorylistall, selfInfo } = useSelector((state) => state.adminReducers);
    const [userSearch, setUserSearch] = useState("");
    const [userType, setUserType] = useState("");
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectWarehouse, setselectWarehouse] = useState("");
    const [selectProduct, setselectProduct] = useState("");
    const [selectCategory, setselectCategory] = useState("");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");
    const [FilterOpen, setFilterOpen] = useState(false);
    const optionsWarehouse = wareHouselistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ key: item.id, value: item?.id, label: `${item?.warehouse_name} - (${item?.warehouse_code})- (${item?.display_name})` }));
    const optionsProducts = productlistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.product_name }));
    const optionsCategory = categorylistall?.filter((elm) => elm?.is_active === true)?.map((item, index) => ({ key: item.id, value: item?.id, label: item?.category_name }));
    const today = new Date();
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            setFromDate("");
            setToDate("");
        } else {
            setFromDate(date)
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };

    // select end date
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if (date === null) {
            // setEndDate("")
            let selectedDate = new Date(FromDate);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            setToDate("")
        } else {
            // setEndDate(date)
            setToDate(date);
        }
    };

    // get product inward_qty
    const GetProductSTockValue = (product_id, inward_qty, transfer_qty) => {
        const CurrentProduct = productlistall?.filter((elm) => elm?.id === product_id)[0];
        return Math.round((parseFloat(CurrentProduct?.mrp) * (parseInt(inward_qty) - parseInt(transfer_qty))) * 100) / 100;
    };

    // get all city list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[1]?.Edit || PermissionsAccess?.Store[1]?.View)) {
            let param = {
                "transmit": "broadcast",
                "url": "product_list",
                "request": {
                    "status": "",
                    "limit": 5000,
                    "page_no": 1,
                    "search": ""
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get all warehouse list
    useEffect(() => {
        if (selfInfo?.user?.user_type === 'super_admin' || selfInfo?.user?.user_type === 'admin' || selfInfo?.user?.user_type === 'regional_sales_team_asm' || selfInfo?.user?.user_type === 'regional_manager' || selfInfo?.user?.user_type === 'regional_sales_team_rm' || selfInfo?.user?.user_type === 'warehouse_manager') {
            let param = {
                "transmit": "broadcast",
                "url": "warehouse_list",
                "request": {
                    "status": "",
                    "limit": 5000,
                    "page_no": 1,
                    "search": ""
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        }
    }, []);

    // get brand all
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[1]?.Edit || PermissionsAccess?.Store[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "category_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }
    }, []);

    // get product list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[1]?.Edit || PermissionsAccess?.Store[1]?.View)) {
            if (FromDate === "" && ToDate === "") {
                let param = {
                    "transmit": "broadcast",
                    "url": "store_level_list",
                    "request": {
                        "status": userType,
                        "limit": userLimit,
                        "page_no": currentPage,
                        "search": userSearch.trim(),
                        "from_date": null,
                        "to_date": null,
                        "warehouse_id": selectWarehouse,
                        "product_id": selectProduct,
                        "category_id": selectCategory,
                    },
                    "DeviceId": device_id
                };
                wsSend_request(websocket, param);
            } else if (FromDate !== "" && ToDate !== "") {
                let param = {
                    "transmit": "broadcast",
                    "url": "store_level_list",
                    "request": {
                        "status": userType,
                        "limit": userLimit,
                        "page_no": currentPage,
                        "search": userSearch.trim(),
                        "from_date": CommonDateTime(FromDate, "YYYY-MM-DD", "hide"),
                        "to_date": CommonDateTime(ToDate, "YYYY-MM-DD", "hide"),
                        "warehouse_id": selectWarehouse,
                        "product_id": selectProduct,
                        "category_id": selectCategory,
                    },
                    "DeviceId": device_id
                };
                wsSend_request(websocket, param);
            }
        };
    }, [userSearch, userType, currentPage, userLimit, selectWarehouse, selectProduct,selectCategory, FromDate, ToDate]);


    window.addEventListener('load', function () {
        // Code to call API when the window loads
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[1]?.Edit || PermissionsAccess?.Store[1]?.View)) {
            let param = {
                "transmit": "broadcast",
                "url": "store_level_list",
                "request": {
                    "status": userType,
                    "limit": userLimit,
                    "page_no": currentPage,
                    "search": userSearch.trim(),
                    "from_date": null,
                    "to_date": null,
                    "warehouse_id": selectWarehouse,
                    "product_id": selectProduct,
                    "category_id": selectCategory,
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        };
    });

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // wareehouse select
    const WareHouseSelect = (value) => {
        setselectWarehouse(value?.value);
        setCurrentPage(1);
    };

    // Product select
    const ProductSelect = (value) => {
        setselectProduct(value?.value);
        setCurrentPage(1);
    };

    // category select
    const CategorySelect = (value) => {
        setselectCategory(value?.value);
        setCurrentPage(1);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };



    // Sample array of numbers
    const numbers = storedLevelReportList?.list;

    // Function to calculate the sum of numbers in an array
    function sumArray(arr) {
        if (arr?.length > 0) {
            let sum = 0;
            for (let i = 0; i < arr?.length; i++) {
                sum += parseInt(arr[i]?.inward_qty);
            }
            return sum;
        }
    }

    // Call the function with your array
    const total = sumArray(numbers);


    // total table count
    const TotalTableCount = (list, url) => {
        if (list?.length > 0) {
            if (url === "inward_qty") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.inward_qty);
                }
                return sum;
            } else if (url === "transfer_qty") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.transfer_qty);
                }
                return sum;
            } else if (url === "inward_qty - transfer_qty") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.inward_qty - list[i]?.transfer_qty);
                }
                return sum;
            } else if(url === "inward_qty - invoiceable_qty") {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += parseInt(list[i]?.inward_qty - list[i]?.invoiceable_qty);
                }
                return sum;
            } else {
                let sum = 0;
                for (let i = 0; i < list.length; i++) {
                    sum += GetProductSTockValue(list[i]?.product_id, list[i]?.inward_qty, list[i]?.transfer_qty);
                }
                return sum;
            };
        } else {
            return 0;
        };
    };

    // select limit
    const SelectLimitFct = (e) => {
        setUserLimit(parseInt(e.target.value));
        setCurrentPage(1);
    };


    function displayZeroIfNegative(number) {
        if (number < 0) {
            return 0;
        } else {
            return number;
        }
    };

    return (<section className="Main_section">
        <div className="filtr_Form" style={{ borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3">
                        <Form.Group className="mb-3">
                            <div className="datepickField min">
                                <DatePicker
                                    selected={FromDate}
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    onChange={(date) => DateStartGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter From Date"
                                    isClearable={FromDate !== "" ? true : false}
                                    name="statDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" >
                        <Form.Group className="mb-3">
                            <div className="datepickField">
                                <DatePicker
                                    selected={ToDate}
                                    startDate={FromDate}
                                    endDate={ToDate}
                                    minDate={startDate}
                                    maxDate={endDate}
                                    onChange={(date) => DateEndGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter To Date"
                                    isClearable={ToDate !== "" ? true : false}
                                    name="ToDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3">
                        {(selfInfo?.user?.user_type === 'super_admin' || selfInfo?.user?.user_type === 'admin' || selfInfo?.user?.user_type === 'regional_sales_team_asm' || selfInfo?.user?.user_type === 'regional_manager' || selfInfo?.user?.user_type === 'regional_sales_team_rm' || selfInfo?.user?.user_type === 'warehouse_manager') && (<Form.Group className="mb-3">
                            {/* <Form.Label>Warehouse</Form.Label> */}
                            <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                <Select
                                    onChange={(e) => WareHouseSelect(e)}
                                    isClearable
                                    options={optionsWarehouse}
                                    className="godown-select-container"
                                    classNamePrefix="godown-select"
                                    placeholder="Select Warehouse"
                                    styles={customStyles}
                                />
                            </div>
                        </Form.Group>)}
                    </div>
                    <div className="col-md-3" style={{ display: "flex", alignItems: "flex-start" }}>
                        <select className="form-control userLimitselect" id="userLimitselect" value={userLimit} onChange={(e) => SelectLimitFct(e)}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                            <option value="60">60</option>
                            <option value="70">70</option>
                            <option value="80">80</option>
                            <option value="90">90</option>
                            <option value="100">100</option>
                        </select>
                        <button className="btn exportbtn" id="stocklevelExportbtn" disabled={ToDate === "" || FromDate === ""} style={{ width: "auto", float: "right" }} onClick={() => CommonExport("export_stock_level", { "FromDate": FromDate, "ToDate": ToDate, "product_id": selectProduct, "warehouse_id": selectWarehouse, "limit": userLimit })}>
                            <i className="bi bi-filetype-xlsx"></i>
                            Export
                        </button>
                        <OverlayTrigger
                            placement="left"
                            delay={{ show: 250, hide: 100 }}
                            overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                {FilterOpen ? "Filter Hide" : "Filter Show"}
                            </Tooltip>}
                        >
                            <button
                                className="filtercollapsebtn"
                                onClick={() => setFilterOpen(!FilterOpen)}
                                aria-controls="example-collapse-text"
                                aria-expanded={FilterOpen}
                            >
                                {FilterOpen ? (<i className="bi bi-funnel-fill"></i>) : (<i className="bi bi-funnel"></i>)}
                            </button>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>
            <Collapse in={FilterOpen}>
                <div className="formloginset">
                    <div className="row">
                        <div className="col-md-3">
                            <Form.Group className="mb-3">
                                {/* <Form.Label>Products</Form.Label> */}
                                <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                    <Select
                                        onChange={(e) => CategorySelect(e)}
                                        isClearable
                                        options={optionsCategory}
                                        className="godown-select-container"
                                        classNamePrefix="godown-select"
                                        placeholder="Select Category"
                                        styles={customStyles}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-3">
                            <Form.Group className="mb-3">
                                {/* <Form.Label>Products</Form.Label> */}
                                <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                    <Select
                                        onChange={(e) => ProductSelect(e)}
                                        isClearable
                                        options={optionsProducts}
                                        className="godown-select-container"
                                        classNamePrefix="godown-select"
                                        placeholder="Select Products"
                                        styles={customStyles}
                                    />
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
        <div className="tableView gstreporttableadded">
            <Scrollbars
                style={{
                    height: FilterOpen === true ?
                        storedLevelReportList?.pagination?.total_records > 10 ? "calc(100vh - 260px)" : "calc(100vh - 206px)" :
                        storedLevelReportList?.pagination?.total_records > 10 ? "calc(100vh - 206px)" : "calc(100vh - 170px)"
                }}
                renderView={props => <div {...props} className="view" />}
                
                className="ScrollbarsSidebar"
            >
                <Table bordered   >
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>Product Code</th>
                            <th>Product Name</th>
                            <th>Warehouse Name</th>
                            <th>Stock Inward</th>
                            <th>Stock Transfer</th>
                            <th>Invoiceable Qty</th>
                            {/* <th>Old Balance Stock</th> */}
                            <th>Balance Stock</th>
                            <th>Stock Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {storedLevelReportList?.list?.map((elm, index) => {
                            return (<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, storedLevelReportList?.pagination?.current_page, storedLevelReportList?.pagination?.record_limit)}
                                </td>
                                <td>{elm?.product_code}</td>
                                <td>{elm?.product_name}</td>
                                <td>{elm?.warehouse_name}</td>
                                <td>{elm?.inward_qty}</td>
                                <td>{elm?.transfer_qty}</td>
                                <td>{displayZeroIfNegative(elm?.inward_qty - elm?.transfer_qty - elm?.invoiceable_qty)}</td>
                                {/* <td>{elm?.inward_qty - elm?.transfer_qty}</td> */}
                                <td>{elm?.balance_qty}</td>
                                <td>{GetProductSTockValue(elm?.product_id, elm?.inward_qty, elm?.transfer_qty)}</td>
                            </tr>)
                        })}
                        {storedLevelReportList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="8">Stock level list not found</td></tr>}
                        <tr className="totaltd">
                            <th colSpan={4} style={{ textAlign: "center" }}>Page Total</th>
                            <th>{TotalTableCount(storedLevelReportList?.list, "inward_qty")}</th>
                            <th>{TotalTableCount(storedLevelReportList?.list, "transfer_qty")}</th>
                            <th>{TotalTableCount(storedLevelReportList?.list, "inward_qty - invoiceable_qty")}</th>
                            <th>{TotalTableCount(storedLevelReportList?.list, "inward_qty - transfer_qty")}</th>
                            <th>{TotalTableCount(storedLevelReportList?.list, "TotalProduct")}</th>
                        </tr>
                        <tr className="totaltd">
                            <th colSpan={4} style={{ textAlign: "center" }}>Grand Total</th>
                            <th>{storedLevelReportList?.grand?.grand_in}</th>
                            <th>{storedLevelReportList?.grand?.grand_tran}</th>
                            <th>{storedLevelReportList?.grand?.grand_inv}</th>
                            <th>{storedLevelReportList?.grand?.grand_bal}</th>
                            <th>{storedLevelReportList?.grand?.grand_val}</th>
                        </tr>
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {storedLevelReportList?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={currentPage}
                paginationLength={storedLevelReportList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
    </section>)
}

export default StockLevel;