/* eslint-disable */
import React, { useState, useEffect, useContext, useMemo } from "react";
import { Table, Form, Button, Modal, FormControl } from 'react-bootstrap';
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useNavigate  } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import ReactDatePicker from "react-datepicker";
import moment from "moment";

const OrderPause = (props) => {
    const { roomId } = useParams();
    const { websocket } = useContext(WebSocketContext);
    const { device_id  } = useSelector((state) => state.adminReducers);

    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        description: "",
        cancel_reason:"",
        Data: {}
    });
    const [start_date, setStart_date] = useState("")
    const [remarks, setRemarks] = useState("")


    const PauseOrder = () => {
            let param = { 
                "transmit": "broadcast", 
                "url": "order_pause",
                "request" : { 
                    "order_id" : roomId,
                    "start_time" : moment(start_date).format("YYYY-MM-DD HH:mm:ss"),
                    "pause_remark" : remarks,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
    };
    const callPauseModal = (e) =>{
        // if(showModalNew?.cancel_reason){
            setShowModalNew({
                ...showModalNew,
                open: true,
                title: "Confirmation",
                subtitle: "Order-Cancel",
                modalType: "Order-Cancel",
                description: "Are you sure to proceed for Cancel Order?",
                modalType: "not form",
                button: "Yes",
                Data: {}
            });
        // }else{
        //     setError("Please enter reason")
        // }
    }
    const onClose = () =>{
        setShowModalNew({
            ...showModalNew,
            open: false,
            title: "Confirmation",
            subtitle: "Order-Cancel",
            modalType: "Order-Cancel",
            description: "Are you sure to proceed for Cancel Order?",
            modalType: "not form",
            button: "Yes",
            Data: {}
        });
        setStart_date("")
        setRemarks("")
    }

    return(
        <React.Fragment>
            <Button className="btn button-1 w-auto ms-2" onClick={() => callPauseModal()} style={{ width: "auto" }} variant="primary" >
                                            Pause Order
                                        </Button>

                <Modal show={showModalNew?.open} onHide={onClose} className='commonModal' centered>
                    <Modal.Header className='border-bottom-0 bb-0 pb-0' closeButton>
                        <Modal.Title className='h5'>Pause Order</Modal.Title>
                    </Modal.Header>
                    <div className='datepickField min p-3'>
                        <h6 className='h6'>Start Time <span className='text-danger' >*</span></h6>
                        <ReactDatePicker 
                            selected={start_date} 
                            onChange={(date) => setStart_date(date)} 
                            dateFormat="dd/MM/yyyy"
                            peekNextMonth 
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="Enter Start Time"
                            minDate={new Date()}
                            autoComplete="off"
                        />
                    </div>
                    <div className='px-3 pb-4'>
                        <h6 className='h6'>Remarks <span className='text-danger' >*</span></h6>
                        <FormControl value={remarks} onChange={(e)=>e.target.value != "" ?setRemarks(e.target.value):setRemarks("")} as="textarea"/>
                    </div>
                <div className='d-flex  px-3 pb-3'>
                    <button className='btn button-1 w-auto ms-auto' disabled={start_date && remarks != ""?false:true} onClick={PauseOrder}>Submit</button>
                </div>
                </Modal>
        </React.Fragment>
        )
}

export default OrderPause;