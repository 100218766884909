/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Badge, Button, Form } from 'react-bootstrap';
import CommonToggle from "../../Common/CommonToggle";
import { Link, useNavigate, useLocation, useParams  } from "react-router-dom";
import { CITIES_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import Select from 'react-select'

const AddCities = () => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ ApplicationState, setApplicationState ] = useState({
        CityName: "",
        State:"",
        Status: true
    });
    const roomId = useParams()?.id;
    const { accessToken, ClearFormSet, brandList,stateList,device_id,cityList } = useSelector((state) => state.adminReducers);
    const CurrentData_ = cityList?.list?.filter((elm) => elm?.id === roomId )[0];
    const [ optionsState, SetOptionsState ] = useState(()=>stateList?.list?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.state_name })));
    // error state
    const [ errorCityName, seterrorCityName ] = useState("");
    const [ errorState, seterrorState ] = useState("");

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    useEffect(() => {
        if(LocationRoute === "edit-cities") {
            const data = optionsState?.filter((eml)=>CurrentData_?.state_id === eml?.value)
            setApplicationState({
                ...ApplicationState,
                CityName:CurrentData_?.district_name,
                State:data[0],
                Status: CurrentData_?.is_active,
            });
        };
    }, [ CurrentData_ ])
    // useEffect(() => {
    //     if(LocationRoute === "edit-product-application") {
    //         setApplicationState({
    //             ...ApplicationState,
    //             BrandImage: CurrentData_?.brand_image,
    //             BrandName: CurrentData_?.brand_name,
    //             Status: CurrentData_?.is_active
    //         });
    //     };
    // }, [ CurrentData_ ])
    // create and edit brand

    const CreateBrand = () => {
        if(ApplicationState?.CityName?.trim() !== "" && ApplicationState?.State !== "") {
            if(LocationRoute === "edit-cities") {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "district_edit",
                    "request" : { 
                        "district_id" : CurrentData_?.id,
                        "district_name" : ApplicationState?.CityName?.trim(), 
                        "state_id":ApplicationState?.State?.value,
                        "is_active" : ApplicationState?.Status
                    },
                "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            } else {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "district_add",
                    "request" : { 
                        "district_name" : ApplicationState?.CityName.trim(),
                        "state_id": ApplicationState?.State?.value,
                        "is_active" : ApplicationState?.Status
                    },
                    "DeviceId" : device_id
                };

                wsSend_request(websocket, param);
            }
        } else {
            CheckValid(ApplicationState?.CityName?.trim(), {type: 'CityName', error: seterrorCityName});
            CheckValid(ApplicationState?.State?.trim(), {type: 'StateName', error: seterrorState});
        };
    };
    const StateValueSelect = (value) => {
        if(value !== null) {
            setApplicationState({...ApplicationState, State: value});
            CheckValid(value, {type: 'StateName', error: seterrorState});
        } else {
            setApplicationState({...ApplicationState, RegionValue: ""});
            CheckValid(value === null ? "" : value, {type: 'StateName', error: seterrorState});
        }
    };
    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            Navigate(CITIES_URL);
        };
    }, [ ClearFormSet ]);


    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={CITIES_URL} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappers">
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>City Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={ApplicationState?.CityName} 
                                onChange={(e) => setApplicationState({...ApplicationState, CityName: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'CityName', error: seterrorCityName})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter City Name" 
                                disabled={UploadFileLoader}
                            />
                            {errorCityName !== "" &&<span className="error_medotory">{errorCityName}</span>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Select State</Form.Label>
                            <div className="selectMultiselect" id="selectMultiselectBrand">
                                <Select 
                                    value={ApplicationState?.State}
                                    onChange={(e) => StateValueSelect(e)} 
                                    // defaultValue={defaultCategoryOption} 
                                    isClearable 
                                    options={optionsState}
                                    className="godown-select-container" 
                                    classNamePrefix="godown-select"
                                    styles={customStyles}
                                    placeholder="Select Region"
                                />
                                {errorState !== "" && <span className="error_medotory">{errorState}</span>}
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Status</Form.Label>
                            <div className="ToggleMainWrap">
                                <CommonToggle 
                                    valueToggle={ApplicationState?.Status}
                                    setValueToggle={() => setApplicationState({...ApplicationState, Status: !ApplicationState?.Status})}
                                    name={""}
                                />
                                {ApplicationState?.Status ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>}
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Button className="formbtn widthfullbtn" onClick={() => CreateBrand()} variant="primary" >
                        {/* {LocationRoute === "edit-product-application" ? "Edit Applications" : "Create Applications"} */}
                        {LocationRoute == "edit-cities"?"Update":"Add"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default AddCities;