/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Modal, Button, Table, Image, Form } from 'react-bootstrap';
import Logo from "../../../assets/img/logo.png";
import { useSelector, useDispatch } from "react-redux";
import { ClearFormSetFutios, GetOrderReportDetails } from "../../../redux/actions/adminActions";
import CommonDateTime from "../../Common/CommonDateTime";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import Scrollbars from "react-custom-scrollbars-2";
import { Link, useNavigate, useParams } from "react-router-dom";
import wsSend_request from "../../../Api/ws/ws_request";
import { WebSocketContext } from "../../../App";
import { FRANCHISE_PENDING_DELIVERY_URL, FRANCHISE_DISPATCH_URL } from "../../Shared/constant";
import SelectReact from "../../Common/SelectReact";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import UploadFileAPI from "../../../Api/UploadFileAPI";
import PurchaseOrderReport from "../../Common/PurchaseOrderReport";
import { StartMainCommonLoader } from "../../../redux/actions/adminActions";

const FranchisePendingDeliveryEdit = (props) => {
    const { OrderViewerModal, setOrderViewerModal } = props;
    const { OrderReportDetails, device_id, OptionCourierList, commonMainLoader, ClearFormSet, accessToken } = useSelector((state) => state.adminReducers);
    const Dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const roomId = useParams();
    const [formData, setFormData] = useState({
        dispatch_date: (OrderReportDetails?.order?.dispatch_date === null || OrderReportDetails?.order?.dispatch_date === undefined) ? "" : new Date(OrderReportDetails?.order?.dispatch_date),
        docket_slip: "",
        delivered_date: (OrderReportDetails?.order?.delivered_date === null || OrderReportDetails?.order?.delivered_date === undefined) ? "" : new Date(OrderReportDetails?.order?.delivered_date),
        docket_number: (OrderReportDetails?.order?.docket_number === null || OrderReportDetails?.order?.docket_number === undefined) ? "" : OrderReportDetails?.order?.docket_number,
        is_dispatch: "",
        order_id:roomId?.id,
    })
    const Navigate = useNavigate();
    const [dispatch_date, setError_dispatch_date] = useState("")
    const [docket_slip, setError_docket_slip] = useState("")
    const [delivered_date, setError_delivered_date] = useState("")
    const [docket_number, setError_docket_number] = useState("")
    const { websocket } = useContext(WebSocketContext);
    const today = new Date();

    useEffect(() => {
        if(roomId?.id) {
            let param = { 
                "transmit": "broadcast", 
                "url": "order_detail",
                "request" : { 
                    "order_id" : roomId?.id,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }
    }, [ roomId?.id, websocket ]);

    useEffect(()=>{
        if(OrderReportDetails?.order) {
            setFormData((data)=>({
                ...data,
                docket_number: OrderReportDetails?.order?.docket_number === null ? "" : OrderReportDetails?.order?.docket_number,
                dispatch_date: OrderReportDetails?.order?.dispatch_date === null ? "" : new Date(OrderReportDetails?.order?.dispatch_date),
            }))
        };
    },[ OrderReportDetails?.order ]);

    useEffect(()=>{
        if(websocket) {
            let param = { 
                "transmit": "broadcast", 
                "url": "courier_list",
                "request" : { 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : "",
                    "status" : true,
                    "order_by" :"-updated_at"
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }
    },[websocket])

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            Dispatch(ClearFormSetFutios({
                url:"",
                action:false
            }));
            Navigate(FRANCHISE_PENDING_DELIVERY_URL);
        };
    }, [ ClearFormSet ]);

    

    const callSubmitForm = (e, state) =>{
        e.preventDefault();
        if(
            formData?.docket_number &&
            formData?.delivered_date &&
            formData?.dispatch_date &&
            formData?.docket_slip
        ){
            Dispatch(StartMainCommonLoader({
                url: "order_delivered",
                loader: true
            }));
            let param = { 
                "transmit": "broadcast", 
                "url": "order_delivered",
                "request" : {
                    "order_id" : roomId?.id,
                    "docket_slip" : formData?.docket_slip,
                    "docket_number": formData?.docket_number,
                    "dispatch_date": moment(formData?.dispatch_date).format("YYYY-MM-DD"),
                    "delivered_date": moment(formData?.delivered_date).format("YYYY-MM-DD")
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }else{
            if(!formData?.dispatch_date){
                setError_dispatch_date("Dispatch Date Is Required")
            }
            if(!formData?.delivered_date){
                setError_delivered_date("Actual Delivered Date Is Required")
            }
            if(!formData?.docket_number){
                setError_docket_number("Docket Number Is Required")
            }
            if(!formData?.docket_slip){
                setError_docket_slip("Docket Slip Is Required")
            }
        }
    };

    const onChange = (e) => {
        if(e.target.value=="" && e.target.name == "docket_number"){
            setError_docket_number("Docket Number Is Required")
        }else{
            setError_docket_number("")
        }
        setFormData((data)=>({
            ...data,
            [e.target.name]:e.target.value
        }))
    };

    const UploadImage = async (e) => {
        const files = e.target.files[0];
        if(files){
            const formData = new FormData();
            formData.append("file", files, files.name);
            const fileresponse = await UploadFileAPI(accessToken, formData);
            if(fileresponse?.status === 200) {
                // setSubCategoryState({...subCategoryState, ProductImage: fileresponse?.id});
                setFormData((data)=>({
                    ...data,
                    [e.target.name]:fileresponse?.id,
                    fileName: files.name,
                }))
                setError_docket_slip("")
            } else {
                // setUploadFileLoader(false);
            };
        } else{
            setFormData((data)=>({
                ...data,
                [e.target.name]:"",
                fileName: "",
            }))
            setError_docket_slip("Docket Slip Is Required")
        }
    };

    const onSelect = (e, name) =>{
        if(e === null && name == "dispatch_date"){
            setError_dispatch_date("Dispatch Date Is Required")
        }else if(name == "dispatch_date"){
            setError_dispatch_date("")
        }
        if(e === null && name == "delivered_date"){
            setError_delivered_date("Actual Delivered Date Is Required")
        }else if(name == "delivered_date"){
            setError_delivered_date("")
        }
        setFormData((data)=>({
            ...data,
            [name]:e
        }))
    }

    function subDate(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    // common function
    const CommonFunction = async (id) => {
        // const printContents = document.getElementById(id).innerHTML;
        // const originalContents = document.body.innerHTML;
        // document.body.innerHTML = printContents;
        // window.print();
        // document.body.innerHTML = originalContents;
        const printContent = document.getElementById(id);
        const newWindow = window.open('', '_blank', "width=1200,height=600");
        newWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>New Window</title>
            <!-- Add Bootstrap CSS link -->
            <style>
                table.printTable {
                    border-collapse: collapse;
                }
                .printTable th, .printTable td {
                    border: 1px solid black;
                    padding: 8px;
                    text-align: left;
                }
                .printTable th {
                    background-color: #f2f2f2;
                }
            </style>
        </head>
        <body>
            ${printContent.innerHTML}
        </body>
        </html>`);
        newWindow.document.close();
        newWindow.print();

        // Listen for the print dialog close event
        const printDialogClosed = () => {
            newWindow.close();
            window.removeEventListener('focus', printDialogClosed);
        };
    
        // Event listener to handle print dialog close
        window.addEventListener('focus', printDialogClosed);
    };

    return(
        <React.Fragment>
            <section className="Main_section">
                <div className="MainHeader">
                    <button  className='btn btn-icon p-1' id="print_btn_function" onClick={() => CommonFunction("contentToPrint")}>
                        <i className="bi bi-printer"></i>
                    </button>
                    <Link className="btn btn-link backbtn" to={FRANCHISE_PENDING_DELIVERY_URL}><i className="bi bi-arrow-left-short"></i> Back</Link>
                </div>
                    <Scrollbars
                        style={{ height: "calc(100vh - 149px )"}} 
                        renderView={props => <div {...props} className="view"/>}
                        
                        className="ScrollbarsSidebar"
                    >
                        <div className=" pb-5 OrderDetailsModal" >
                            <div >
                                <h5>{OrderViewerModal?.Title}</h5>
                            </div>

                            {/* print content */}
                            <div className="orderDetailsViewr " id="contentToPrint" style={{ display: "none" }}>
                                {OrderReportDetails !== undefined && Object.keys(OrderReportDetails)?.length > 0 ? (<div>
                                    <PurchaseOrderReport OrderReportDetails={OrderReportDetails}/>
                                </div>) : (<>
                                    Please Wait...
                                </>)}
                            </div>

                            <div className="orderDetailsViewr ">
                                {OrderReportDetails !== undefined && Object.keys(OrderReportDetails)?.length > 0 ? (<div>
                                    <PurchaseOrderReport OrderReportDetails={OrderReportDetails}/>

                                    <div className="m-auto pt-4" style={{maxWidth: 700}}>
                                        <div className="row m-0">
                                            <div className="col-md-6 ">
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Docket Number <span className="mendatory_star">*</span></Form.Label>
                                                    <Form.Control 
                                                        name={"docket_number"}
                                                        onChange={onChange}
                                                        value={formData?.docket_number}
                                                        placeholder="Enter Docket Number"
                                                        disabled={OrderReportDetails?.order?.shipment_url !== null}
                                                        autoComplete="off"
                                                    />
                                                    {docket_number !== "" &&<span className="error_medotory">{docket_number}</span>}
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6 " >
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Dispatch Date <span className="mendatory_star">*</span></Form.Label>
                                                    {OrderReportDetails?.order?.shipment_url === null ? ( <div className="datepickField min">
                                                        <ReactDatePicker
                                                            selected={formData?.dispatch_date} 
                                                            startDate={formData?.dispatch_date}
                                                            onChange={(e)=>onSelect(e, "dispatch_date")} 
                                                            // minDate={subDate(new Date(), 60)}
                                                            maxDate={today}
                                                            dateFormat="dd/MM/yyyy"
                                                            peekNextMonth 
                                                            showMonthDropdown
                                                            name="dispatch_date"
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            placeholderText="Enter From Date"
                                                            isClearable={formData?.dispatch_date !== "" ? true : false}
                                                            disabled={OrderReportDetails?.order?.shipment_url !== null}
                                                            autoComplete="off"
                                                        />
                                                    </div>) : ( <div className="datepickField min">
                                                        <ReactDatePicker
                                                            selected={formData?.dispatch_date} 
                                                            startDate={formData?.dispatch_date}
                                                            onChange={(e)=>onSelect(e, "dispatch_date")} 
                                                            // minDate={subDate(new Date(), 60)}
                                                            maxDate={today}
                                                            dateFormat="dd/MM/yyyy"
                                                            peekNextMonth 
                                                            showMonthDropdown
                                                            name="dispatch_date"
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            placeholderText="Enter From Date"
                                                            // isClearable={formData?.dispatch_date !== "" ? true : false}
                                                            disabled={OrderReportDetails?.order?.shipment_url !== null}
                                                            autoComplete="off"
                                                        />
                                                    </div>) }
                                                   
                                                    {dispatch_date !== "" &&<span className="error_medotory">{dispatch_date}</span>}
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6 ">
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Actual Delivered Date <span className="mendatory_star">*</span></Form.Label>
                                                    <div className="datepickField min">
                                                        <ReactDatePicker
                                                            selected={formData?.delivered_date} 
                                                            startDate={formData?.delivered_date}
                                                            minDate={new Date(formData?.dispatch_date)}
                                                            onChange={(e)=>onSelect(e, "delivered_date")} 
                                                            maxDate={today}
                                                            dateFormat="dd/MM/yyyy"
                                                            peekNextMonth 
                                                            showMonthDropdown
                                                            name="delivered_date"
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            placeholderText="Enter From Date"
                                                            isClearable={formData?.delivered_date !== "" ? true : false}
                                                            disabled={formData?.delivered_date !== "" && OrderReportDetails?.order?.shipment_url !== null}
                                                            autoComplete="off"
                                                        />
                                                    </div>
                                                    {delivered_date !== "" &&<span className="error_medotory">{delivered_date}</span>}
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-6 ">
                                                <Form.Group className="mb-3" >
                                                    <Form.Label className="d-block">Docket Slip <span className="mendatory_star">*</span></Form.Label>
                                                    {/* <Form.Control 
                                                        name={"docket_slip"}
                                                        onChange={UploadImage}
                                                        // value={formData?.docket_slip}
                                                        type="file"
                                                    /> */}
                                                   {formData?.docket_slip ?(
                                                    <div className="uplaodedFilename w-100">
                                                        <i className="bi bi-file-earmark-text"></i>
                                                        {formData?.fileName}
                                                        <i className="bi bi-x-circle-fill" onClick={() => setFormData({...formData, docket_slip:""})}></i>
                                                    </div>
                                                   ):(
                                                    <label id="UploadCmnBTN" className="btn head_cmn_btn w-100 UploadCmnBTN" style={{ width: "auto", marginTop: "0px", float: "left" }}>
                                                        <i className="bi bi-upload"></i>
                                                        <input type="file" name={"docket_slip"} id="fileInput" onChange={UploadImage} accept=".png, .jpg, .pdf" />
                                                        Upload Slip
                                                    </label>
                                                   )}
                                                    {docket_slip !== "" &&<span className="error_medotory">{docket_slip}</span>}
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                </div>) : (<>
                                    Please Wait...
                                </>)}
                            </div>
                           <div className="text-center mb-4">
                                <Button className="btn exportbtn" disabled={(commonMainLoader?.url === "order_delivered" && commonMainLoader?.loader)} variant={"exportbtn"} onClick={callSubmitForm}>
                                    Update
                                </Button>
                           </div>
                            {/* <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" className="addbtncmn" onClick={() => CommonFunction("contentToPrint")}>
                                    Print
                                </Button>
                            </Modal.Footer> */}
                        </div>
                    </Scrollbars>
            </section>
        </React.Fragment>
    )
}

export default FranchisePendingDeliveryEdit;