/* eslint-disable */
import LogoutAPI from "../../Api/LogoutAPI";
import { clearRedux, checkLogin, getDeviceId } from "../../redux/actions/adminActions";
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CommonLogout = async (accessToken, dispatch) => {
    if(accessToken !== "" && accessToken !== null) {
        const responce = await LogoutAPI(accessToken);
        if(responce?.status === 200){
            setTimeout(() => {
                // toast.success(responce.msg);
                window.location.replace(process.env.REACT_APP_BASE_URL+`user/del_login/${location?.host}`);
                dispatch(clearRedux());
                localStorage.clear();
                dispatch(checkLogin('no'));
                // dispatch(getDeviceId(""));
                localStorage.removeItem("access_token");
            }, 200);
        } else {
            setTimeout(() => {
                // window.location.replace(process.env.REACT_APP_BASE_URL+`user/del_login/${location?.host}`);
                dispatch(clearRedux());
                localStorage.clear();
                dispatch(checkLogin('no'));
                // dispatch(getDeviceId(""));
                localStorage.removeItem("access_token");
            }, 200);
        }
    };
}

export default CommonLogout;