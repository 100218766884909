/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table } from 'react-bootstrap';
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { WebSocketContext } from "../../App";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../Common/CommonPagination";
import GetCurrentTableSRNo from "../Common/GetCurrentTableSRNo";
import moment from "moment";

const SalesPersonPerformance = (props) => {
    const { RouteName } = props;
    const { device_id, salesPersonPerformanceList } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ Franchise, setFranchise ] = useState("");

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // select franchise name
    const FranchiseName = (value) => {
        setFranchise(value);
        setCurrentPage(1);
    };

    // api calling
    const APICall = () => {
        let param = { 
            "transmit": "broadcast", 
            "url": "salesperson_performance_list",
            "request" : { 
                "limit" : userLimit,
                "page_no" : currentPage, 
                "franchise_id" : Franchise?.value,
                "search" : userSearch.trim(),
                "order_by" :"-updated_at"
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, currentPage, userLimit, Franchise ]);

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={"salesPersonPerformance"}
            searchType={"Email"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
            ActiveInactiveFilter={false}
            Franchise={Franchise}
            setFranchise={setFranchise}
            FranchiseName={FranchiseName}
        />
        <div className="tableView">
            <Scrollbars 
                style={{ height: salesPersonPerformanceList?.pagination?.total_records > 10 ? "calc(100vh - 185px)" : "calc(100vh - 150px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>Dealer Code</th>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Month</th>
                            <th>Target</th>
                            <th>Target Achieved</th>
                            <th>Incentives Earned</th>
                        </tr>
                    </thead>
                    <tbody>
                        {salesPersonPerformanceList?.list?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, salesPersonPerformanceList?.pagination?.current_page, salesPersonPerformanceList?.pagination?.record_limit)}
                                </td>
                                <td>{elm?.pramotor_code}</td>
                                <td>{elm?.sales_email}</td>
                                <td>{elm?.full_name}</td>
                                <td>{parseInt(elm?.month) <= 12 ? moment().month(parseInt(elm?.month) - 1).format('MMMM') : "-"}</td>
                                <td>{elm?.target}</td>
                                <td>{elm?.target_achived}</td>
                                <td>{elm?.incentive_earned}</td>
                            </tr>)
                        })}
                        {salesPersonPerformanceList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="8">Sales person performance list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {salesPersonPerformanceList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={salesPersonPerformanceList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
    </section>)
}

export default SalesPersonPerformance;