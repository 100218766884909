const ConvertAmountIntoWords = (amount) => {
    var ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
    var teens = ['', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
    var tens = ['', 'ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

    function convertLessThanOneThousand(n) {
        if (n < 10) return ones[n];
        else if (n < 20) return teens[n - 10];
        else if (n < 100) return tens[Math.floor(n / 10)] + ' ' + ones[n % 10];
        else return ones[Math.floor(n / 100)] + ' hundred ' + convertLessThanOneThousand(n % 100);
    }

    var rupees = Math.floor(amount);
    var paise = Math.round((amount - rupees) * 100);

    var words = '';

    if (rupees === 0 && paise === 0) {
        return 'zero rupees only';
    }

    if (rupees < 0) {
        words += 'minus ';
        rupees = Math.abs(rupees);
    }

    if (rupees >= 10000000) {
        words += convertLessThanOneThousand(Math.floor(rupees / 10000000)) + ' crore ';
        rupees %= 10000000;
    }

    if (rupees >= 100000) {
        words += convertLessThanOneThousand(Math.floor(rupees / 100000)) + ' lakh ';
        rupees %= 100000;
    }

    if (rupees >= 1000) {
        words += convertLessThanOneThousand(Math.floor(rupees / 1000)) + ' thousand ';
        rupees %= 1000;
    }

    if (rupees > 0) {
        words += convertLessThanOneThousand(rupees);
    }

    if (paise > 0) {
        words += ' rupees and ' + convertLessThanOneThousand(paise) + ' paise only';
    } else {
        words += ' rupees only';
    }

    return words.trim();
}

export default ConvertAmountIntoWords;