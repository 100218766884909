/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Badge, Collapse, Image } from 'react-bootstrap';
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { WebSocketContext } from "../../App";
import { useSelector } from "react-redux";
import CommonPagination from "../Common/CommonPagination";
import GetCurrentTableSRNo from "../Common/GetCurrentTableSRNo";
import wsSend_request from "../../Api/ws/ws_request";
import MoreIcon from '../../assets/img/more_options.png';
import { FILE_AUTH_URL } from "../Shared/constant";
import ProductDefaultImage from "../../assets/img/DefaultProduct.jpg";
import FileDownload from "../Common/FileDownload";
import SendRefurbishedPdf from "../../Api/SendRefurbishedPdf";
import { toast } from "react-toastify";
import GetViewFilesAPI from "../Common/GetViewFilesAPI";
import CommonDateTime from "../Common/CommonDateTime";

const DealerRegistration = (props) => {
    const { RouteName, ImagePreviewFunction } = props;
    const { ResellcycleReportsList, device_id, selfInfo, accessToken } = useSelector((state) => state.adminReducers);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const [ disable, setdisable ] = useState({
        active: false,
        id: ""
    });
    const [ DownloadFileProgress, setDownloadFileProgress ] = useState({
        loader: "0%",
        id: ""
    });
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    
    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    
    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ReSells[0]?.Edit || PermissionsAccess?.ReSells[0]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "refurbished_list",
                "request" : { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch.trim(),
                    "order_by" : "-updated_at",
                    "seller_type" : "dealer" 
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // // video open click function
    // const InspectionVideos = (id) => {
    //     window.open(FILE_AUTH_URL + "view/file/" + id, '_blank');
    // };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    // send mail function
    const sendMailFuct = async (id) => {
        setdisable({
            ...disable,
            active: true,
            id: id
        });
        const jsonData = JSON.stringify({ 
            refurbished_id: id,
            is_resend: true
        });
        const responce = await SendRefurbishedPdf(jsonData);
        if(responce?.status === 200) {
            setdisable({
                ...disable,
                active: false,
                id: ""
            });
            toast.success(responce?.message);
        } else {
            setdisable({
                ...disable,
                active: false,
                id: ""
            });
            toast.error(responce?.message);
        };
    };

    // get brand name
    const getBrandName = (brand_id) => {
        const CurrentBrandName = ResellcycleReportsList?.refurbished_brand?.filter((elm) => elm?.id === brand_id)[0];
        return CurrentBrandName === undefined ? brand_id : CurrentBrandName?.brand_name;
    };

    // download file progress bar
    const handleProgress = (progress) => {
        setDownloadFileProgress({
            ...DownloadFileProgress,
            loader: progress?.percentCompleted + "%",
            id: progress?.id
        });
    };

    // donwload file
    const DonwloadFile = async (refurbished_pdf, id) => {
        const authurl = FILE_AUTH_URL + refurbished_pdf?.view_file_url;
        const url = await GetViewFilesAPI(authurl, accessToken, handleProgress, id);

        if(url) {
            var tempEl = document.createElement("a");
            tempEl.href = url;
            tempEl.download = "inspection_report.pdf";
            tempEl.click();
            window.URL.revokeObjectURL(url);

            setTimeout(() => {
                setDownloadFileProgress({
                    ...DownloadFileProgress,
                    loader: "0%",
                    id: ""
                });
                toast.success("Valuation report has been successfully downloaded.");
            }, 500);
        };
    };

    return(
        <div className="tabletabs_scrolbar">
            <DataTableFilter 
                filterType={""}
                searchType={"Full Name, Email, Mobile"}
                userSearch={userSearch}
                setUserSearch={setUserSearch}
                userType={userType} 
                setUserType={setUserType}
                userLimit={userLimit} 
                setUserLimit={setUserLimit}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                SelectUserTypeFnct={SelectUserTypeFnct}
                buttonvisible={false}
                searchdisable={true}
                ActiveInactiveFilter={false}
            />
            <div className="tableView gstreporttableadded">
                <Scrollbars 
                    style={{ height: ResellcycleReportsList?.pagination?.total_records > 10 ? "calc(100vh - 232px)" : "calc(100vh - 197px)"}} 
                    renderView={props => <div {...props} className="view"/>}
                    className="ScrollbarsSidebar"
                >
                    <Table bordered  >
                        <thead>
                            <tr>
                                <th></th>
                                <th>SNO</th>
                                <th>Full Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Brand</th>
                                <th>Purchase Price</th>
                                <th>Frame Number</th>
                                <th>No. of gears</th>
                                <th>Purchase year & month</th>
                                <th>Score</th>
                                <th>Seller Type</th>
                                <th>Email Sent</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ResellcycleReportsList?.list?.map((elm, index) => {
                                const correctedJsonString = elm?.points; //?.replace(/'/g, '"')
                                const correctbrandpricing = elm?.brand_pricing;

                                let pointdataArray;
                                try {
                                    pointdataArray = JSON.parse(correctedJsonString?.replace(/'/g, '"'));
                                } catch (error) {
                                    // console.error('Error parsing JSON:', error);
                                };

                                let BrandPracingdataArray;
                                try {
                                    BrandPracingdataArray = JSON.parse(correctbrandpricing?.replace(/'/g, '"'));
                                } catch (error) {
                                    // console.error('Error parsing JSON:', error);
                                };

                                return(<React.Fragment key={index}>
                                    <tr key={index}>
                                        <td style={{ width: '10px' }}>
                                            <button 
                                                className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                                onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={activeTableDetails === elm?.id ? true : false}
                                            >
                                                <Image src={MoreIcon} alt="more icon" />
                                            </button>
                                        </td>
                                        <td>
                                            {GetCurrentTableSRNo(index, ResellcycleReportsList?.pagination?.current_page, ResellcycleReportsList?.pagination?.record_limit)}
                                        </td>
                                        <td>{elm?.full_name}</td>
                                        <td>{elm?.email}</td>
                                        <td>{elm?.mobile}</td>
                                        <td>{elm?.brand === null ? "-" : getBrandName(elm?.brand)}</td>
                                        <td>{elm?.purchase_price}</td>
                                        <td>{elm?.frame_number}</td>
                                        <td>{elm?.is_geared ? <Badge bg="success">Yes</Badge> : <Badge bg="danger">No</Badge>} {(elm?.no_geared !== null && elm?.is_geared) && elm?.no_geared}</td>
                                        <td>
                                            {parseInt(elm?.purchase_year)}  &  {parseInt(elm?.purchase_month)}
                                        </td>
                                        <td>{elm?.score}</td>
                                        <td style={{ textTransform: "capitalize" }}>{elm?.seller_type === null ? "-" : elm?.seller_type}</td>
                                        <td>
                                            {elm?.email_send ? <Badge bg="success">Yes</Badge> : <Badge bg="danger">No</Badge>}
                                        </td>
                                        <td>
                                            {elm?.refurbished_pdf !== null ? (<>
                                                <button className={(disable?.active && disable?.id === elm?.id) ? "btn send_btn active" : "btn send_btn"} disabled={disable?.active} onClick={() => sendMailFuct(elm?.id)}>
                                                    <i className="bi bi-envelope-at"></i> Re-Send
                                                </button>
                                                <button className={DownloadFileProgress?.id === elm?.id ? "btn donwload_btn active" : "btn donwload_btn"} style={{ marginLeft: "10px" }} disabled={(DownloadFileProgress?.id !== "") && (DownloadFileProgress?.id !== elm?.id || DownloadFileProgress?.id === elm?.id)} onClick={() => DonwloadFile(elm?.refurbished_pdf, elm?.id)}>
                                                    {DownloadFileProgress?.id === elm?.id && (<span className="download_progress_bra" >
                                                        <i className="bi bi-download"></i> 
                                                        {DownloadFileProgress?.loader}
                                                    </span>)}
                                                    <i className="bi bi-download"></i> Download
                                                </button>
                                            </>) : ("-")}
                                        </td>
                                    </tr>
                                    <Collapse in={activeTableDetails === elm?.id ? true : false}>
                                        <tr className="tablecollapsewraps" id={"tablecollpase"+elm?.id}>
                                            <td colSpan="9" >
                                                <Table bordered  className="table">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: "25%" }}>Bike Condition</th>
                                                            <td>
                                                                <Table bordered  className="table Bike_Condition">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Conditions</th>
                                                                            <th>Images</th>
                                                                            <th>Score</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {pointdataArray?.map((elm__, index) => {
                                                                            if(elm?.tire_front_image === null && elm?.tire_rear_image === null && elm?.frame_left_image === null && elm?.frame_right_image === null && elm?.suspension_image === null && elm?.gears_image === null) {
                                                                                return(<tr key={index}>
                                                                                    <td>{elm__?.key}</td>
                                                                                    <td className="Bike_Condition_iamges" style={{ borderLeft: "0px", borderRight: "0px" }}>
                                                                                        {elm__ ?.Images === undefined ? ("No Image") : elm__ ?.Images?.map((elx, index) => {
                                                                                            return(<div className="AvtarMain" key={index}>
                                                                                                <Image 
                                                                                                    src={FILE_AUTH_URL + "view/file/" + elx}
                                                                                                    onClick={() => ImagePreviewFunction(elx, "image")}
                                                                                                    onError={(e) => e.target.src = ProductDefaultImage}
                                                                                                    alt={"tableimg"}
                                                                                                />
                                                                                            </div>)
                                                                                        })}
                                                                                    </td>
                                                                                    <td>{elm__?.value}</td>
                                                                                </tr>)
                                                                            } else {
                                                                                return(<tr key={index}>
                                                                                    <td>{elm__?.key}</td>
                                                                                    <td className="Bike_Condition_iamges" style={{ borderLeft: "0px", borderRight: "0px" }}>
                                                                                        {elm__?.key === "Tire Condition" ? (<>
                                                                                            <div className="AvtarMain">
                                                                                                <Image 
                                                                                                    src={FILE_AUTH_URL + "view/file/" + elm?.tire_front_image}
                                                                                                    onClick={() => ImagePreviewFunction(elm?.tire_front_image, "image")}
                                                                                                    onError={(e) => e.target.src = ProductDefaultImage}
                                                                                                    alt={"tableimg"}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="AvtarMain">
                                                                                                <Image 
                                                                                                    src={FILE_AUTH_URL + "view/file/" + elm?.tire_rear_image}
                                                                                                    onClick={() => ImagePreviewFunction(elm?.tire_rear_image, "image")}
                                                                                                    onError={(e) => e.target.src = ProductDefaultImage}
                                                                                                    alt={"tableimg"}
                                                                                                />
                                                                                            </div>
                                                                                        </>) : elm__?.key === "Frame & Color" ? (<>
                                                                                            <div className="AvtarMain">
                                                                                                <Image 
                                                                                                    src={FILE_AUTH_URL + "view/file/" + elm?.frame_left_image}
                                                                                                    onClick={() => ImagePreviewFunction(elm?.frame_left_image, "image")}
                                                                                                    onError={(e) => e.target.src = ProductDefaultImage}
                                                                                                    alt={"tableimg"}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="AvtarMain">
                                                                                                <Image 
                                                                                                    src={FILE_AUTH_URL + "view/file/" + elm?.frame_right_image}
                                                                                                    onClick={() => ImagePreviewFunction(elm?.frame_right_image, "image")}
                                                                                                    onError={(e) => e.target.src = ProductDefaultImage}
                                                                                                    alt={"tableimg"}
                                                                                                />
                                                                                            </div>
                                                                                        </>) : elm__?.key === "Brakes front & rear" ? ("No Image") : elm__?.key === "Suspension" ? (<>
                                                                                            <div className="AvtarMain">
                                                                                                <Image 
                                                                                                    src={FILE_AUTH_URL + "view/file/" + elm?.suspension_image}
                                                                                                    onClick={() => ImagePreviewFunction(elm?.suspension_image, "image")}
                                                                                                    onError={(e) => e.target.src = ProductDefaultImage}
                                                                                                    alt={"tableimg"}
                                                                                                />
                                                                                            </div>
                                                                                        </>) :(<>
                                                                                            <div className="AvtarMain">
                                                                                                <Image 
                                                                                                    src={FILE_AUTH_URL + "view/file/" + elm?.gears_image}
                                                                                                    onClick={() => ImagePreviewFunction(elm?.gears_image, "image")}
                                                                                                    onError={(e) => e.target.src = ProductDefaultImage}
                                                                                                    alt={"tableimg"}
                                                                                                />
                                                                                            </div>
                                                                                        </>)}
                                                                                    </td>
                                                                                    <td>{elm__?.value}</td>
                                                                                </tr>)
                                                                            }
                                                                        })}
                                                                    </tbody>
                                                                </Table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "25%" }}>Inspection Videos</th>
                                                            <td className="Bike_Condition_iamges">
                                                                <ul>
                                                                    {JSON.parse(elm?.inspection_videos?.replace(/'/g, '"'))?.map((elmzz, index) => {
                                                                        return(<li key={index}>
                                                                            <div className="youtube_wrapper" onClick={() => ImagePreviewFunction(elmzz, "video")}><i className="bi bi-camera-reels"></i>&nbsp;&nbsp;Inspection Videos {index + 1}</div>
                                                                        </li>)
                                                                    })}
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "25%" }}>Invoice Image</th>
                                                            <td className="Bike_Condition_files">
                                                                {(elm?.invoice_image !== null && elm?.invoice_image !== "") ? (<div onClick={() => FileDownload(elm?.invoice_image, accessToken)}><i className="bi bi-file-earmark-ruled"></i>&nbsp;Invoice File</div>) : "No File"}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "25%" }}>Applicable Offer</th>
                                                            <td>
                                                                <Table bordered  className="table Bike_Condition">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>E-Bike</th> 
                                                                            <th>Discount</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {elm.brand_pricing === null ? elm?.brand_list?.map((elm, index) => {
                                                                            return(<tr key={index}>
                                                                                <td>{elm?.key}</td>
                                                                                <td>{elm?.value}</td>
                                                                            </tr>)
                                                                        }) : BrandPracingdataArray?.map((elm, index) => {
                                                                            return(<tr key={index}>
                                                                                <td>{elm?.key}</td>
                                                                                <td>{elm?.value}</td>
                                                                            </tr>)
                                                                        })}
                                                                    </tbody>
                                                                </Table>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "25%" }}>Created At</th>
                                                            <td>{CommonDateTime(elm.created_at, "LL", "show")}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                    </Collapse>
                                </React.Fragment>)
                            })}
                            {ResellcycleReportsList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="15">Resellcycle reports list not found </td></tr>}
                        </tbody>
                    </Table>
                </Scrollbars>
                {/* pagination */}
                {ResellcycleReportsList?.pagination?.total_records > 10 && (<CommonPagination 
                    currentPage={currentPage}
                    paginationLength={ResellcycleReportsList?.pagination}
                    currentFunction={currentFunction}
                />)}
            </div>
        </div>
    )
}

export default DealerRegistration;