/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Badge, Collapse, Image, Button, Modal } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector,useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import OrderDetailsViewer from "../../Common/OrderDetailsViewer";
import { FRANCHISE_PENDING_DELIVERY_URL, FRANCHISE_DISPATCH_URL } from "../../Shared/constant";
import MoreIcon from '../../../assets/img/more_options.png';
import { useNavigate } from "react-router-dom";
import { TimeSense, getColor, getOrderTypeSource, getStringOfErrorValues } from "../../Common/TimeSense";
import GenerateInvoiceAPI from "../../../Api/GenerateInvoiceAPI";
import { GENERATE_INVOICE_API_URL } from "../../Shared/constant";
import CommonLoader from "../../Common/CommonLoader";
import BrowserUniqueTabID from "../../Common/BrowserUniqueTabID";

const EInvoiceDetails = (props) => {
    const navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [ FromDate, setFromDate ] = useState("");
    const [ ToDate, setToDate ] = useState("");
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const [ status, setStatus ] = useState("SUCCESS");
    const [ statusSearch, setStatusSearch ] = useState("");
    const [show, setShow] = useState(null);
    const [newModal, setNewModal] = useState({
        open: false,
        Data: {}
    });
    const handleShow = (elm) => setShow(elm);
    const today = new Date();
    const { device_id, EInvoiceSuccess, AllDealerFranchiseTypeListAll, accessToken } = useSelector((state) => state.adminReducers);
    const [ OrderViewerModal, setOrderViewerModal ] = useState({
        open: false,
        Title: "Order Details",
        Data: {}
    });
    const [ Loader, setLoader ] = useState(false);
    // var tabID = sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = Math.random();
    var tabID = BrowserUniqueTabID();

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // select start date
    const DateStartGet = (date) => {
        if(date === null) {
            setFromDate("");
        } else {
            setFromDate(date);
        }
    };

    // select end date
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if(date === null) {
            setToDate("");
        } else {
            setToDate(date);
        }
    };

    useEffect(() => {
        if(websocket){
           callFilterData();
        }
    }, [ websocket, userSearch, userType, currentPage, userLimit, FromDate, ToDate ]);

    const callFilterData = () => {
        let param = {
            "transmit": "broadcast",
            "url": "invoice_list",
            "request" : {
                limit: userLimit,
                page_no: currentPage,
                search: userSearch.trim(),
                ewb_status: "",
                is_cancel: false,
                order_by: "-updated_at",
                invoice_status: status,
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
        setTimeout(()=>setStatusSearch(status), [1000])
    };

    

    // open order details modal
    const OrderDetailsModal = (order_code) => {
        // navigate(`${FRANCHISE_PENDING_DELIVERY_URL}/${order_code}`);
        // let param = { 
        //     "transmit": "broadcast", 
        //     "url": "order_detail",
        //     "request" : { 
        //         "order_id" : order_code,
        //     },
        //     "DeviceId" : device_id
        // };
        // wsSend_request(websocket, param);
        // setOrderViewerModal({...OrderViewerModal, open: !OrderViewerModal?.open});
    };
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };
    const callInvoiceModal = (e, elm) =>{
        e.preventDefault();
        let param = {
            "transmit": "broadcast",
            "url": "order_detail",
            "request" : {
                "order_id" : elm?.order_id,
                qr_code:elm?.qr_code?`data:image/png;base64,${elm?.qr_code}`:""
            },
            "DeviceId" : device_id,
            "TabId": tabID,
            modal:true
        };
        wsSend_request(websocket, param);
    }
    const responseText = (response1) =>{
        if(response1 !== undefined) {
            const response = JSON.parse(response1);
            let text = "";
            if(typeof(response?.errors) == "object"){
                text = text +`</p><b>errors:</b> ${getStringOfErrorValues(response?.errors)}</p>`;
            }
            if(typeof(response?.message) == "object"){
                text = text +`</p><b>message:</b> ${getStringOfErrorValues(response?.message)}</p>`;
            }
            if(typeof(response?.response) == "object"){
                text = text +`</p><b>response:</b> ${getStringOfErrorValues(response?.response)}</p>`;
            }
            if(typeof(response?.message) == "string"){
                text = text +`</p><b>message:</b> ${getStringOfErrorValues(response?.message)}</p>`;
            }
            return text;
        } else {
            return "504 Gateway Time-out"
        };
    };
    
    // Generate Invoice
    const GenerateInvoicFunction = async (elm) => {
        setLoader(true);
        const GenerateInvoiceAPIData_ = await GenerateInvoiceAPI(`${GENERATE_INVOICE_API_URL}/${elm?.invoice_no}`, accessToken);
        if(GenerateInvoiceAPIData_?.status === 200) {
            setLoader(false);
        } else {
            const response = GenerateInvoiceAPIData_?.msg;
            setLoader(false)
            setNewModal({...newModal, 
                    open: true, 
                    Data: response 
                });
        };
    };
    
    return(<section className="Main_section">
        <div className="filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-6">
                        <div className="row w-100">
                            <div className="col-md-6">
                                <Form.Group className="mb-3" >
                                    <select onChange={(e)=> setStatus(e.target.value)} value={status} className="form-control activestatusselet" id="activestatusselet">
                                        {/* <option>Select Generation Type</option> */}
                                        <option value={"FAILURE"}>Pending</option>
                                        <option value={"SUCCESS"}>Generate</option>
                                    </select>
                                </Form.Group>
                            </div>
                            <div className="col">
                                <button className="btn newsumbitbnt w-auto" onClick={() => { callFilterData(), setCurrentPage(1)}} style={{ marginTop: "0px" }}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DataTableFilter 
            searchType={"Invoice ID."}
            filterType={""}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
        />
        <div className="tableView">
            <Scrollbars 
                style={{ height: `calc(100vh -  ${EInvoiceSuccess?.pagination?.total_records > 10?"265px":"231px"})` }} 
                renderView={props => <div {...props} className="view"/>}
                
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th hidden></th>
                            <th>SNo</th>
                            <th>Order Id</th>
                            <th>Order Date</th>
                            <th>Invoice ID</th>
                            <th>Invoice date</th>
                            <th>EWB No.</th>
                            <th>EWB Date.</th>
                            {statusSearch === "FAILURE" && (
                                <React.Fragment>
                                    <th>Error</th>
                                    <th>Generate</th>
                                </React.Fragment>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {EInvoiceSuccess?.data?.map((elm, index) => {
                            const get_color = getColor(elm);
                            return(  <React.Fragment key={index}>
                                <tr >
                                    <td hidden style={{ width: '10px' }}>
                                        <button 
                                            className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                            onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={activeTableDetails === elm?.id ? true : false}
                                        >
                                            <Image src={MoreIcon} alt="more icon" />
                                        </button>
                                    </td>
                                    <td>{GetCurrentTableSRNo(index, EInvoiceSuccess?.pagination?.current_page, EInvoiceSuccess?.pagination?.record_limit)}</td>
                                    <td>
                                        <span onClick={() => OrderDetailsModal(elm?.id)} >{elm?.order_code}</span>
                                    </td>
                                    <td>{CommonDateTime(elm?.created_at, "LL", "show")}</td>
                                    <td>
                                        {elm?.invoice_no === "OPEN" ? "-" : <span className="">{elm?.invoice_no}</span>}
                                    </td>
                                    <td>{CommonDateTime(elm?.updated_at, "LL", "show")}</td>
                                    <td>
                                        {elm?.ack_no === "OPEN" ? "-" : <b className="tableBolanchor" onClick={(e)=>callInvoiceModal(e, elm)}>{elm?.ack_no}</b>}
                                    </td>
                                    <td>{CommonDateTime(elm?.ack_date, "LL", "show")}</td>
                                    {statusSearch === "FAILURE" && (
                                        <React.Fragment>
                                            <td>
                                                <Button className="btn edittable btn btn-primary" onClick={()=>handleShow(elm)}>
                                                    <i className="bi bi-eye"></i>
                                                </Button>
                                            </td>
                                            <td>
                                                {elm?.invoice_no === null ? "-" : (
                                                    <Button className="btn edittable btn btn-primary" 
                                                        onClick={() => GenerateInvoicFunction(elm)}
                                                    >
                                                        <i className="bi bi-eye"></i>
                                                    </Button>
                                                )}
                                            </td>
                                        </React.Fragment>
                                    )}
                                    <td hidden>
                                        {elm?.order_status === "DELIVERED" && (<Badge bg="danger">Inward completed</Badge>)}
                                    </td>
                                </tr>
                                <Collapse hidden in={activeTableDetails === elm?.id ? true : false}>
                                    <tr className="tablecollapsewraps" id={"tablecollpase"+elm?.id}>
                                        <td colSpan="12" >
                                            <Table bordered  className="table">
                                                <tbody>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Quantity</th>
                                                        <td>{elm?.qty}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Order Value</th>
                                                        <td>{AmountNumberFormat(elm?.total)}</td>

                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>SKU Type</th>
                                                        <td>{elm?.category}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Pending Since</th>
                                                        {/* <td>{elm?.updated_at?.split(" ")[0] === elm?.created_at?.split(" ")[0] ? TimeSense(elm?.updated_at) : TimeSense(elm?.created_at)}</td> */}
                                                        <td>{TimeSense(elm?.updated_at)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Invoice Date</th>
                                                        <td>{CommonDateTime(elm?.invoice_at || elm?.updated_at, "LL", "show")}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Criticality</th>
                                                        <td>
                                                            <span className={`px-2 py-1 rounded ${get_color.color_code}`} >{get_color.text}</span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>
                                </Collapse>
                            </React.Fragment>)
                        })}
                        {(!EInvoiceSuccess?.data || EInvoiceSuccess?.data?.length <= 0) && <tr style={{ textAlign: "center" }} ><td colSpan="10">E-Invoice Details list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {EInvoiceSuccess?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={EInvoiceSuccess?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        <OrderDetailsViewer
            OrderViewerModal={OrderViewerModal} 
            setOrderViewerModal={setOrderViewerModal} 
        />
        {show!==null && (
            <Modal show={show!==null} className="errorDetailsModal" onHide={()=>setShow(null)}>
                <Modal.Header closeButton>
                <Modal.Title>Error Details</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{overflow: "auto"}}>
                    <p dangerouslySetInnerHTML={{__html:responseText(show?.invoice_response)}}>
                    </p>
                </Modal.Body>
            </Modal>
        )}

        {newModal?.open && (<Modal show={newModal?.open} className="errorDetailsModal" onHide={()=>setNewModal({...newModal, open: false, Data: {}})}>
            <Modal.Header closeButton>
                <Modal.Title>Error Details</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{overflow: "auto"}}>
                <p dangerouslySetInnerHTML={{__html:responseText(newModal?.Data)}}>
                </p>
            </Modal.Body>
        </Modal>)}

        {Loader && (<CommonLoader />)}
    </section>)
}

export default EInvoiceDetails;