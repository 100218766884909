/* eslint-disable */
import React, { useState } from "react";
import ImagePreviwer from "../Common/ImagePreviwer";
import DealerRegistration from "./DealerRegistration";
import CustomerRegistration from "./CustomerRegistration";

const ResallCycle = (props) => {
    const { RouteName } = props;
    const [ Tab, setTab ] = useState("dealer_registration");
    const [ ImagePreviewerModal, setImagePreviewerModal ] = useState({
        open: false,
        Images_id:"",
        type: ""
    });

    // preview image modal
    const ImagePreviewFunction = (id, url) => {
        setImagePreviewerModal({
            open: !ImagePreviewerModal?.open,
            Images_id: id,
            type: url,
        });
    };

    // close modal
    const CloseImageModal = () => {
        setImagePreviewerModal({
            open: false,
            Images_id: "",
            type: "",
        });
    };

    return(<section className="Main_section">
        <div className="MainHeader">
            <ul className="MainHeader_Tab">
                <li className={Tab === "dealer_registration" ? "active" : ""} onClick={() => setTab("dealer_registration")}>Dealer Registration</li>
                <li className={Tab === "customer_registration" ? "active" : ""} onClick={() => setTab("customer_registration")}>Customer Registration</li>
            </ul>
        </div>
        <div className="tabletabs_scrolbar">
            {Tab === "dealer_registration" ? (<DealerRegistration 
                ImagePreviewFunction={ImagePreviewFunction} 
            />) : (<CustomerRegistration 
                ImagePreviewFunction={ImagePreviewFunction}
            />)}
        </div>

        {/* image preview modal */}
        {ImagePreviewerModal?.open && (<div className="background_wrapper" onClick={() => CloseImageModal()}></div>)}
        {ImagePreviewerModal?.open && (<ImagePreviwer ImagePreviewerModal={ImagePreviewerModal} CloseImageModal={CloseImageModal} />)}
    </section>)
}

export default ResallCycle;