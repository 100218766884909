import React from "react";
import { Spinner } from "react-bootstrap";

const CommonLoader = () => {
    return(<div className="commonLoaderwarap">
        <Spinner />
        <span>Please Wait...</span>
    </div>)
}

export default CommonLoader;