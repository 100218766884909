/* eslint-disable eqeqeq */
import { API_URL, FILE_AUTH_URL } from "../Shared/constant";

// main file download function
const FileDownload = async (image_id, accessToken) => {
    const FileUrl = FILE_AUTH_URL + "view/file/" + image_id;
    const url = await GetViewFilesAPI(FileUrl, accessToken);
    const filedetails = await GetFileDetails(image_id, accessToken)
    const tempEl = document.createElement("a");
    tempEl.href = url;
    tempEl.download = filedetails?.original_name;
    tempEl.click();
    window.URL.revokeObjectURL(url);
};

// get file details api call function
const GetFileDetails = async (id, accessToken) => {
    const url = API_URL + "file/detail/" + id;
    const responce = window.axios.get(url, {
    headers: {
      'Authorization': 'Bearer ' + accessToken,
    }}).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
};
  
// file convert into blob url
const GetViewFilesAPI = async (url, qr_token) => {
    const options = {
        headers: {
            'Authorization': `Bearer ${qr_token}`
        }
    };
    const responce = await fetch(url, options).then( res => res.blob())
    .then( blob => {
        let file = window.URL.createObjectURL(blob);
        return file;
    }).catch((error) => {
        return error;
    });
    return responce;
};

export default FileDownload;