/* eslint-disable */
import React, { useState } from "react";
import { Table, Button, Form } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const POPurchaseOrder = (props) => {
    const { RouteName } = props;
    const [ userSearch, setUserSearch ] = useState("");

    

    return(<section className="Main_section">
        <Scrollbars 
            style={{ height: "calc(100vh - 100px)" }} 
            renderView={props => <div {...props} className="view"/>}
            
            className="ScrollbarsSidebar"
        >
            <div className="filtr_Form">
                <div className="formloginset">
                    <div className="row">
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Select Warehouse</Form.Label>
                                <select className="form-control">
                                    <option value="all">WH001- (testing)</option>
                                    <option value="active">WH002- (Mahesh)</option>
                                </select>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Search By FsCode</Form.Label>
                                <div className={`searchBoxwithbtn ${userSearch !== ""?"search":""}`} style={{ width: "100%" }}>
                                    <Form.Control type="text" className="searchIcon" style={{ height: "40px" }} value={userSearch} onKeyDown={(e) => EmptySpaceFieldValid(e)} onChange={(e) => setUserSearch(e.target.value)}  placeholder="Search By FsCode" />
                                    {userSearch === "" ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                        <path d="M21 21l-6 -6"></path>
                                    </svg>) : (<i className="bi bi-x-circle-fill searchclear" onClick={() => setUserSearch("")}></i>)}
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="HeaderDivider">
                        <h6 className="subtitleForm">Search Result:</h6>
                        <div className="formsearchList">
                            <div className="row">
                                <div className="col-md-3" style={{ width: "23%" }}>
                                    <Form.Group>
                                        <Form.Label>FsCode</Form.Label>
                                        <div className="formData">WH001- (testing)</div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-1" style={{ padding: "0px", width: "10%" }}>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Purchase Price</Form.Label>
                                        <div className="formData">3997</div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-2" style={{ width: "15%" }}>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Batch No</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Batch No" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-2" style={{ width: "17%" }}>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Mfg Date</Form.Label>
                                        <div className="datepickField">
                                            <DatePicker 
                                                // selected={DateSelect} 
                                                // onChange={(date) => setDateSelect(date)} 
                                                dateFormat="dd/MM/yyyy"
                                                peekNextMonth 
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                placeholderText="Enter Date"
                                                // maxDate={newDate}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-2" style={{ width: "17%" }}>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Expiry Date</Form.Label>
                                        <div className="datepickField">
                                            <DatePicker 
                                                // selected={DateSelect} 
                                                // onChange={(date) => setDateSelect(date)} 
                                                dateFormat="dd/MM/yyyy"
                                                peekNextMonth 
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                placeholderText="Enter Date"
                                                // maxDate={newDate}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-2">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Quantity</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Quantity" />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                        <div className="formsearchList">
                            <div className="row">
                                <div className="col-md-3" style={{ width: "23%" }}>
                                    <Form.Group>
                                        <Form.Label>FsCode</Form.Label>
                                        <div className="formData">WH001- (testing)</div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-1" style={{ padding: "0px", width: "10%" }}>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Purchase Price</Form.Label>
                                        <div className="formData">3997</div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-2" style={{ width: "15%" }}>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Batch No</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Batch No" />
                                    </Form.Group>
                                </div>
                                <div className="col-md-2" style={{ width: "17%" }}>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Mfg Date</Form.Label>
                                        <div className="datepickField">
                                            <DatePicker 
                                                // selected={DateSelect} 
                                                // onChange={(date) => setDateSelect(date)} 
                                                dateFormat="dd/MM/yyyy"
                                                peekNextMonth 
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                placeholderText="Enter Date"
                                                // maxDate={newDate}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-2" style={{ width: "17%" }}>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Expiry Date</Form.Label>
                                        <div className="datepickField">
                                            <DatePicker 
                                                // selected={DateSelect} 
                                                // onChange={(date) => setDateSelect(date)} 
                                                dateFormat="dd/MM/yyyy"
                                                peekNextMonth 
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                placeholderText="Enter Date"
                                                // maxDate={newDate}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-2">
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Quantity</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Quantity" />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                        <div className="btnGroupswraps">
                            <Button className="Addbtn">Add</Button>
                            <Button className="Resetbtn">Reset</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tableView">
                <Table bordered  >
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>Product Code</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Unit Price</th>
                            <th>Total Price</th>
                            <th>Batch No</th>
                            <th>Mfg Date</th>
                            <th>Expiry Date</th>
                            <th style={{ width: "70px" }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>WH001- (testing)</td>
                            <td>XFactor - X1 Bumblebee Yellow- XX02EB1000</td>
                            <td>34</td>
                            <td>60000</td>
                            <td>2040000</td>
                            <td>534</td>
                            <td>01/04/2024</td>
                            <td>01/13/2024</td>
                            <td>
                                <Button className="btn edittable">
                                    <i className="bi bi-pencil-square"></i>    
                                </Button>
                                <Button className="btn edittable" style={{ marginLeft: "7px" }}>
                                    <i className="bi bi-trash" style={{ color: "red" }}></i> 
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div className="btnGroupswraps">
                    <Button className="Addbtn">Update Stock</Button>
                    <Button className="Resetbtn">Reset</Button>
                </div>
            </div>
        </Scrollbars>
    </section>)
}

export default POPurchaseOrder;