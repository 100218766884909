/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CommonewModel from './CommonewModel';
import { WebSocketContext } from '../../App';
import wsSend_request from '../../Api/ws/ws_request';
import { ClearFormSetFutios, StartMainCommonLoader } from '../../redux/actions/adminActions';
import { Button, FormControl, Modal } from 'react-bootstrap';
import PropTypes from "prop-types";

export default function CancelOrder(props) {
    const { device_id, ClearFormSet, selfInfo } = useSelector((state) => state.adminReducers);
    const Location = useLocation()?.pathname;
    const { data } = props;
    const [error, setError] = useState()
    const [state, setState] = useState()
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        description: "",
        cancel_reason:"",
        Data: {}
    });
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);

    const callCancelOrder = (e) =>{
        e.preventDefault();
        if(showModalNew?.cancel_reason){
            setState(false)
            setShowModalNew({
                ...showModalNew,
                open: true,
                title: "Confirmation",
                subtitle: "Order-Cancel",
                modalType: "Order-Cancel",
                description: "Are you sure to proceed for Cancel Order?",
                modalType: "not form",
                button: "Yes",
                Data: {}
            });
        }else{
            setError("Please enter reason")
        }
    }
    const ConfirmOrder = () =>{
        if(selfInfo?.user?.user_type === 'warehouse_manager'){
            if(Location?.split("/")[1] === "warehouse-approvals") {
                const param = {
                    "transmit": "broadcast",
                    "url": "wh_cancelled",
                    "request" :{
                         "order_id" : data?.order?.id,
                        "cancel_reason" : showModalNew?.cancel_reason,
                        "order_status":"REJECTED"
                        // "order_status" : props?.order_status,
                    },
                    'DeviceId':device_id,
                }
                wsSend_request(websocket, param)
            } else {
                dispatch(StartMainCommonLoader({
                    url: "order_cancelled",
                    loader: true
                }));
                const param = {
                    "transmit": "broadcast",
                    "url": "order_cancelled",
                    "request" :{
                        "order_id" : data?.order?.id,
                        "cancel_reason" : showModalNew?.cancel_reason,
                        order_status : props?.order_status,
                    },
                    'DeviceId':device_id,
                }
                wsSend_request(websocket, param)
            };
        }else{
            dispatch(StartMainCommonLoader({
                url: "order_cancelled",
                loader: true
            }));
            const param = {
                "transmit": "broadcast",
                "url": "order_cancelled",
                "request" :{
                    "order_id" : data?.order?.id,
                    "cancel_reason" : showModalNew?.cancel_reason,
                    order_status : props?.order_status,
                },
                'DeviceId':device_id,
            }
            wsSend_request(websocket, param)
        }
    }
    useEffect(() => {
        if(ClearFormSet?.action === 200 && ClearFormSet?.url == "order_cancelled") {
            setShowModalNew({
                open: false,
                title: "",
                modalType: "",
                description: "",
                cancel_reason: "",
                Data: {}
            })
            dispatch(ClearFormSetFutios({
                url:"",
                action:false
            }));
            Navigate('../');
        };
    }, [ ClearFormSet ]);

    const onChange = (e) =>{
        if(e.target.value == " "){
            e.target.value = "";
        }
        if(e.target.value!=""){
            setError("")
        }else{
            setError("Please enter reason")
        }
        setShowModalNew(data=>({
            ...data,
            cancel_reason: e.target.value,
        }))
    }
    const onClose = () =>{
        setState(false)
        setError("")
        setShowModalNew(data=>({
            ...data,
            cancel_reason: ""
        }))
    }

    if(!data || data?.order?.order_status === "CANCELLED" || data?.order?.order_status === "HALF_DISPATCH"){
        return <React.Fragment/>
    }
  return (
    <React.Fragment>
        <button onClick={()=>setState(true)} className={`btn button-1 w-auto ${props?.className}`}>
            {props?.text}
        </button>
        <Modal show={state} onHide={onClose} className='commonModal' centered>
            <Modal.Header className='border-bottom-0 bb-0 pb-0' closeButton>
                <Modal.Title className='h5'>Cancel Reason <span className='text-danger' >*</span></Modal.Title>
            </Modal.Header>
            <div className='p-3'>
                <FormControl onChange={onChange} value={showModalNew?.cancel_reason} placeholder='Enter a reason for cancel order' as='textarea' />
                <small className='text-danger' >{error}</small>
            </div>
           <div className='d-flex  px-3 pb-3'>
            <button className='btn button-1 w-auto ms-auto' onClick={callCancelOrder}>Submit</button>
           </div>
        </Modal>
        {showModalNew?.open && (<CommonewModel callBack={ConfirmOrder} setShowModalNew={setShowModalNew} showModalNew={{...showModalNew, cancel_reason:""}}/>)}
    </React.Fragment>
  )
}
CancelOrder.propTypes = {
    order_status: PropTypes.any,
    text: PropTypes.any,
}
CancelOrder.defaultProps = {
    order_status: "REJECTED",
    text: "Reject Order",
}