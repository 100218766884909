/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Badge } from 'react-bootstrap';
import DataTableFilter from "../../Common/DataTableFilter";
import AvatarSet from "../../Common/AvatarSet";
// import CommonToggle from "../../Common/CommonToggle";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link, useNavigate } from "react-router-dom";
import { WebSocketContext } from "../../../App";
import { ADD_CITIES_URL, ADD_PINCODES_URL, ADD_STATES_URL } from "../../Shared/constant";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../../Common/CommonPagination";
import CommonewModel from "../../Common/CommonewModel";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";

const Pincodes = (props) => {
    const { RouteName } = props;
    const { pincodeList, device_id, statelistall } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };


    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // get all city
    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "state_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    // api calling
    const APICall = () => {
        let param = { 
            "transmit": "broadcast", 
            "url": "pin_code_list",
            "request" : { 
                "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                "limit" : userLimit,
                "page_no" : currentPage, 
                "search" : userSearch.trim()
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    // create applications
    const cmnFnct = () => {
        Navigate(ADD_PINCODES_URL);
    };

    // get current state, city, gst number starting digits
    const GetCurrentDetails = (district_id) => {
        const CurrentCity = pincodeList?.district?.filter((elm) => district_id === elm?.id)[0];
        const GetCurrentStateName = statelistall?.filter((elm) => CurrentCity?.state_id === elm?.id)[0];
        return {
            city: CurrentCity?.district_name,
            state: GetCurrentStateName?.state_name,
            GSTCode: GetCurrentStateName?.gst_code
        }
    };

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={"Pincodes"}
            searchType={"Pincodes"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            ButtonSet={{
                buttontitle: "Create Pincodes",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={true}
        />
        <div className="tableView">
            <Scrollbars 
                style={{ height: pincodeList?.pagination?.total_records > 10 ? "calc(100vh - 185px)" : "calc(100vh - 150px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>State</th>
                            <th>City</th>
                            <th>Pincode</th>
                            <th>GST code</th>
                            <th>Status</th>
                            {/* <th>Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {pincodeList?.list?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, pincodeList?.pagination?.current_page, pincodeList?.pagination?.record_limit)}
                                </td>
                                <td>{GetCurrentDetails(elm?.district_id)?.state}</td>
                                <td>{GetCurrentDetails(elm?.district_id)?.city}</td>
                                <td>{elm?.pin_code}</td>
                                <td>{GetCurrentDetails(elm?.district_id)?.GSTCode}</td>
                                <td>{elm?.is_active ? (<Badge bg="success">Active</Badge>) : (<Badge bg="danger">Inactive</Badge>)}</td>
                                {/* <td>
                                    <Link className="btn edittable" to={`/edit-pincode/${elm?.id}`}>
                                        <i className="bi bi-pencil-square"></i>
                                    </Link>
                                </td> */}
                            </tr>)
                        })}
                        {pincodeList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="7">Pincodes list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {pincodeList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={pincodeList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModalNew?.open && (<CommonewModel setCurrentPage={setCurrentPage} setShowModalNew={setShowModalNew} showModalNew={showModalNew}/>)}
    </section>)
}

export default Pincodes;