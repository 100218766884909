const FileAuthConvert = async (accessToken, url) => {
    const options = {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    };
    const responce = await fetch(url, options)
    .then( res => res.blob() )
    .then( blob => {
        let file = window.URL.createObjectURL(blob);
        return {
            authImage: file,
            status:200
        };
    })
    .catch((error)=>{return error;});
    return responce;
};
  
export default FileAuthConvert;