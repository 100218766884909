/* eslint-disable */
import React, { useState, useContext, useEffect, useMemo } from "react";
import { Badge, Button, Form } from 'react-bootstrap';
import CommonToggle from "../../Common/CommonToggle";
import { Link, useNavigate, useLocation, useParams  } from "react-router-dom";
import { COURIERS_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import Scrollbars from "react-custom-scrollbars-2";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";

const AddCourier = () => {
    const { CourierList, ClearFormSet, device_id } = useSelector((state) => state.adminReducers);
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const param = useParams();
    const { websocket } = useContext(WebSocketContext);
    const [ formData, setFormData ] = useState({
        courier_name: "",
        is_active: true,
        email: "",
        remarks: "",
        gst_no: "",
        tracking_link: "",
        vehicle_no: "",
        warehouse_id: "",
        slug:"",
        vendor_id:"",
        service_type:"",
        service_type_air:""
    });
    const { id } = useParams();
    const StoreCourier = useMemo(()=>CourierList?.data?.find((elm) => elm?.id === id), [id]);
    const [ courier_name, setError_courier_name ] = useState("")
    const [tracking_link, setError_tracking_link] = useState("")
    const [remarks, setError_remarks] = useState("")
    const [gst_no, setError_gst_no] = useState("")
    const [slug, setErrorSlug] = useState("")
    const [vendor_id, setErrorVendorId] = useState("")
    const [service_type, setErrorServiceType] = useState("")
    const [service_type_air, setErrorServiceTypeAir] = useState("")

    useEffect(()=>{
        if(StoreCourier){
            setFormData((data)=>({
                ...data,
                ...StoreCourier,
            }))
        }
    },[StoreCourier])
    // create and edit brand
    function checksum(gstNumber){
        const gstRegex = /^(?:(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1})|(\d{2}[A-Z]{4}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}))$/;
        return gstRegex.test(gstNumber);
    }

    function validateVehicleNumber(vehicleNumber) {
        const vehicleRegex = /^([A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{4})$/;
        return vehicleRegex.test(vehicleNumber);
    }
    const CreateBrand = () => {
        if(
            formData?.courier_name !== "" &&
            // formData?.gst_no !== "" &&
            // checksum(formData?.gst_no) &&
            // validateVehicleNumber(formData?.vehicle_no) &&
            formData?.tracking_link !== "" &&
            formData?.remarks !== "" &&
            formData?.is_active !== "" &&
            formData?.slug !=="" 
        ){
            const payload = { 
                "transmit": "broadcast",
                "url": "courier_add",
                "request": {
                    "courier_name": formData?.courier_name,
                    "gst_no": formData?.gst_no,
                    "vehicle_no": formData?.vehicle_no,
                    "tracking_link": formData?.tracking_link,
                    "slug":formData?.slug,
                    "vendor_id":formData?.vendor_id,
                    "service_type":formData?.service_type,
                    "service_type_air":formData.service_type_air,
                    "remarks": formData?.remarks,
                    "is_active": formData?.is_active,
                },
                DeviceId: device_id
            };
            if(id){
                if( formData?.tracking_link !== "" &&formData?.remarks !== "" &&formData?.is_active !== ""&&formData?.slug !=="" &&formData?.courier_name !== "" && formData?.slug !==null && formData?.vendor_id !==null && formData?.vendor_id !=="" &&  formData?.service_type !==null &&  formData?.service_type !== ""  ){
                    payload.request.courier_id = id;
                    payload.url = "courier_edit";
                }else{
                    CheckValid(formData?.courier_name, {type: 'courier_name', error: setError_courier_name});
                    // CheckValid(formData?.gst_no, {type: 'gst_no', error: setError_gst_no})
                     CheckValid(formData?.tracking_link, {type: 'tracking_link', error: setError_tracking_link})
                    CheckValid(formData?.remarks, {type: 'Remark', error: setError_remarks})
                    CheckValid(formData?.slug, {type: 'slug', error: setErrorSlug});
                    CheckValid(formData?.vendor_id, {type: 'vendor_id', error: setErrorVendorId})
                    CheckValid(formData?.service_type, {type: 'service_type', error: setErrorServiceType})
                    // CheckValid(formData?.service_type_air, {type: 'service_type_air', error: setErrorServiceTypeAir})
                }
            }
            wsSend_request(websocket, payload);
        }else{
            CheckValid(formData?.courier_name, {type: 'courier_name', error: setError_courier_name});
            // CheckValid(formData?.gst_no, {type: 'gst_no', error: setError_gst_no})
            CheckValid(formData?.tracking_link, {type: 'tracking_link', error: setError_tracking_link})
            CheckValid(formData?.remarks, {type: 'Remark', error: setError_remarks})
            CheckValid(formData?.slug, {type: 'slug', error: setErrorSlug});
            CheckValid(formData?.vendor_id, {type: 'vendor_id', error: setErrorVendorId})
            CheckValid(formData?.service_type, {type: 'service_type', error: setErrorServiceType})
            // CheckValid(formData?.service_type_air, {type: 'service_type_air', error: setErrorServiceTypeAir})
        }
    };


    const onChange = (e) =>{
        let { value } = e.target;
        value = value.replace(/\s+/g, ' ');
        if (value.charAt(0) === ' ') {
            e.target.value = '';
        }
        if( e.target.value == " "){
            e.target.value = "";
        }
        if(e.target.name === "gst_no" || e.target.name === "vehicle_no"){
            e.target.value = e.target.value.toUpperCase()
        }
        setFormData((data)=>({
            ...data,
            [e.target.name]: e.target.value,
        }));
    }

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);
    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            Dispatch(ClearFormSetFutios({
                url:"",
                action:false
            }));
            Navigate(COURIERS_URL);
        };
    }, [ ClearFormSet ]);

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={COURIERS_URL} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>

        <Scrollbars
            style={{ height: "calc(100vh - 147px)" }} 
            renderView={props => <div {...props} className="view"/>}
            className="ScrollbarsSidebarHide"
        >
        <div className="tabsWrappers">
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Courier Name <span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="text" 
                                value={formData?.courier_name}
                                name="courier_name"
                                onChange={onChange}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'courier_name', error: setError_courier_name})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Courier Name"
                            />
                            {courier_name !== "" &&<span className="error_medotory">{courier_name}</span>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>GST No</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={formData?.gst_no}
                                name="gst_no"
                                onChange={onChange}
                                // onKeyUp={(e) => CheckValid(e.target.value, {type: 'gst_no', error: setError_gst_no})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter GST No"
                            />
                            {/* {gst_no !== "" &&<span className="error_medotory">{gst_no}</span>} */}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Vehicle No</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={formData?.vehicle_no}
                                name="vehicle_no"
                                onChange={onChange}
                                // onKeyUp={(e) => CheckValid(e.target.value, {type: 'vehicle_no', error: setError_vehicle_no})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Vehicle No"
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Tracking Link<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="text" 
                                value={formData?.tracking_link}
                                name="tracking_link"
                                onChange={onChange}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'tracking_link', error: setError_tracking_link})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Tracking Link"
                            />
                            {tracking_link !== "" &&<span className="error_medotory">{tracking_link}</span>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Slug<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="text" 
                                value={formData?.slug}
                                name="slug"
                                onChange={onChange}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'slug', error: setErrorSlug})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Slug"
                            />
                            {slug !== "" &&<span className="error_medotory">{slug}</span>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Vendor Id<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="text" 
                                value={formData?.vendor_id}
                                name="vendor_id"
                                onChange={onChange}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'vendor_id', error: setErrorVendorId})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Vendor Id"
                            />
                            {vendor_id !== "" &&<span className="error_medotory">{vendor_id}</span>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Service Type<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="text" 
                                value={formData?.service_type}
                                name="service_type"
                                onChange={onChange}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'service_type', error: setErrorServiceType})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Service Type"
                            />
                            {service_type !== "" &&<span className="error_medotory">{service_type}</span>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Service Type Air</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={formData?.service_type_air}
                                name="service_type_air"
                                onChange={onChange}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'service_type_air', error: setErrorServiceType})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Service Type Air"
                            />
                            {/* {service_type_air !== "" &&<span className="error_medotory">{service_type_air}</span>} */}
                        </Form.Group>
                    </div>
                    {/* <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Service Type Air<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="text" 
                                value={formData?.service_type_air}
                                name="servce_type_air"
                                onChange={onChange}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'servce_type_air', error: setErrorServiceTypeAir})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Service Type Air"
                            />
                            {service_type_air !== "" &&<span className="error_medotory">{service_type_air}</span>}
                        </Form.Group>
                    </div> */}
                    <div className="col-12">
                        <Form.Group className="mb-3" >
                            <Form.Label>Remark <span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                as={"textarea"}
                                type="textarea" 
                                value={formData?.remarks}
                                name="remarks"
                                onChange={onChange}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'Remark', error: setError_remarks})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Remark"
                            />
                            {remarks !== "" &&<span className="error_medotory">{remarks}</span>}
                        </Form.Group>
                    </div>

                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Status</Form.Label>
                            <div className="ToggleMainWrap">
                                <CommonToggle 
                                    valueToggle={formData?.is_active}
                                    setValueToggle={() => setFormData({...formData, is_active: !formData?.is_active})}
                                    name={""}
                                />
                                {formData?.is_active ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>}
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Button className="formbtn widthfullbtn" onClick={() => CreateBrand()} variant="primary" >
                           {id ? "Update Courier":" Create Courier"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        </Scrollbars>
    </section>)
}

export default AddCourier;