/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Badge } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector,useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import { COURIERS_URL } from "../../Shared/constant";
import { Link, useNavigate } from "react-router-dom";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";

const CouriersListPage = (props) => {
    const navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [ userType, setUserType ] = useState("");
    const today = new Date();
    const { device_id, CourierList, AllDealerFranchiseTypeListAll, selfInfo } = useSelector((state) => state.adminReducers);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Masters[4]?.Edit || PermissionsAccess?.Masters[4]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "courier_list",
                "request" : { 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch.trim(),
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "order_by" :"-updated_at"
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }
    }, [ websocket, userSearch, currentPage, userType, userLimit ]);


    const GetFranchiseName = (billing_id) => {
        const current = AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.id === billing_id)[0]
        return {
            name: current?.customer_name
        };
    };
    // open order details modal
    const OrderDetailsModal = (order_code) => {
        navigate(`${COURIERS_URL}/${order_code}`);
        // let param = { 
        //     "transmit": "broadcast", 
        //     "url": "order_detail",
        //     "request" : { 
        //         "order_id" : order_code,
        //     },
        //     "DeviceId" : device_id
        // };
        // wsSend_request(websocket, param);
        // setOrderViewerModal({...OrderViewerModal, open: !OrderViewerModal?.open});
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={"Courier"}
            searchType={"Courier Name"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            setUserType={setUserType}
            userType={userType}
            searchdisable={true}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Masters[4]?.Edit) ? true : false}
            ButtonSet={{buttontitle:"Add", function:()=>navigate(`${COURIERS_URL}/add`)}}
            ActiveInactiveFilter={true}
        />
        <div className="tableView">
            <Scrollbars 
                style={{ height: CourierList?.pagination?.total_records > 10 ? "calc(100vh - 185px)" : "calc(100vh - 150px)" }} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th>SNo</th>
                            <th>Courier Name</th>
                            <th>GST</th>
                            <th>Vehicle No.</th>
                            <th>Tracking Link</th>
                            <th>Slug</th>
                            <th>Status</th>
                            <th>Remarks</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Masters[4]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {CourierList?.data?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>{GetCurrentTableSRNo(index, CourierList?.pagination?.current_page, CourierList?.pagination?.record_limit)}</td>
                                <td>
                                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Masters[4]?.Edit) ? (<b onClick={() => OrderDetailsModal(elm?.id)} className="tableBolanchor">{elm?.courier_name}</b>) : <b className="tableBolanchor">{elm?.courier_name}</b>}
                                </td>
                                <td>{elm?.gst_no}</td>
                                <td>{elm?.vehicle_no}</td>
                                <td>{elm?.tracking_link}</td>
                                <td>{elm?.slug === null ? "-" : elm?.slug}</td>
                                <td>
                                    {elm?.is_active  ? (<Badge bg="success">Active</Badge>) : (<Badge bg="danger">InActive</Badge>)}
                                </td>
                                <td>{elm?.remarks}</td>
                                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Masters[4]?.Edit) && (<td>
                                    <Link className="btn edittable" to={`${COURIERS_URL}/${elm?.id}`} >
                                        <i className="bi bi-pencil-square"></i>
                                    </Link>
                                </td>)}
                            </tr>)
                        })}
                        {CourierList?.data?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="10">Courier list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {CourierList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={CourierList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
    </section>)
}

export default CouriersListPage;