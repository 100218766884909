/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Badge, Button, Form } from 'react-bootstrap';
import { Link, useNavigate, useLocation, useParams  } from "react-router-dom";
import { POINT_VALUE_REFURBISHED_REPORT } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { NavigationPointList } from "../../../redux/actions/adminActions";

const AddPointSettings = () => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ ApplicationState, setApplicationState ] = useState({
        StartPoint: "",
        EndPoint: "",
        Percentage: "",
        PointScore: "",
    });
    const { roomId } = useParams();
    const { ClearFormSet, PointSettingsList, device_id } = useSelector((state) => state.adminReducers);
    const CurrentData_ = PointSettingsList?.list?.filter((elm) => elm?.id === roomId)[0];
    const exceptThisCostSymbols = ["e", "E", "+", "-"];

    // error state
    const [ errorStartPoint, setErrorStartPoint ] = useState("");
    const [ errorEndPoint, setErrorEndPoint ] = useState("");
    const [ errorPercentage, setErrorPercentage ] = useState("");
    const [ errorPointScore, setErrorPointScore ] = useState("");

    useEffect(() => {
        if(LocationRoute === "edit-point-settings") {
            setApplicationState({
                ...ApplicationState,
                StartPoint: CurrentData_?.start_point,
                EndPoint: CurrentData_?.end_point,
                Percentage: CurrentData_?.max_percentage,
                PointScore: CurrentData_?.point_score,
            });
        };
    }, [ CurrentData_ ]);

    // create and edit brand
    const CreateBrand = () => {
        // if(ApplicationState?.StartPoint !== "" && ApplicationState?.EndPoint !== ""  && ApplicationState?.Percentage !== "" && ApplicationState?.PointScore !== "") {
        if(ApplicationState?.StartPoint !== "" && ApplicationState?.EndPoint !== ""  && ApplicationState?.Percentage !== "") {
            if(LocationRoute === "edit-point-settings") {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "refurbished_point_value_edit",
                    "request" : { 
                        "refurbished_point_value_id" : CurrentData_?.id,
                        "start_point" : ApplicationState?.StartPoint,
                        "end_point" : ApplicationState?.EndPoint,
                        "max_percentage" : ApplicationState?.Percentage,
                        // "point_score" : ApplicationState?.PointScore,
                        "point_score" : 0,
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            } else {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "refurbished_point_value_add",
                    "request" : { 
                        "start_point" : ApplicationState?.StartPoint,
                        "end_point" : ApplicationState?.EndPoint,
                        "max_percentage" : ApplicationState?.Percentage,
                        // "point_score" : ApplicationState?.PointScore,
                        "point_score" : 0,
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            }
        } else {
            CheckValid(ApplicationState?.StartPoint, {type: 'StartPoint', error: setErrorStartPoint});
            CheckValid(ApplicationState?.EndPoint, {type: 'EndPoint', error: setErrorEndPoint});
            CheckValid(ApplicationState?.Percentage, {type: 'Percentage', error: setErrorPercentage});
            // CheckValid(ApplicationState?.PointScore, {type: 'PointScore', error: setErrorPointScore});
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && (ClearFormSet?.url === "refurbished_point_value_add" || ClearFormSet?.url === "refurbished_point_value_edit")) {
            Navigate(POINT_VALUE_REFURBISHED_REPORT);
            Dispatch(NavigationPointList("Pont_settings"));
        };
    }, [ ClearFormSet ]);

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    // add start and end point 
    const AddPointsNumber = (e, url) => {
        const inputValue = e.target.value;
        // Regular expression to match numbers from 1 to 10 with up to 2 decimal places
        const regex = /^(10(\.0{0,2})?|[1-9](\.\d{0,2})?)?$/;
        if (regex.test(inputValue)) {
            if(url === "StartPoint") {
                setApplicationState({...ApplicationState, StartPoint: inputValue})
            } else {
                setApplicationState({...ApplicationState, EndPoint: inputValue})
            };
        };
    };

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={POINT_VALUE_REFURBISHED_REPORT} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappers">
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Start Point<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="number" 
                                className="inputBoxs"
                                value={ApplicationState?.StartPoint} 
                                onChange={(e) => AddPointsNumber(e, "StartPoint")}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'StartPoint', error: setErrorStartPoint})}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e)
                                    exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                }}
                                placeholder="Enter Start Point" 
                                // min="0"
                            />
                            {errorStartPoint !== "" && (<span className="error_medotory">{errorStartPoint}</span>)}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>End Point<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="number" 
                                className="inputBoxs"
                                value={ApplicationState?.EndPoint} 
                                onChange={(e) => AddPointsNumber(e, "EndPoint")}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'EndPoint', error: setErrorEndPoint})}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e)
                                    exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                }}
                                placeholder="Enter End Point" 
                                // min="0"
                            />
                            {errorEndPoint !== "" && (<span className="error_medotory">{errorEndPoint}</span>)}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Max Percentage<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="number" 
                                className="inputBoxs"
                                value={ApplicationState?.Percentage} 
                                onChange={(e) => setApplicationState({...ApplicationState, Percentage: e?.target?.value.replace(/^0/, "")})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'Percentage', error: setErrorPercentage})}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e)
                                    exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                }}
                                placeholder="Enter Max Percentage" 
                                min="0"
                            />
                            {errorPercentage !== "" && (<span className="error_medotory">{errorPercentage}</span>)}
                        </Form.Group>
                    </div>
                    {/* <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Point Score<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="number" 
                                className="inputBoxs"
                                value={ApplicationState?.PointScore} 
                                onChange={(e) => setApplicationState({...ApplicationState, PointScore: e.target.value <= 100 && e?.target?.value.replace(/^0/, "")})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'PointScore', error: setErrorPointScore})}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e)
                                    exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                }}
                                placeholder="Enter Point Score" 
                                min="0"
                            />
                            {errorPointScore !== "" && (<span className="error_medotory">{errorPointScore}</span>)}
                        </Form.Group>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Button className="formbtn widthfullbtn" onClick={() => CreateBrand()} variant="primary" >
                            {LocationRoute === "edit-point-settings" ? "Update" : "Add"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default AddPointSettings;