/* eslint-disable */
import React from "react";
import { Image } from "react-bootstrap";
import { FILE_AUTH_URL } from "../Shared/constant";
import ProductDefaultImage from "../../assets/img/DefaultProduct.jpg";
import { useSelector } from "react-redux";
import FileDownload from "./FileDownload";

const ImagePreviwer = (props) => {
    const { ImagePreviewerModal, CloseImageModal } = props;
    const { accessToken } = useSelector((state) => state.adminReducers);

    return(<div className="Image_preview_wrapper">
        <h4>Preview Image</h4>
        <i className="bi bi-download" onClick={() => FileDownload(ImagePreviewerModal?.Images_id, accessToken)}></i>
        <i className="bi bi-x-lg" onClick={CloseImageModal}></i>
        <div className="preivew_image">
            {ImagePreviewerModal?.type === "image" ? (
                <Image src={FILE_AUTH_URL + "view/file/" + ImagePreviewerModal?.Images_id} onError={(e) => e.target.src = ProductDefaultImage} alt="image" />
                ) : (<video controls autoPlay>
                    <source src={FILE_AUTH_URL + "view/file/" + ImagePreviewerModal?.Images_id} type="video/mp4"></source>
                    Your browser does not support the video tag.
                </video>)}
        </div>
    </div>)
};

export default ImagePreviwer;