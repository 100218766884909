/* eslint-disable */
import moment from "moment";
import 'moment-timezone';

export function TimeSense(created_at) {
    if (!created_at) {
        return '';
    }

    var currentTime = new Date();
    var createdAtTime = new Date(created_at);
    var durationInMilliseconds = currentTime - createdAtTime;

    var millisecondsInSecond = 1000;
    var millisecondsInMinute = millisecondsInSecond * 60;
    var millisecondsInHour = millisecondsInMinute * 60;
    var millisecondsInDay = millisecondsInHour * 24;

    var seconds = Math.floor(durationInMilliseconds / millisecondsInSecond);

    if (seconds < 60) {
        return `${seconds} Sec${seconds !== 1 ? 's' : ''}`;
    } else if (seconds < 60 * 60) {
        var minutes = Math.floor(seconds / 60);
        return `${minutes} Min${minutes !== 1 ? 's' : ''} `;
    } else if (seconds < 60 * 60 * 24) {
        var hours = Math.floor(seconds / (60 * 60));
        var remainingMinutes = Math.floor((seconds % (60 * 60)) / 60);
        return `${hours} Hr${hours !== 1 ? 's' : ''} ${remainingMinutes} Min${remainingMinutes !== 1 ? 's' : ''}`;
    } else {
        var hours = Math.floor(seconds / (60 * 60));
        var remainingMinutes = Math.floor((seconds % (60 * 60)) / 60);
        var days = Math.floor(seconds / (60 * 60 * 24));
        var remainingHours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
        return `${days} Day${days !== 1 ? 's' : ''} ${remainingHours} Hr${remainingHours !== 1 ? 's' : ''} ${remainingMinutes} Min${remainingMinutes !== 1 ? 's' : ''}`;
    }
}

export function getColor(state) {
    // const state = getColorCode(entity, condition, dateTime)

    if(state?.priority == 3){
        return {
            color_code:"light-red",
            text:"High"
        }
    }
    if(state?.priority == 2){
        return {
            color_code:"light-orange",
            text:"Medium"
        }
    }
    if(state?.priority == 1){
        return {
            color_code:"light-green",
            text:"Low"
        }
    }
    if(state?.priority == 0){
        return {
            color_code:"light-grey",
            text:"Pause"
        }
    }
        return {
            color_code:"",
            text:""
        }
}

const getColorCode = (entity, condition, dateTime) =>{
    if (!dateTime || !entity || !condition) {
        return '';
    }

    const currentTime = new Date();
    const createdAtTime = new Date(dateTime);
    const durationInHours = (currentTime - createdAtTime) / (1000 * 60 * 60); // Convert milliseconds to hours
    switch (entity) {
        case 'Marketplaces':
            switch (condition) {
                case 'Criticality':
                    return durationInHours >= 24 ? '#dc3545' : (durationInHours > 5 ? '#ffc107' : '#2aa13d');
                case 'TAT':
                    return durationInHours >= 24 ? '#dc3545' : (durationInHours > 5 ? '#ffc107' : '#2aa13d');
                default:
                    return '';
            }
        case 'Dealer':
            switch (condition) {
                case 'Paused':
                    return durationInHours >= 24 ? '#dc3545' : '';
                case 'Scheduled':
                    return durationInHours >= 24 ? '#dc3545' : '';
                case 'TAT':
                    return durationInHours >= 24 ? '#dc3545' : (durationInHours > 5 ? '#ffc107' : '#2aa13d');
                case 'Created time':
                    return durationInHours >= 5 ? '#ffc107' : '#2aa13d';
                default:
                    return '';
            }
        case 'BDC/End Customer':
            switch (condition) {
                case 'Criticality':
                    return durationInHours >= 24 ? '#dc3545' : (durationInHours > 5 ? '#ffc107' : '#2aa13d');
                case 'TAT':
                    return durationInHours >= 24 ? '#dc3545' : (durationInHours > 5 ? '#ffc107' : '#2aa13d');
                default:
                    return '';
            }
        default:
            return '';
    }
}

export const getOrderTypeSource = (elm) =>{
    if(elm?.order_type == "FO"){
        return "Dealer";
    }else if(elm?.order_type == "CO"){
        return "Sale";
    }else if(elm?.order_type == "WO"){
        return "Internal Warehouse to Warehouse";
    }else if(elm?.order_type == "AO"){
        return "Amazon, Website, Flipkart";
    }
}

export const GetGSTAmount = (price, gstRate) => {
    return parseFloat(parseFloat(price)  - parseFloat( parseFloat(price) / (1 + (parseFloat(gstRate) / 100)) ).toFixed(2)).toFixed(2    ) ;
}

export const printContent = (id) => {
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    const frameDocument = iframe.contentDocument || iframe.contentWindow.document;

    // Get the HTML content and CSS
    const content = document.getElementById(id).innerHTML;
    const styles = Array.from(document.styleSheets)
        .map(styleSheet => {
            try {
                return Array.from(styleSheet.cssRules)
                    .map(rule => rule.cssText)
                    .join('\n');
            } catch (e) {
                console.warn('Could not read CSS rules from StyleSheet', styleSheet.href);
            }
        })
        .join('\n');

    // Write HTML content and CSS to iframe document
    frameDocument.write(`
        <html>
            <head>
                <style>${styles}</style>
            </head>
            <body>${content}</body>
        </html>
    `);
    frameDocument.close();

    // Trigger the print dialog
    iframe.contentWindow.print();
};
export const getStringOfErrorValues = (value) => {
    if (typeof value === "object") {
        if (Array.isArray(value)) {
            return value.map(getStringOfErrorValues).join(', ');
        } else {
            return Object.values(value).map(getStringOfErrorValues).join(', ');
        }
    } else {
        return String(value);
    }
}