/* eslint-disable eqeqeq */
// const GetViewFilesAPI = async (url, qr_token) => {
//     const options = {
//       headers: {
//         'Authorization': `Bearer ${qr_token}`
//       }
//     };
//     const responce = await fetch(url, options)
//     .then( res => res.blob() )
//     .then( blob => {
//       let file = window.URL.createObjectURL(blob);
//       return file;
//     }).catch((error)=>{return error;});
//     return responce;
// };

// export default GetViewFilesAPI;

import axios from 'axios';

const GetViewFilesAPI = async (url, qr_token, onProgress, id) => {
  try {
    const options = {
      headers: {
        'Authorization': `Bearer ${qr_token}`
      },
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        if (onProgress) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          onProgress({
            percentCompleted, id
          });
        }
      }
    };

    const response = await axios.get(url, options);
    const file = window.URL.createObjectURL(response.data);
    return file;

  } catch (error) {
    return error;
  }
};

export default GetViewFilesAPI;
