/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Button, Form } from 'react-bootstrap';
import { Link, useNavigate, useLocation, useParams  } from "react-router-dom";
import { AGE_VALUE_REPORT } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";

const AddAgeValue = () => {
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ ApplicationState, setApplicationState ] = useState({
        MinDay: "",
        MaxDay: "",
        Percentage: "",
        PointScore: "",
    });
    const { roomId } = useParams();
    const { ClearFormSet, AgeValueList, device_id } = useSelector((state) => state.adminReducers);
    const CurrentData_ = AgeValueList?.list?.filter((elm) => elm?.id === roomId)[0];
    const exceptThisCostSymbols = ["e", "E", "+", "-"];

    // error state
    const [ errorMinDay, setErrorMinDay ] = useState("");
    const [ errorMaxDay, setErrorMaxDay ] = useState("");
    const [ errorPercentage, setErrorPercentage ] = useState("");
    const [ errorPointScore, setErrorPointScore ] = useState("");

    useEffect(() => {
        if(LocationRoute === "edit-age-value") {
            setApplicationState({
                ...ApplicationState,
                MinDay: CurrentData_?.min_day,
                MaxDay: CurrentData_?.max_day,
                Percentage: CurrentData_?.max_percentage,
                PointScore: CurrentData_?.age_score,
            });
        };
    }, [ CurrentData_ ]);

    // create and edit brand
    const CreateBrand = () => {
        if(ApplicationState?.MinDay !== "" && ApplicationState?.MaxDay !== ""  && ApplicationState?.Percentage !== "" && ApplicationState?.PointScore !== "") {
            if(LocationRoute === "edit-age-value") {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "refurbished_age_edit",
                    "request" : { 
                        "refurbished_age_id": CurrentData_?.id,
                        "min_day" : ApplicationState?.MinDay,
                        "max_day" : ApplicationState?.MaxDay,
                        "max_percentage" : ApplicationState?.Percentage,
                        "age_score" : ApplicationState?.PointScore,
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            } else {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "refurbished_age_add",
                    "request" : { 
                        "min_day" : ApplicationState?.MinDay,
                        "max_day" : ApplicationState?.MaxDay,
                        "max_percentage" : ApplicationState?.Percentage,
                        "age_score" : ApplicationState?.PointScore,
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            }
        } else {
            CheckValid(ApplicationState?.MinDay, {type: 'MinDay', error: setErrorMinDay});
            CheckValid(ApplicationState?.MaxDay, {type: 'MaxDay', error: setErrorMaxDay});
            CheckValid(ApplicationState?.Percentage, {type: 'Percentage', error: setErrorPercentage});
            CheckValid(ApplicationState?.PointScore, {type: 'PointScore', error: setErrorPointScore});
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && (ClearFormSet?.url === "refurbished_age_add" || ClearFormSet?.url === "refurbished_age_edit")) {
            Navigate(AGE_VALUE_REPORT);
        };
    }, [ ClearFormSet ]);

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={AGE_VALUE_REPORT} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappers">
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Min Day<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="number" 
                                className="inputBoxs"
                                value={ApplicationState?.MinDay} 
                                onChange={(e) => setApplicationState({...ApplicationState, MinDay: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'MinDay', error: setErrorMinDay})}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e)
                                    exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                }}
                                placeholder="Enter Min Day" 
                                // min="0"
                            />
                            {errorMinDay !== "" && (<span className="error_medotory">{errorMinDay}</span>)}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Max Day<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="number" 
                                className="inputBoxs"
                                value={ApplicationState?.MaxDay} 
                                onChange={(e) => setApplicationState({...ApplicationState, MaxDay: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'MaxDay', error: setErrorMaxDay})}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e)
                                    exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                }}
                                placeholder="Enter Max Day" 
                                // min="0"
                            />
                            {errorMaxDay !== "" && (<span className="error_medotory">{errorMaxDay}</span>)}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Max Percentage<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="number" 
                                className="inputBoxs"
                                value={ApplicationState?.Percentage} 
                                onChange={(e) => setApplicationState({...ApplicationState, Percentage: e?.target?.value.replace(/^0/, "")})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'Percentage', error: setErrorPercentage})}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e)
                                    exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                }}
                                placeholder="Enter Max Percentage" 
                                min="0"
                            />
                            {errorPercentage !== "" && (<span className="error_medotory">{errorPercentage}</span>)}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Age Score<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="number" 
                                className="inputBoxs"
                                value={ApplicationState?.PointScore} 
                                onChange={(e) => setApplicationState({...ApplicationState, PointScore: e.target.value <= 100 && e?.target?.value.replace(/^0/, "")})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'PointScore', error: setErrorPointScore})}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e)
                                    exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                }}
                                placeholder="Enter Age Score" 
                                min="0"
                            />
                            {errorPointScore !== "" && (<span className="error_medotory">{errorPointScore}</span>)}
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Button className="formbtn widthfullbtn" onClick={() => CreateBrand()} variant="primary" >
                            {LocationRoute === "edit-age-value" ? "Update" : "Add"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default AddAgeValue;