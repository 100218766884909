/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Badge, Form, Button  } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { WebSocketContext } from "../../App";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector } from "react-redux";
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";

const Settings = (props) => {
    const { RouteName } = props;
    const { device_id, RefurbishedSettings, selfInfo } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const [ InvoiceGeared, setInvoiceGeared ] = useState(({
        Invoice: {
            Yes: "",
            No: "",
            YesScore: "",
            NoScore: ""
        },
        Geared: {
            Yes: "",
            No: "",
            YesScore: "",
            NoScore: ""
        },
        Suspension: {
            Yes: "",
            No: "",
            YesScore: "",
            NoScore: ""
        },
    }));
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // error state
    const [ errorInvoice, setErrorInvoice ] = useState("");
    const [ errorGeared, setErrorGeared ] = useState("");
    const [ errorSuspension, setErrorSuspension ] = useState("");

    // get current settings
    useEffect(() => {
        setInvoiceGeared(({
            ...InvoiceGeared,
            Invoice: {
                Yes: RefurbishedSettings?.invoice_yes,
                No: RefurbishedSettings?.invoice_no,
                YesScore: RefurbishedSettings?.invoice_yes_score,
                NoScore: RefurbishedSettings?.invoice_no_score,
            },
            Geared: {
                Yes: RefurbishedSettings?.geared_yes,
                No: RefurbishedSettings?.geared_no,
                YesScore: RefurbishedSettings?.geared_yes_score,
                NoScore: RefurbishedSettings?.geared_no_score,
            },
            Suspension: {
                Yes: RefurbishedSettings?.suspension_yes,
                No: RefurbishedSettings?.suspension_no,
                YesScore: RefurbishedSettings?.suspension_yes_score,
                NoScore: RefurbishedSettings?.suspension_no_score,
            }
        }));

        if(RefurbishedSettings?.invoice_yes?.trim() === "" || RefurbishedSettings?.invoice_no?.trim() === "" || RefurbishedSettings?.suspension_score?.trim() === "") {
            setErrorInvoice("Invoice percentage cannot be empty");
        } else {
            setErrorInvoice("");
        };
        if(RefurbishedSettings?.geared_yes?.trim() === "" || RefurbishedSettings?.geared_no?.trim() === "" || RefurbishedSettings?.geared_score?.trim() === "") {
            setErrorGeared("Geared percentage cannot be empty");
        } else {
            setErrorGeared("");
        };
        if(RefurbishedSettings?.suspension_yes?.trim() === "" || RefurbishedSettings?.suspension_no?.trim() === "") {
            setErrorSuspension("Suspension percentage cannot be empty");
        } else {
            setErrorSuspension("");
        };
    }, [ RefurbishedSettings ]);

    const NumberValidHundredValue = (e, type, method) => {
        if (e.target.value <= 100) {
            setInvoiceGeared({
                ...InvoiceGeared, 
                [type]: {
                    ...InvoiceGeared?.[type],
                    [method] : e.target.value.replace(/^0/, ""),
                } 
            })
        };
    };

    // save percentage
    const save_percentage = () => {
        if((InvoiceGeared?.Invoice?.Yes?.trim() !== "" && InvoiceGeared?.Invoice?.No?.trim() !== "" && InvoiceGeared?.Invoice?.YesScore !== "" && InvoiceGeared?.Invoice?.NoScore !== "") && (InvoiceGeared?.Geared?.Yes?.trim() !== "" && InvoiceGeared?.Geared?.No?.trim() !== "" && InvoiceGeared?.Geared?.YesScore !== "" && InvoiceGeared?.Geared?.NoScore !== "") && (InvoiceGeared?.Suspension?.Yes?.trim() !== "" && InvoiceGeared?.Suspension?.No?.trim() !== "" && InvoiceGeared?.Suspension?.YesScore !== "" && InvoiceGeared?.Suspension?.NoScore !== "")) {
            let param = { 
                "transmit": "broadcast", 
                "url": "refurbished_settings_update",
                "request" : { 
                    "invoice_yes": InvoiceGeared?.Invoice?.Yes, 
                    "invoice_no": InvoiceGeared?.Invoice?.No,
                    "invoice_yes_score": InvoiceGeared?.Invoice?.YesScore,
                    "invoice_no_score": InvoiceGeared?.Invoice?.NoScore,
                    "geared_yes": InvoiceGeared?.Geared?.Yes, 
                    "geared_no": InvoiceGeared?.Geared?.No,
                    "geared_yes_score": InvoiceGeared?.Geared?.YesScore,
                    "geared_no_score": InvoiceGeared?.Geared?.NoScore,
                    "suspension_yes": InvoiceGeared?.Suspension?.Yes,
                    "suspension_no": InvoiceGeared?.Suspension?.No,
                    "suspension_yes_score": InvoiceGeared?.Suspension?.YesScore,
                    "suspension_no_score": InvoiceGeared?.Suspension?.NoScore,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        } else {
            if(InvoiceGeared?.Invoice?.Yes?.trim() === "" || InvoiceGeared?.Invoice?.No?.trim() === "" || InvoiceGeared?.Invoice?.YesScore === "" || InvoiceGeared?.Invoice?.NoScore === "") {
                setErrorInvoice("Invoice percentage cannot be empty");
            } else {
                setErrorInvoice("");
            };
            if(InvoiceGeared?.Geared?.Yes?.trim() === "" || InvoiceGeared?.Geared?.No?.trim() === "" || InvoiceGeared?.Geared?.YesScore === "" || InvoiceGeared?.Geared?.NoScore === "") {
                setErrorGeared("Geared percentage cannot be empty");
            } else {
                setErrorGeared("");
            };
            if(InvoiceGeared?.Suspension?.Yes?.trim() === "" || InvoiceGeared?.Suspension?.No?.trim() === "") {
                setErrorSuspension("Suspension percentage cannot be empty");
            } else {
                setErrorSuspension("");
            };
        };
    };

    const APICall = () => {
        let param = { 
            "transmit": "broadcast", 
            "url": "refurbished_settings_get", 
            "request" : {},
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket ]);

    const disablebtn = parseInt(InvoiceGeared?.Invoice?.Yes) === parseInt(RefurbishedSettings?.invoice_yes) && parseInt(InvoiceGeared?.Invoice?.No) === parseInt(RefurbishedSettings?.invoice_no) && parseInt(InvoiceGeared?.Invoice?.YesScore) === parseInt(RefurbishedSettings?.invoice_yes_score) && parseInt(InvoiceGeared?.Invoice?.NoScore) === parseInt(RefurbishedSettings?.invoice_no_score) && parseInt(InvoiceGeared?.Geared?.Yes) === parseInt(RefurbishedSettings?.geared_yes) && parseInt(InvoiceGeared?.Geared?.No) === parseInt(RefurbishedSettings?.geared_no) && parseInt(InvoiceGeared?.Geared?.YesScore) === parseInt(RefurbishedSettings?.geared_yes_score) && parseInt(InvoiceGeared?.Geared?.NoScore) === parseInt(RefurbishedSettings?.geared_no_score) && parseInt(InvoiceGeared?.Suspension.Yes) === parseInt(RefurbishedSettings?.suspension_yes) && parseInt(InvoiceGeared?.Suspension?.No) === parseInt(RefurbishedSettings?.suspension_no) && parseInt(InvoiceGeared?.Suspension?.YesScore) === parseInt(RefurbishedSettings?.suspension_yes_score) && parseInt(InvoiceGeared?.Suspension?.NoScore) === parseInt(RefurbishedSettings?.suspension_no_score);

    return(<section className="Main_section">
        <div className="tableView">
            <Scrollbars 
                style={{ height: "calc(100vh - 100px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <div className="HeaderDivider" style={{ paddingTop: "40px" }}>
                    <h6 className="subtitleForm PurchaseOrderCheckbox">Invoice</h6>
                    <div className="settting_Staus_wrapper">
                        <div className="settting_Staus success">
                            <Badge bg="success">Yes</Badge>
                            <div className="statusPercentafe">
                                <Form.Control 
                                    type="number"  
                                    className="inputBoxs"
                                    value={InvoiceGeared?.Invoice?.Yes}
                                    onChange={(e) => NumberValidHundredValue(e, "Invoice", "Yes")}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                />
                                <span>%</span>
                            </div>
                        </div>
                        <div className="settting_Staus settting_Staus_score success">
                            <Badge bg="success">Yes Score</Badge>
                            <div className="statusPercentafe">
                                <Form.Control 
                                    className="inputBoxs"
                                    value={InvoiceGeared?.Invoice?.YesScore}
                                    onChange={(e) => NumberValidHundredValue(e, "Invoice", "YesScore")}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                    min="0"
                                />
                            </div>
                        </div>
                        <div className="settting_Staus danger">
                            <Badge bg="danger">No</Badge>
                            <div className="statusPercentafe">
                                <Form.Control 
                                    type="number"  
                                    className="inputBoxs"
                                    value={InvoiceGeared?.Invoice?.No}
                                    onChange={(e) => NumberValidHundredValue(e, "Invoice", "No")}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                />
                                <span>%</span>
                            </div>
                        </div>
                        <div className="settting_Staus settting_Staus_score danger">
                            <Badge bg="danger">No Score</Badge>
                            <div className="statusPercentafe">
                                <Form.Control 
                                    className="inputBoxs"
                                    value={InvoiceGeared?.Invoice?.NoScore}
                                    onChange={(e) => NumberValidHundredValue(e, "Invoice", "NoScore")}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                    min="0"
                                />
                            </div>
                        </div>
                    </div>
                    {errorInvoice !== "" && <span className="error_medotory">{errorInvoice}</span>}
                </div>
                <div className="HeaderDivider" style={{ paddingTop: "40px" }}>
                    <h6 className="subtitleForm PurchaseOrderCheckbox">Geared</h6>
                    <div className="settting_Staus_wrapper">
                        <div className="settting_Staus success">
                            <Badge bg="success">Yes</Badge>
                            <div className="statusPercentafe">
                                <Form.Control 
                                    type="number"  
                                    className="inputBoxs"
                                    value={InvoiceGeared?.Geared?.Yes}
                                    onChange={(e) => NumberValidHundredValue(e, "Geared", "Yes")}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                />
                                <span>%</span>
                            </div>
                        </div>
                        <div className="settting_Staus settting_Staus_score success">
                            <Badge bg="success">Yes Score</Badge>
                            <div className="statusPercentafe">
                                <Form.Control 
                                    className="inputBoxs"
                                    value={InvoiceGeared?.Geared?.YesScore}
                                    onChange={(e) => NumberValidHundredValue(e, "Geared", "YesScore")}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                    min="0"
                                />
                            </div>
                        </div>
                        <div className="settting_Staus danger">
                            <Badge bg="danger">No</Badge>
                            <div className="statusPercentafe">
                                <Form.Control 
                                    type="number"  
                                    className="inputBoxs"
                                    value={InvoiceGeared?.Geared?.No}
                                    onChange={(e) => NumberValidHundredValue(e, "Geared", "No")}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                />
                                <span>%</span>
                            </div>
                        </div>
                        <div className="settting_Staus settting_Staus_score danger">
                            <Badge bg="danger">No Score</Badge>
                            <div className="statusPercentafe">
                                <Form.Control 
                                    className="inputBoxs"
                                    value={InvoiceGeared?.Geared?.NoScore}
                                    onChange={(e) => NumberValidHundredValue(e, "Geared", "NoScore")}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                    min="0"
                                />
                            </div>
                        </div>
                    </div>
                    {errorGeared !== "" && <span className="error_medotory">{errorGeared}</span>}
                </div>
                <div className="HeaderDivider" style={{ paddingTop: "40px" }}>
                    <h6 className="subtitleForm PurchaseOrderCheckbox">Suspension</h6>
                    <div className="settting_Staus_wrapper">
                        <div className="settting_Staus success">
                            <Badge bg="success">Yes</Badge>
                            <div className="statusPercentafe">
                                <Form.Control 
                                    type="number"  
                                    className="inputBoxs"
                                    value={InvoiceGeared?.Suspension?.Yes}
                                    onChange={(e) => NumberValidHundredValue(e, "Suspension", "Yes")}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                    placeholder="Enter Percentage"
                                />
                                <span>%</span>
                            </div>
                        </div>
                        <div className="settting_Staus settting_Staus_score success">
                            <Badge bg="success">Yes Score</Badge>
                            <div className="statusPercentafe">
                                <Form.Control 
                                    className="inputBoxs"
                                    value={InvoiceGeared?.Suspension?.YesScore}
                                    onChange={(e) => NumberValidHundredValue(e, "Suspension", "YesScore")}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                    min="0"
                                />
                            </div>
                        </div>
                        <div className="settting_Staus danger">
                            <Badge bg="danger">No</Badge>
                            <div className="statusPercentafe">
                                <Form.Control 
                                    type="number"  
                                    className="inputBoxs"
                                    value={InvoiceGeared?.Suspension?.No}
                                    onChange={(e) => NumberValidHundredValue(e, "Suspension", "No")}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                    placeholder="Enter Percentage"
                                />
                                <span>%</span>
                            </div>
                        </div>
                        <div className="settting_Staus settting_Staus_score danger">
                            <Badge bg="danger">No Score</Badge>
                            <div className="statusPercentafe">
                                <Form.Control 
                                    className="inputBoxs"
                                    value={InvoiceGeared?.Suspension?.NoScore}
                                    onChange={(e) => NumberValidHundredValue(e, "Suspension", "NoScore")}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                    min="0"
                                />
                            </div>
                        </div>
                    </div>
                    {errorSuspension !== "" && <span className="error_medotory">{errorSuspension}</span>}
                </div>
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ReSells[2]?.Edit) && (<Button className="save_percentage" disabled={disablebtn} onClick={() => save_percentage()}>Save</Button>)}
            </Scrollbars>
        </div>
    </section>)
}

export default Settings;