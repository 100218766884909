import { WHATSAPP_LIMECHAT_API_URL } from "../components/Shared/constant";

const GetWhatsappLmeAPI = async (accessToken, order_id) => {
    const responce = window.axios.get(`${WHATSAPP_LIMECHAT_API_URL}/${order_id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':`Bearer ${accessToken}`
          },
      }).then(function (result) {
          return {
            ...result.data,
            status:200
          };
      }).catch(function (result) {
          return {
            ...result?.response?.data,
            status:result?.response?.status
          }
      });
      return responce;
};
  
export default GetWhatsappLmeAPI;