/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { useNavigate,useLocation } from "react-router-dom";
import {useSelector} from "react-redux"
import wsSend_request from "../../../Api/ws/ws_request";
import { WebSocketContext } from "../../../App";
import { DEBIT_CREDIT_NOTES } from "../../Shared/constant";

const DebitCreditNotes = (props) => {
    const navigate = useNavigate();
    const { websocket } = useContext(WebSocketContext);
    const LocationRoute = useLocation()?.pathname;
    const { device_id, FranchiseUserTypeList, AllDealerFranchiseTypeListAll, selfInfo } = useSelector((state) => state.adminReducers);
    const [ApplicationState, setApplicationState] = useState({
        State: ""
    });
    const [franchise_id,selectFranchiseId] = useState(0)
    const [optionsState, SetOptionsState] = useState([]);
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    useEffect(()=>{
         SetOptionsState(AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.is_active === true && elm?.parent_id === null )?.map((item, index)=>({key:item.id, value:item?.id , label:`${item?.franchise_code} - ${item?.customer_name}` })))
    },[ AllDealerFranchiseTypeListAll ])
    
    useEffect(()=>{
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.PaymentManager[1]?.Edit || PermissionsAccess?.PaymentManager[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "franchise_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket,param)
        };
    },[])

    // Define the GetDetails function
    const GetDetails = () => {
        // navigate("/debit-credit-notes/"+franchise_id)
       navigate(`${DEBIT_CREDIT_NOTES}/${franchise_id}`)
    }
    
    const Back = () => {
        navigate("/debit-credit-notes")
    }

    const StateValueSelect = (e) => {
        const id = AllDealerFranchiseTypeListAll?.list?.filter((ele)=>ele?.is_active ===true && ele?.id === e?.value)?.map((item)=> item?.id)[0]
        selectFranchiseId(id)
        setApplicationState({ State: e });
    };

    return (
        <section className="Main_section">
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-4">
                        <Form.Group className="mb-3">
                            <Form.Label>Franchise Code<span className="mendatory_star">*</span></Form.Label>
                            <Form.Group className="mb-3">
                                <div className="selectMultiselect" id="selectMultiselectBrand">
                                    <Select
                                        value={ApplicationState?.State}
                                        onChange={(e) => StateValueSelect(e)}
                                        isClearable
                                        options={optionsState}
                                        className="godown-select-container"
                                        classNamePrefix="godown-select"
                                        styles={customStyles}
                                        placeholder="Select Franchise"
                                    />
                                </div>
                            </Form.Group>
                        </Form.Group>
                    </div>
                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.PaymentManager[1]?.Edit) && (<div className="col-md-1">
                        <Form.Group className="mb-3">
                            <Form.Label>&nbsp;</Form.Label>
                            <Button id="getDetailButton" className="getDetailButton" onClick={() => GetDetails()} disabled={!ApplicationState?.State}>
                                Get Details
                            </Button>
                        </Form.Group>
                    </div>)}
                </div>
            </div>
        </section>
    );
}

export default DebitCreditNotes;
