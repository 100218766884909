/* eslint-disable */
import { ActionTypes } from "../actions/adminActions";

const initialData = {
    is_login: "no",
    user_id: "",
    device_id: "",
    selfInfo: {},
    accessToken: "",
    MainNavUrl: "",
    ClearFormSet: {
        url: "",
        action: false
    },
    commonMainLoader: {
        url: "",
        loader: false
    },
    ImageStored: {},
    brandList: {
        list: [],
        pagination: {}
    },
    boxList: {
        list: [],
        pagination: {}
    },
    AllboxList: [],
    categoryList: {
        list: [],
        brands: [],
        pagination: {}
    },
    subcategoryList: {
        list: [],
        category: [],
        pagination: {}
    },
    frameNumberList: [],
    invoiceDetails: [],
    productList: {
        list: [],
        sub_category: [],
        pagination: {}
    },
    stateList: {
        list: [],
        pagination: {}
    },
    cityList: {
        list: [],
        state: [],
        pagination: {}
    },
    pincodeList: {
        list: [],
        district: [],
        pagination: {}
    },
    regionMasterList: {
        list: [],
        pagination: {}
    },
    userList: {
        list: [],
        region_list: [],
        pagination: {}
    },
    salesPersonPerformanceList: {
        list: [],
        pagination: {}
    },
    userlistAll: [],
    wareHouseList: {
        list: [],
        pin_code: [],
        pagination: {}
    },
    storedDetailsReportList: {
        list: [],
        pagination: {}
    },
    storedDetailsLogReportList: {
        list: [],
        pagination: {}
    },
    accessoriesReportList: {
        list: [],
        pagination: {}
    },
    storedLevelReportList: {
        list: [],
        grand: {},
        pagination: {}
    },
    storedLevelAllReportList: {
        list: [],
        warehouse: [],
        grand: {},
        pagination: {}
    },
    SoApprovalstoredLevelReportList: {
        list: [],
        warehouse: {},
        pagination: {}
    },
    FranchiseTypeList: {
        list: [],
        pagination: {}
    },
    FranchiseUserTypeList: {
        list: [],
        pin: [],
        district: [],
        state: [],
        region: [],
        user: [],
        asm_list: [],
        franchise_type: [],
        pagination: {}
    },
    OrderReportList: {
        list: [],
        pin: [],
        district: [],
        franchise: [],
        franchise_type: [],
        state: [],
        regionlist: [],
        uni_com_invoice_list: [],
        pagination: {}
    },
    AllDealerFranchiseTypeListAll: {
        list: [],
        pin: []
    },
    wareHouselistall: [],
    franchiseTypeListAll: [],
    brandlistall: [],
    citylistall: [],
    Regionlistall: [],
    categorylistall: [],
    productlistall: [],
    productCategorylistall: {
        list: [],
        sub_category: [],
        category: [],
    },
    subCategorylistall: [],
    statelistall: [],
    ListActiveStatus: false,
    magic_code: "",
    searchPincodeList: {
        pin: {},
        city: {},
        state: {},
    },
    searchCommonPincodeList: {
        pin: {},
        city: {},
        state: {},
    },
    ErrorMessageFromReddux: {
        API: "",
        Error: "",
    },
    ErrorFramesMessageFromReddux: {
        API: "",
        Error: "",
    },
    commonLoader: false,
    ProductGroupList: {
        list: [],
        pagination: {}
    },
    AllProductGroupListAll: [],
    OrderReportDetails: {},
    OrderDetailsByCode: {},
    OrderPOApprovalsList: {
        list: [],
        franchise: [],
        franchise_type: [],
        uni_com_invoice_list: [],
        regionlist: [],
        pagination: {}
    },
    OrderWareHouseApprovalsList: {
        list: [],
        franchise: [],
        franchise_type: [],
        regionlist: [],
        pagination: {}
    },
    PendingForAccountsList: {
        list: [],
        franchise: [],
        franchise_type: [],
        regionlist: [],
        erp_order_log_list: [],
        pin: [],
        district: [],
        state: [],
        pagination: {}
    },
    PendingForStoresList: {
        list: [],
        franchise: [],
        franchise_type: [],
        regionlist: [],
        uni_com_invoice_list: [],
        erp_invoice_log_list: [],
        pin: [],
        district: [],
        state: [],
        pagination: {}
    },
    DispatchLists: {
        list: [],
        warehouses: [],
        district: [],
        pin_code: [],
        state: [],
        pagination: {},
    },
    AllDispacchList: [],
    OrderReportOptions: {
        data: [],
        pagination: {},
        product: [],
        warehouse: []
    },
    CourierList: {
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    RelatedCourierList: {
        list: [],
        warehouses: [],
        pagination: {}
    },
    OptionCourierList: {
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    OptionCourierListNew: {
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    OptionCourierBeforeLoadListNew: {
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    PendingDispatchList: {
        franchise: [],
        franchise_type: [],
        data: [],
        regionlist: [],
        uni_com_invoice_list: [],
        pin: [],
        district: [],
        state: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    PendingDeliveryList: {
        franchise: [],
        franchise_type: [],
        data: [],
        regionlist: [],
        uni_com_invoice_list: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    DeliveredList: {
        franchise: [],
        franchise_type: [],
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    RejectedOrderList: {
        franchise: [],
        franchise_type: [],
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    CancelledOrderList: {
        franchise: [],
        franchise_type: [],
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    InvoiceList: {
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    EInvoiceSuccess: {
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    EInvoiceFailure: {
        data: [],
        pagination: {
            total_records: 1,
            record_limit: 10,
            current_page: 1,
        },
    },
    DebitCreditNoteList: {
        list: [],
        pagination: {}
    },
    ReasonTypeList: [],
    ReasonList: [],
    PaymentList: [],
    ReturnReplaceOrderList: {
        list: [],
        regionlist: [],
        pagination: {}
    },
    ReturnReplaceOrderSingleList: [],
    ModalInvoice: null,
    OptionList: {},
    DebitCreditNoteReportList: {
        list: [],
        franchise: [],
        user: [],
        grand_credit: "",
        grand_debit: "",
        pagination: {}
    },
    ErpLedgerReportList: {
        list: [],
        franchise: [],
        user: [],
        grand_credit: "",
        grand_debit: "",
        pagination: {}
    },
    ErpGeneralLedgerReportList: {
        list: [],
        franchise: [],
        user: [],
        grand_credit: "",
        grand_debit: "",
        grand_outstatnding: "",
        pagination: {}
    },
    erpAccountList: {
        list: [],
        franchise: [],
        pagination: {},
    },
    PaymentApprovalList: {
        list: [],
        franchise: [],
        user: [],
        grand_credit: "",
        grand_debit: "",
        pagination: {}
    },
    DNCNLedgerList: {
        list: [],
        franchise: [],
        pagination: {}
    },
    gstReportList: {
        list: [],
        invoice_list: [],
        dispatch_List: [],
        district: [],
        order_list: [],
        pin_code: [],
        state: [],
        warehouse_list: [],
        pagination: {},
        fo_billing_list: [],
        ao_billing_list: [],
        co_billing_list: [],
        region_list: [],
    },
    sortingfor: "",
    PurchaseList: {
        list: [],
        pin_code: [],
        state: [],
        district: [],
        pagination: {},
    },
    FrameList: {},
    AllOrderLogList: {},
    franchiseBrandListAll: {
        list: []
    },
    InwardLogList: {
        order_logs: [],
        product: [],
        user_list: [],
        warehouse: [],
        pagination: {},
    },
    WarehousePincodeListAll: {
        list: [],
        pin_code: [],
    },
    OrderManagmentFilter: {
        AllOrdersReport: {
            UserSearch: "",
            SelectDateType: "created_at",
            is_bundle_orders: false,
            FromDate: "",
            ToDate: "",
            SelectOrderType: "OPEN,SO_HALF_APPROVAL,SO_APPROVED,WH_PENDING,WH_APPROVED,AC_APPROVED,ST_APPROVED,HALF_DISPATCH,DISPATCHED,DELIVERED,CANCELLED,REJECTED,RETURN,REPLACE,RETURN_REQUEST,CANCELLED_REQUEST,RETURN_IN_TRANSIT,REPLACE_REQUEST",
            SelectRegion: "",
            OrderSource: "",
            WarehouseName: "",
            UserLimit: 10,
            CurrentPage: 1,
        },
        SOApprovals: {
            UserSearch: "",
            SelectDateType: "created_at",
            is_bundle_orders: false,
            FromDate: "",
            ToDate: "",
            SelectRegion: "",
            OrderSource: "",
            WarehouseName: "",
            UserLimit: 10,
            CurrentPage: 1,
        },
        WarehouseApprovals: {
            UserSearch: "",
            SelectDateType: "created_at",
            is_bundle_orders: false,
            FromDate: "",
            ToDate: "",
            SelectRegion: "",
            OrderSource: "",
            WarehouseName: "",
            UserLimit: 10,
            CurrentPage: 1,
        },
        PendingForAccounts: {
            UserSearch: "",
            SelectDateType: "created_at",
            is_bundle_orders: false,
            FromDate: "",
            ToDate: "",
            SelectRegion: "",
            OrderSource: "",
            WarehouseName: "",
            UserLimit: 10,
            CurrentPage: 1,
        },
        PendingForStores: {
            UserSearch: "",
            SelectDateType: "created_at",
            is_bundle_orders: false,
            FromDate: "",
            ToDate: "",
            SelectRegion: "",
            OrderSource: "",
            WarehouseName: "",
            UserLimit: 10,
            CurrentPage: 1,
            is_odi_courier_pin: {label:"All Orders", flag: "all", color: "#fff"},
        },
        PendingForDispatch: {
            UserSearch: "",
            SelectDateType: "created_at",
            is_bundle_orders: false,
            FromDate: "",
            ToDate: "",
            SelectRegion: "",
            OrderSource: "",
            WarehouseName: "",
            UserLimit: 10,
            CurrentPage: 1,
            is_odi_courier_pin: {label:"All Orders", flag: "all", color: "#fff"},
        },
        PendingForDelivery: {
            UserSearch: "",
            SelectDateType: "created_at",
            is_bundle_orders: false,
            FromDate: "",
            ToDate: "",
            SelectRegion: "",
            OrderSource: "",
            WarehouseName: "",
            UserLimit: 10,
            CurrentPage: 1,
            is_odi_courier_pin: {label:"All Orders", flag: "all", color: "#fff"},
        },
        OrderDelivered: {
            UserSearch: "",
            SelectDateType: "created_at",
            is_bundle_orders: false,
            FromDate: "",
            ToDate: "",
            SelectRegion: "",
            OrderSource: "",
            WarehouseName: "",
            UserLimit: 10,
            CurrentPage: 1,
            is_odi_courier_pin: {label:"All Orders", flag: "all", color: "#fff"},
        },
    },
    franchiseOrderLatestThreeListl: [],
    PointValueList: {
        list: [],
        pagination: {}
    },
    RefurbishedSettings: {},
    pointListNaviGation: "Point_list",
    ResellcycleReportsList: {
        list: [],
        refurbished_brand: [],
        pagination: {}
    },
    ResellcycleCustomerReportsList: {
        list: [],
        refurbished_brand: [],
        district: [],
        pin_code: [],
        state: [],
        pagination: {}
    },
    PointSettingsList: {
        list: [],
        pagination: {}
    },
    AgeValueList: {
        list: [],
        pagination: {}
    },
    ResellBrandList: {
        list: [],
        pagination: {}
    },
    ErpApiFailureList: {
        list: [],
        erp_order_log_list: [],
        pagination: {}
    },
    ErpApiInvoiceFailureList: {
        list: [],
        erp_order_log_list: [],
        pagination: {}
    },
    ErpApiDeleiveryNoteFrameNumberFailureList: {
        list: [],
        erp_order_log_list: [],
        pagination: {}
    },
    ErpAPIPurchaseTrueList: {
        list: [],
        erp_order_log_list: [],
        pagination: {}
    },
    ErpAPIPurchaseFalseList: {
        list: [],
        erp_order_log_list: [],
        pagination: {}
    },
    TbaOrderList: {
        list: [],
        pagination: {},
    },
    IncentiveSlabList: {
        list: [],
        pagination: {},
    },
    SearchModulesObj: "",
    orderCancelSuccess: {
        open: false,
        title: "",
        message: "",
    },
    ClearCache__: "ClearCache"
};

export const adminReducers = (state = initialData, action) => {
    switch (action.type) {
        case ActionTypes.IS_LOGIN:
            return {
                ...state,
                is_login: action.payload,
            };
        case ActionTypes.SET_STORE_COURIERS_LIST:
            return {
                ...state,
                CourierList: action.payload,
            };
        case ActionTypes.SET_RELATED_COURIERS_LIST:
            return {
                ...state,
                RelatedCourierList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.related_couriers,
                    warehouses: Object.keys(action.payload).length === 0 ? [] : action.payload.warehouses,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            }
        case ActionTypes.SET_STORE_INVOICE_LIST:
            return {
                ...state,
                InvoiceList: action.payload,
            };
        case ActionTypes.SET_STORE_EINVOICE_SUCCESS_LIST:
            return {
                ...state,
                EInvoiceSuccess: action.payload,
            };
        case ActionTypes.SET_STORE_EINVOICE_FAILURE_LIST:
            return {
                ...state,
                EInvoiceFailure: action.payload,
            };
        case ActionTypes.SET_STORE_CANCELLED_ORDER_LIST:
            return {
                ...state,
                CancelledOrderList: action.payload,
            };
        case ActionTypes.SET_STORE_OPTION_COURIERS_LIST:
            return {
                ...state,
                OptionCourierList: action.payload,
            };
        case ActionTypes.SET_STORE_OPTION_COURIERS_NEW_LIST:
            return {
                ...state,
                OptionCourierListNew: action.payload,
            };
        case ActionTypes.SET_STORE_BEFORE_LOADMORE_OPTION_COURIERS_NEW_LIST:
            return {
                ...state,
                OptionCourierBeforeLoadListNew: action.payload,
            };
        case ActionTypes.GET_USER_ID:
            return {
                ...state,
                user_id: action.payload,
            };
        case ActionTypes.GET_DEVICE_ID:
            return {
                ...state,
                device_id: action.payload,
            };
        case ActionTypes.SET_MAGIC_CODE:
            return {
                ...state,
                magic_code: action?.payload
            };
        case ActionTypes.SELF_DETAILS:
            return {
                ...state,
                selfInfo: action.payload,
            };
        case ActionTypes.GET_ACCESSTOKEN:
            return {
                ...state,
                accessToken: action.payload,
            };
        case ActionTypes.MAIN_NAVIGATION:
            return {
                ...state,
                MainNavUrl: action.payload,
            };
        case ActionTypes.CLEAR_FORM_SET_FUCT:
            return {
                ...state,
                ClearFormSet: {
                    url: action?.payload?.url,
                    action: action?.payload?.action
                }
            };
        case ActionTypes.SET_STORE_OPTIONS_LIST:
            const oldOption = state?.OptionList || {};
            oldOption[action?.payload?.id] = action.payload.data;
            return {
                ...state,
                OptionList: oldOption
            };
        case ActionTypes.SET_STORE_OPTIONS_LIST_CLEAR:
            return {
                ...state,
                OptionList: {},
            };
        case ActionTypes.GET_BRAND_LIST:
            return {
                ...state,
                brandList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_NEW_BRAND_LIST:
            const OldBrandlist = [...state.brandList.list];
            OldBrandlist.unshift(action.payload);
            return {
                ...state,
                brandList: {
                    ...state.brandList,
                    list: OldBrandlist,
                },
            }
        case ActionTypes.EDIT_BRAND_LIST:
            const OldBrandlistx = [...state.brandList.list];
            const UpdateBrandList = OldBrandlistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        brand_image: action.payload.brand_image,
                        brand_name: action.payload.brand_name,
                        is_active: action.payload.is_active,
                    };
                } else
                    return elm;
            });
            return {
                ...state,
                brandList: {
                    ...state.brandList,
                    list: UpdateBrandList,
                }
            };
        case ActionTypes.IMAGE_STORED_REDUX:
            return {
                ...state,
                ImageStored: {
                    ...state?.ImageStored,
                    [action?.payload?.fileresponse?.id]: action?.payload?.Authfileresponse?.authImage
                },
            };
        case ActionTypes.GET_RETURN_REPLACE_ORDER_LIST:
            return {
                ...state,
                ReturnReplaceOrderList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    franchise: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise,
                    franchise_type: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise_type,
                    regionlist: Object.keys(action.payload).length === 0 ? [] : action.payload.region_list,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.GET_RETURN_REPLACE_SINGLE_ORDER:
            return {
                ...state,
                ReturnReplaceOrderSingleList: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
            };
        case ActionTypes.GET_RETURN_REPLACE_INVOICE_ORDER_DETAILS:
            return {
                ...state,
                invoiceDetails: action.payload,
            };
        case ActionTypes.GET_RETURN_REPLACE_FRAMES_ORDER_DETAILS:
            return {
                ...state,
                frameNumberList: action.payload,
            };
        case ActionTypes.GET_CATEGORY_LIST:
            return {
                ...state,
                categoryList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    brands: Object.keys(action.payload).length === 0 ? [] : action.payload.brands,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_CATEGORY_LIST:
            const OldCategorylist = [...state.categoryList.list];
            OldCategorylist.unshift(action.payload);
            return {
                ...state,
                categoryList: {
                    ...state.categoryList,
                    list: OldCategorylist,
                },
            };
        case ActionTypes.UPDATE_CATEGORY_LIST:
            const OldCategorylistx = [...state.categoryList.list];
            const UpdateCategoryList = OldCategorylistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        category_name: action.payload.category_name,
                        category_image: action.payload.category_image,
                        brand_id: action.payload.brand_id,
                        is_active: action.payload.is_active
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                categoryList: {
                    ...state.categoryList,
                    list: UpdateCategoryList,
                },
            };
        case ActionTypes.GET_ALL_BRANDS_LIST:
            return {
                ...state,
                brandlistall: Object.keys(action.payload).length === 0 ? [] : action?.payload?.data?.data,
            };
        case ActionTypes.GET_ALL_CATEGORY_LIST:
            return {
                ...state,
                categorylistall: Object.keys(action.payload).length === 0 ? [] : action?.payload?.data?.data,
            };
        case ActionTypes.GET_ALL_SUB_CATEGORY_LIST:
            return {
                ...state,
                subCategorylistall: Object.keys(action.payload).length === 0 ? [] : action?.payload?.data?.data,
            };
        case ActionTypes.ACTIVE_LIST_ITEM_STATUS:
            return {
                ...state,
                ListActiveStatus: action.payload
            };
        case ActionTypes.GET_SUB_CATEGORY_LIST:
            return {
                ...state,
                subcategoryList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    category: Object.keys(action.payload).length === 0 ? [] : action.payload.category,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_SUB_CATEGORY_LIST:
            const OldSubCategorylist = [...state.subcategoryList.list];
            OldSubCategorylist.unshift(action.payload);
            return {
                ...state,
                subcategoryList: {
                    ...state.subcategoryList,
                    list: OldSubCategorylist,
                },
            };
        case ActionTypes.UPDATE_SUB_CATEGORY_LIST:
            const OldSubCategorylistx = [...state.subcategoryList.list];
            const UpdateSubCategoryList = OldSubCategorylistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        sub_category_name: action.payload.sub_category_name,
                        category_name: action.payload.category_name,
                        is_active: action.payload.is_active
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                subcategoryList: {
                    ...state.subcategoryList,
                    list: UpdateSubCategoryList,
                },
            };
        case ActionTypes.GET_PRODUCT_LIST:
            return {
                ...state,
                productList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    sub_category: Object.keys(action.payload).length === 0 ? [] : action.payload.sub_category,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_PRODUCT_LIST:
            const OldProductlist = [...state.productList.list];
            OldProductlist.unshift(action.payload);
            return {
                ...state,
                productList: {
                    ...state.productList,
                    list: OldProductlist,
                },
            };
        case ActionTypes.UPDATE_PRODUCT_LIST:
            const OldProductlistx = [...state.productList.list];
            const UpdateProductList = OldProductlistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        sku: action.payload.sku,
                        product_name: action.payload.product_name,
                        display_name: action.payload.display_name,
                        short_description: action.payload.short_description,
                        product_image: action.payload.product_image,
                        product_group_id: action.payload.product_group_id,
                        hsn_code: action.payload.hsn_code,
                        product_type_id: action.payload.product_type_id,
                        is_return: action.payload.is_return,
                        return_remark: action.payload.return_remark,
                        mrp: action.payload.mrp,
                        franchise_rate: action.payload.franchise_rate,
                        retail_price: action.payload.retail_price,
                        discount_amount: action.payload.discount_amount,
                        ecom_price: action.payload.ecom_price,
                        fs_code: action.payload.fs_code,
                        product_color: action.payload.product_color,
                        igst_rate: action.payload.igst_rate,
                        cgst_rate: action.payload.cgst_rate,
                        sgst_rate: action.payload.sgst_rate,
                        is_active: action.payload.is_active,
                        erp_id: action.payload.erp_id,
                        sub_category_id: action.payload.sub_category_id
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                productList: {
                    ...state.productList,
                    list: UpdateProductList,
                },
            };
        case ActionTypes.GET_STATE_LIST:
            return {
                ...state,
                stateList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_STATE_LIST:
            const OldStatelist = [...state.stateList.list];
            OldStatelist.unshift(action.payload);
            return {
                ...state,
                stateList: {
                    ...state.stateList,
                    list: OldStatelist,
                },
            };
        case ActionTypes.GET_ALL_STATE_LIST:
            return {
                ...state,
                statelistall: Object.keys(action.payload).length === 0 ? [] : action.payload,
            }
        case ActionTypes.UPDATE_STATE_LIST:
            const OldStatelistx = [...state.stateList.list];
            const UpdateStatelist = OldStatelistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        state_name: action.payload?.state_name,
                        state_code: action.payload?.state_code,
                        gst_code: action.payload?.gst_code,
                        region_id: action.payload?.region_id,
                        is_active: action.payload.is_active,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                stateList: {
                    ...state.stateList,
                    list: UpdateStatelist,
                },
            };

        case ActionTypes.GET_CITY_LIST:
            return {
                ...state,
                cityList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    state: Object.keys(action.payload).length === 0 ? [] : action.payload.state,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.GET_PIN_CODE_LIST:
            return {
                ...state,
                pincodeList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    district: Object.keys(action.payload).length === 0 ? [] : action.payload.district,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.GET_ALL_PRODUCT_LIST:
            return {
                ...state,
                productlistall: Object.keys(action.payload).length === 0 ? [] : action.payload,
            };
        case ActionTypes.GET_ALL_PRODUCT_CATEGORY_LIST:
            return {
                ...state,
                productCategorylistall: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    sub_category: Object.keys(action.payload).length === 0 ? [] : action.payload.sub_category,
                    category: Object.keys(action.payload).length === 0 ? [] : action.payload.category,
                },
            };
        case ActionTypes.GET_ALL_CITY_LIST:
            return {
                ...state,
                citylistall: Object.keys(action.payload).length === 0 ? [] : action.payload,
            };
        case ActionTypes.ADD_CITY_LIST:
            const OldCitylist = [...state.cityList.list];
            OldCitylist.unshift(action.payload);
            return {
                ...state,
                cityList: {
                    ...state.cityList,
                    list: OldCitylist,
                },
            };
        case ActionTypes.UPDATE_CITY_LIST:
            const OldCitylistx = [...state.cityList.list];
            const UpdateCityList = OldCitylistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        district_name: action.payload?.district_name,
                        state_id: action.payload?.state_id,
                        is_active: action.payload.is_active,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                cityList: {
                    ...state.cityList,
                    list: UpdateCityList,
                },
            };

        case ActionTypes.GET_REGION_MASTER_LIST:
            return {
                ...state,
                regionMasterList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_REGION_MASTER_LIST:
            const OldRegionMasterlist = [...state.regionMasterList.list];
            OldRegionMasterlist.unshift(action.payload);
            return {
                ...state,
                regionMasterList: {
                    ...state.regionMasterList,
                    list: OldRegionMasterlist,
                },
            };
        case ActionTypes.UPDATE_REGION_MASTER_LIST:
            const OldRegionMasterlistx = [...state.regionMasterList.list];
            const UpdateRegionMasterlist = OldRegionMasterlistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        region_name: action.payload.region_name,
                        is_active: action.payload.is_active,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                regionMasterList: {
                    ...state.regionMasterList,
                    list: UpdateRegionMasterlist,
                },
            };
        case ActionTypes.GET_USER_LIST:
            return {
                ...state,
                userList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    region_list: Object.keys(action.payload).length === 0 ? [] : action.payload.region_list,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_USER_LIST:
            const OldUserlist = [...state.userList.list];
            OldUserlist.unshift(action.payload);
            return {
                ...state,
                userList: {
                    ...state.userList,
                    list: OldUserlist,
                },
            };
        case ActionTypes.UPDATE_USER_LIST:
            const OldUserlistx = [...state.userList.list];
            const UpdateUserlist = OldUserlistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        full_name: action.payload?.full_name,
                        email: action.payload?.email,
                        mobile: action.payload?.mobile,
                        address: action.payload?.address,
                        user_type: action.payload?.user_type,
                        permissions: action.payload?.permissions,
                        permissions_access: action.payload?.permissions_access,
                        related_id: action.payload?.related_id,
                        monthly_target: action.payload?.monthly_target,
                        is_active: action.payload.is_active,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                userList: {
                    ...state.userList,
                    list: UpdateUserlist,
                },
            };
        case ActionTypes.GET_ALL_USER_LIST:
            return {
                ...state,
                userlistAll: Object.keys(action.payload).length === 0 ? [] : action?.payload,
            };
        case ActionTypes.GET_SALES_PERSON_PERFORMANCE_LIST:
            return {
                ...state,
                salesPersonPerformanceList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.GET_ALL_REGION_LIST:
            return {
                ...state,
                Regionlistall: Object.keys(action.payload).length === 0 ? [] : action?.payload,
            };
        case ActionTypes.GET_WAREHOUSE_LIST:
            return {
                ...state,
                wareHouseList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pin_code: Object.keys(action.payload).length === 0 ? [] : action.payload.pin_code,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_WAREHOUSE_LIST:
            const OldWareHouselist = [...state.wareHouseList.list];
            OldWareHouselist.unshift(action.payload);
            return {
                ...state,
                wareHouseList: {
                    ...state.wareHouseList,
                    list: OldWareHouselist,
                },
            };
        case ActionTypes.UPDATE_WAREHOUSE_LIST:
            const OldwareHouselistx = [...state.wareHouseList.list];
            const UpdateWarehouselist = OldwareHouselistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        warehouse_name: action.payload?.warehouse_name,
                        display_name: action.payload?.display_name,
                        pin_code_id: action.payload?.pin_code_id,
                        address: action.payload?.address,
                        poc_name: action.payload?.poc_name,
                        poc_number: action.payload?.poc_number,
                        pan_no: action.payload?.pan_no,
                        pan_image: action.payload?.pan_image,
                        gstin_no: action.payload?.gstin_no,
                        gstin_image: action.payload?.gstin_image,
                        cin_no: action.payload?.cin_no,
                        region_id: action.payload?.region_id,
                        warehouse_code: action.payload?.warehouse_code,
                        warehouse_type: action.payload?.warehouse_type,
                        erp_id: action.payload?.erp_id,
                        erp_address_id: action.payload?.erp_address_id,
                        is_active: action.payload.is_active,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                wareHouseList: {
                    ...state.wareHouseList,
                    list: UpdateWarehouselist,
                },
            };
        case ActionTypes.GET_ALL_WAREHOUSE_LIST:
            return {
                ...state,
                wareHouselistall: Object.keys(action.payload).length === 0 ? [] : action.payload,
            };
        case ActionTypes.PINCODES_SEARCH_LIST:
            return {
                ...state,
                searchPincodeList: {
                    pin: Object.keys(action.payload).length === 0 ? [] : action.payload.pin,
                    city: Object.keys(action.payload).length === 0 ? [] : action.payload.district,
                    state: Object.keys(action.payload).length === 0 ? [] : action.payload.state,
                },
            };
        case ActionTypes.COMMON_PINCODES_SEARCH_LIST:
            return {
                ...state,
                searchCommonPincodeList: {
                    pin: Object.keys(action.payload).length === 0 ? [] : action.payload.pin,
                    city: Object.keys(action.payload).length === 0 ? [] : action.payload.district,
                    state: Object.keys(action.payload).length === 0 ? [] : action.payload.state,
                },
            };
        case ActionTypes.COMMON_LOADER:
            return {
                ...state,
                commonLoader: action.payload,
            }
        case ActionTypes.COMMON_MAIN_LOADER:
            return {
                ...state,
                commonMainLoader: {
                    url: action.payload.url,
                    loader: action.payload.loader,
                }
            }
        case ActionTypes.ERROR_MESSAGE_FROM_REDUX:
            return {
                ...state,
                ErrorMessageFromReddux: {
                    API: action.payload.API,
                    Error: action.payload.Error,
                },
            };
        case ActionTypes.ERROR_FRAMES_MESSAGE_FROM_REDUX:
            return {
                ...state,
                ErrorFramesMessageFromReddux: {
                    API: action.payload.API,
                    Error: action.payload.Error,
                },
            };
        case ActionTypes.STORED_DETAIL_REPORT_LIST:
            return {
                ...state,
                storedDetailsReportList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.STORED_DETAIL_LOG_REPORT_LIST:
            return {
                ...state,
                storedDetailsLogReportList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.SET_STORE_ACCESSORIES_REPORT:
            return {
                ...state,
                accessoriesReportList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.SET_STORE_PENDING_DISPATCH_LIST:
            return {
                ...state,
                PendingDispatchList: action.payload
            };
        case ActionTypes.SET_STORE_PENDING_DELIVERY_LIST:
            return {
                ...state,
                PendingDeliveryList: action.payload
            };
        case ActionTypes.SET_STORE_DELIVERY_LIST:
            return {
                ...state,
                DeliveredList: action.payload
            };
        case ActionTypes.SET_STORE_REJECTED_ORDER_LIST:
            return {
                ...state,
                RejectedOrderList: action.payload
            };
        case ActionTypes.STORED_LEVEL_REPORT_LIST:
            return {
                ...state,
                storedLevelReportList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    grand: Object.keys(action.payload).length === 0 ? [] : action.payload.grand,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.STORED_LEVEL_ALL_REPORT_LIST:
            return {
                ...state,
                storedLevelAllReportList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    warehouse: Object.keys(action.payload).length === 0 ? [] : action.payload.warehouse,
                    grand: Object.keys(action.payload).length === 0 ? [] : action.payload.grand,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.SO_APPROVAL_STORED_LEVEL_REPORT_LIST:
            return {
                ...state,
                SoApprovalstoredLevelReportList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    warehouse: Object.keys(action.payload).length === 0 ? [] : action.payload.warehouse,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.FRANCHISE_TYPE_LIST:
            return {
                ...state,
                FranchiseTypeList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_FRANCHISE_TYPE_LIST:
            const OldFranchiseTypelist = [...state.FranchiseTypeList.list];
            OldFranchiseTypelist.unshift(action.payload);
            return {
                ...state,
                FranchiseTypeList: {
                    ...state.FranchiseTypeList,
                    list: OldFranchiseTypelist,
                },
            };
        case ActionTypes.UPDATE_FRANCHISE_TYPE_LIST:
            const OldFranchiseTypelistx = [...state.FranchiseTypeList.list];
            const UpdateOldFranchiseTypelist = OldFranchiseTypelistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        franchise_type_name: action.payload?.franchise_type_name,
                        franchise_type_credit: action.payload?.franchise_type_credit,
                        is_active: action.payload?.is_active,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                FranchiseTypeList: {
                    ...state.FranchiseTypeList,
                    list: UpdateOldFranchiseTypelist,
                },
            };
        case ActionTypes.GET_ALL_FRANCHISE_TYPE_LIST_LIST:
            return {
                ...state,
                franchiseTypeListAll: Object.keys(action.payload).length === 0 ? [] : action.payload,
            };
        case ActionTypes.GET_FRANCHISE_USER_TYPE_LIST:
            return {
                ...state,
                FranchiseUserTypeList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    pin: Object.keys(action.payload).length === 0 ? [] : action.payload.pin_code,
                    district: Object.keys(action.payload).length === 0 ? [] : action.payload.district,
                    state: Object.keys(action.payload).length === 0 ? [] : action.payload.state,
                    region: Object.keys(action.payload).length === 0 ? [] : action.payload.region,
                    user: Object.keys(action.payload).length === 0 ? [] : action.payload.user,
                    franchise_type: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise_type,
                    asm_list: Object.keys(action.payload).length === 0 ? [] : action.payload.asm_list,
                },
            };
        case ActionTypes.GET_ALL_DEALER_FRANCHISE_USER_TYPE_LIST:
            return {
                ...state,
                AllDealerFranchiseTypeListAll: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pin: Object.keys(action.payload).length === 0 ? [] : action.payload.pin_code
                },
            };
        case ActionTypes.ADD_FRANCHISE_USER_TYPE_LIST:
            const OldFranchiseUserTypelist = [...state.FranchiseUserTypeList.list];
            OldFranchiseUserTypelist.unshift(action.payload);
            return {
                ...state,
                FranchiseUserTypeList: {
                    ...state.FranchiseUserTypeList,
                    list: OldFranchiseUserTypelist,
                },
            };
        case ActionTypes.UPDATE_FRANCHISE_USER_TYPE_LIST:
            const OldFranchiseUserTypeListx = [...state.FranchiseUserTypeList.list];
            const UpdateFranchiseUserTypeListx = OldFranchiseUserTypeListx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        "franchise_type": action.payload?.franchise_type,
                        "parent_id": action.payload?.parent_id,
                        "franchise_type_id": action.payload?.franchise_type_id,
                        "customer_name": action.payload?.customer_name,
                        "poc_name": action.payload?.poc_name,
                        "mobile": action.payload?.mobile,
                        "secondary_contact": action.payload?.secondary_contact,
                        "email": action.payload?.email,
                        "pin_code_id": action.payload?.pin_code_id,
                        "address": action.payload?.address,
                        "gst_no": action.payload?.gst_no,
                        "gst_image": action.payload?.gst_image,
                        "credit_limit": action.payload?.credit_limit,
                        "advance_amount": action.payload?.advance_amount,
                        "is_flexible_pricing": action.payload?.is_flexible_pricing,
                        "is_active": action.payload?.is_active,
                        "location_url": action.payload?.location_url,
                        "erp_id": action.payload?.erp_id,
                        "erp_address_id": action.payload?.erp_address_id,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                FranchiseUserTypeList: {
                    ...state.FranchiseUserTypeList,
                    list: UpdateFranchiseUserTypeListx,
                },
            };
        case ActionTypes.GET_PRODUCT_GROUP_LIST:
            return {
                ...state,
                ProductGroupList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_PRODUCT_GROUP_LIST:
            const OldProductGrouplist = [...state.ProductGroupList.list];
            OldProductGrouplist.unshift(action.payload);
            return {
                ...state,
                ProductGroupList: {
                    ...state.ProductGroupList,
                    list: OldProductGrouplist,
                },
            };
        case ActionTypes.UPDATE_PRODUCT_GROUP_LIST:
            const OldProductGrouplistX = [...state.ProductGroupList.list];
            const UpdateOldProductGroupList = OldProductGrouplistX.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        product_group_name: action.payload?.product_group_name,
                        is_active: action.payload?.is_active,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                ProductGroupList: {
                    ...state.ProductGroupList,
                    list: UpdateOldProductGroupList,
                },
            };
        case ActionTypes.GET_ALL_PRODUCT_GROUP_LIST:
            return {
                ...state,
                AllProductGroupListAll: Object.keys(action.payload).length === 0 ? [] : action.payload,
            };
        case ActionTypes.FRANCHISE_ORDER_REPORT_LIST:
            return {
                ...state,
                OrderReportList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    franchise: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise,
                    franchise_type: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise_type,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    pin: Object.keys(action.payload).length === 0 ? [] : action.payload.pin_code,
                    district: Object.keys(action.payload).length === 0 ? [] : action.payload.district,
                    regionlist: Object.keys(action.payload).length === 0 ? [] : action.payload.region_list,
                    uni_com_invoice_list: Object.keys(action.payload).length === 0 ? [] : action.payload.uni_com_invoice_list,
                    state: Object.keys(action.payload).length === 0 ? [] : action.payload.state,
                }
            }
        case ActionTypes.ORDER_PO_APPROVALS_ORDERS:
            return {
                ...state,
                OrderPOApprovalsList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    franchise: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise,
                    franchise_type: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise_type,
                    regionlist: Object.keys(action.payload).length === 0 ? [] : action.payload.region_list,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            }
        case ActionTypes.ORDER_WAREHOUSE_APPROVALS_ORDERS:
            return {
                ...state,
                OrderWareHouseApprovalsList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    franchise: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise,
                    franchise_type: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise_type,
                    regionlist: Object.keys(action.payload).length === 0 ? [] : action.payload.region_list,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            }
        case ActionTypes.PENDING_FOR_ACCOUNT_ORDERS_LIST:
            return {
                ...state,
                PendingForAccountsList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    franchise: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise,
                    franchise_type: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise_type,
                    regionlist: Object.keys(action.payload).length === 0 ? [] : action.payload.region_list,
                    erp_order_log_list: Object.keys(action.payload).length === 0 ? [] : action.payload.erp_order_log_list,
                    pin: Object.keys(action.payload).length === 0 ? [] : action.payload.pin_code,
                    district: Object.keys(action.payload).length === 0 ? [] : action.payload.district,
                    state: Object.keys(action.payload).length === 0 ? [] : action.payload.state,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            }
        case ActionTypes.PENDING_FOR_STORES_ORDERS_LIST:
            return {
                ...state,
                PendingForStoresList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    franchise: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise,
                    franchise_type: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise_type,
                    regionlist: Object.keys(action.payload).length === 0 ? [] : action.payload.region_list,
                    uni_com_invoice_list: Object.keys(action.payload).length === 0 ? [] : action.payload.uni_com_invoice_list,
                    erp_invoice_log_list: Object.keys(action.payload).length === 0 ? [] : action.payload.erp_invoice_log_list,
                    pin: Object.keys(action.payload).length === 0 ? [] : action.payload.pin_code,
                    district: Object.keys(action.payload).length === 0 ? [] : action.payload.district,
                    state: Object.keys(action.payload).length === 0 ? [] : action.payload.state,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            }
        case ActionTypes.DISPATCH_LIST:
            return {
                ...state,
                DispatchLists: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    warehouses: Object.keys(action.payload).length === 0 ? [] : action.payload.warehouses,
                    district: Object.keys(action.payload).length === 0 ? [] : action.payload.district,
                    pin_code: Object.keys(action.payload).length === 0 ? [] : action.payload.pin_code,
                    state: Object.keys(action.payload).length === 0 ? [] : action.payload.state,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            }
        case ActionTypes.ADD_DISPATCH_LIST:
            const OldDispatchlist = [...state.DispatchLists.list];
            OldDispatchlist.unshift(action.payload);
            return {
                ...state,
                DispatchLists: {
                    ...state.DispatchLists,
                    list: OldDispatchlist,
                },
            };
        case ActionTypes.UPDATE_DISPATCH_LIST:
            const OldDispatchlistX = [...state.DispatchLists.list];
            const UpdateOldOldDispatchlistX = OldDispatchlistX.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        dispatch_name: action.payload?.dispatch_name,
                        mobile: action.payload?.mobile,
                        email: action.payload?.email,
                        pin_code_id: action.payload?.pin_code_id,
                        gst_no: action.payload?.gst_no,
                        address: action.payload?.address,
                        warehouse_id: action.payload?.warehouse_id,
                        is_active: action.payload?.is_active,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                DispatchLists: {
                    ...state.DispatchLists,
                    list: UpdateOldOldDispatchlistX,
                },
            };
        case ActionTypes.GET_ALL_DISPATCH_LIST:
            return {
                ...state,
                AllDispacchList: action?.payload,
            };
        case ActionTypes.ORDER_DETAILS_REPORT:
            return {
                ...state,
                OrderReportDetails: action?.payload,
            }
        case ActionTypes.ORDER_DETAILS_BY_ORDER_CODE:
            return {
                ...state,
                OrderDetailsByCode: action?.payload,
            }
        case ActionTypes.SET_STORE_ACCESSORIES_LIST:
            return {
                ...state,
                OrderReportOptions: action?.payload
            }
        case ActionTypes.SET_STORE_MODAL_INVOICE_DETAILS:
            return {
                ...state,
                ModalInvoice: action?.payload
            }
        case ActionTypes.GET_STOCK_INWARD_LIST:
            return {
                ...state,
                StockInwardList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            }
        case ActionTypes.GET_BOX_LIST:
            return {
                ...state,
                boxList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.ADD_BOX_LIST:
            const OldBoxlist = [...state.boxList.list];
            OldBoxlist.unshift(action.payload);
            return {
                ...state,
                boxList: {
                    ...state.boxList,
                    list: OldBoxlist,
                },
            };
        case ActionTypes.UPDATE_BOX_LIST:
            const OldBoxlistx = [...state.boxList.list];
            const UpdateBoxlist = OldBoxlistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        box_name: action.payload?.box_name,
                        box_height: action.payload?.box_height,
                        box_width: action.payload?.box_width,
                        box_length: action.payload?.box_length,
                        is_active: action.payload.is_active,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                boxList: {
                    ...state.boxList,
                    list: UpdateBoxlist,
                },
            };
        case ActionTypes.GET_ALL_BOX_LIST:
            return {
                ...state,
                AllboxList: Object.keys(action.payload).length === 0 ? [] : action.payload,
            };
        case ActionTypes.GET_ALL_REASON_TYPE_LIST:
            return {
                ...state,
                ReasonTypeList: Object.keys(action.payload).length === 0 ? [] : action.payload,
            };
        case ActionTypes.GET_ALL_REASON_LIST:
            return {
                ...state,
                ReasonList: Object.keys(action.payload).length === 0 ? [] : action.payload,
            };
        case ActionTypes.GET_DEBIT_CREDIT_NOTE_REPORT_LIST:
            return {
                ...state,
                DebitCreditNoteReportList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    franchise: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise,
                    user: Object.keys(action.payload).length === 0 ? [] : action.payload.user,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    grand_credit: Object.keys(action.payload).length === 0 ? [] : action.payload.grand_credit,
                    grand_debit: Object.keys(action.payload).length === 0 ? [] : action.payload.grand_debit,
                }
            };
        case ActionTypes.GET_PAYMENT_APPROVAL_LIST_LIST:
            return {
                ...state,
                PaymentApprovalList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    franchise: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise,
                    user: Object.keys(action.payload).length === 0 ? [] : action.payload.user,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    grand_credit: Object.keys(action.payload).length === 0 ? [] : action.payload.grand_credit,
                    grand_debit: Object.keys(action.payload).length === 0 ? [] : action.payload.grand_debit,
                }
            };
        case ActionTypes.GET_INWARD_LOG_LIST:
            return {
                ...state,
                InwardLogList: {
                    order_logs: (Object.keys(action.payload).length === 0) ? [] : action.payload.order_logs,
                    product: Object.keys(action.payload).length === 0 ? [] : action.payload.product,
                    user_list: Object.keys(action.payload).length === 0 ? [] : action.payload.user_list,
                    warehouse: Object.keys(action.payload).length === 0 ? [] : action.payload.warehouse,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                },
            };
        case ActionTypes.GET_DNCN_LEDGER_LIST:
            return {
                ...state,
                DNCNLedgerList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    franchise: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                }
            };
        case ActionTypes.ADD_DEBIT_CREDIT_NOTE:
            const OldDebitCreditNotelist = [...state.DebitCreditNoteList.list];
            OldDebitCreditNotelist.unshift(action.payload);
            return {
                ...state,
                DebitCreditNoteList: {
                    ...state.DebitCreditNoteList,
                    list: OldDebitCreditNotelist,
                },
            };
        case ActionTypes.ADD_PAYMENT_LIST:
            const OldPaymentlist = state.PaymentApprovalList?.list?.length > 0 ? [...state.PaymentApprovalList?.list] : [];
            OldPaymentlist.unshift(action.payload);
            return {
                ...state,
                PaymentApprovalList: {
                    ...state.PaymentApprovalList,
                    list: OldPaymentlist,
                },
            };
        case ActionTypes.GET_GST_REPORT_LIST:
            return {
                ...state,
                gstReportList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.order_item_list,
                    invoice_list: Object.keys(action.payload).length === 0 ? [] : action.payload.invoice_list,
                    dispatch_List: Object.keys(action.payload).length === 0 ? [] : action.payload.dispatch_list,
                    district: Object.keys(action.payload).length === 0 ? [] : action.payload.district,
                    order_list: Object.keys(action.payload).length === 0 ? [] : action.payload.order_list,
                    pin_code: Object.keys(action.payload).length === 0 ? [] : action.payload.pin_code,
                    state: Object.keys(action.payload).length === 0 ? [] : action.payload.state,
                    warehouse_list: Object.keys(action.payload).length === 0 ? [] : action.payload.warehouse_list,
                    fo_billing_list: Object.keys(action.payload).length === 0 ? [] : action.payload.fo_billing_list,
                    ao_billing_list: Object.keys(action.payload).length === 0 ? [] : action.payload.ao_billing_list,
                    co_billing_list: Object.keys(action.payload).length === 0 ? [] : action.payload.co_billing_list,
                    wo_billing_list: Object.keys(action.payload).length === 0 ? [] : action.payload.wo_billing_list,
                    region_list: Object.keys(action.payload).length === 0 ? [] : action.payload.region_list,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                }
            };
        case ActionTypes.PURCHASE_LIST:
            return {
                ...state,
                PurchaseList: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pin_code: Object.keys(action.payload).length === 0 ? [] : action.payload.pin_code,
                    state: Object.keys(action.payload).length === 0 ? [] : action.payload.state,
                    district: Object.keys(action.payload).length === 0 ? [] : action.payload.district,
                    pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                }
            };
        case ActionTypes.GET_WAREHOUSE_WITH_PINCODE_LIST:
            return {
                ...state,
                WarehousePincodeListAll: {
                    list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                    pin_code: Object.keys(action.payload).length === 0 ? [] : action.payload.pin_code,
                },
            };
        case ActionTypes.UPDATE_PURCHASE_LIST:
            const OldPurchaseListlistx = [...state.PurchaseList.list];
            const UpdatePurchaselist = OldPurchaseListlistx.map((elm) => {
                if (elm?.id === action.payload.id) {
                    return {
                        ...elm,
                        customer_name: action.payload?.customer_name,
                        mobile: action.payload?.mobile,
                        email: action.payload?.email,
                        use_case: action.payload?.use_case,
                        age_group: action.payload?.age_group,
                        pin_code_id: action.payload?.pin_code_id,
                        district_id: action.payload?.district_id,
                        state_id: action.payload?.state_id,
                        frame_number: action.payload?.frame_number,
                        product_color: action.payload?.product_color,
                        product_id: action.payload?.product_id,
                        product_name: action.payload?.product_name,
                        franchise_id: action.payload?.franchise_id,
                        franchise_name: action.payload?.franchise_name,
                        invoice_image: action.payload?.invoice_image,
                        invoice_image_name: action.payload?.invoice_image_name,
                    }
                } else
                    return elm;
            });
            return {
                ...state,
                PurchaseList: {
                    ...state.PurchaseList,
                    list: UpdatePurchaselist,
                },
            };
        case ActionTypes.GET_ALL_FRAME_LIST:
            return {
                ...state,
                FrameList: Object.keys(action.payload).length === 0 ? {} : action.payload,
            };
        case ActionTypes.TABLE_SORTING_BY:
            return {
                ...state,
                sortingfor: action.payload
            }
        case ActionTypes.GET_ORDER_LOG_LIST:
            return {
                ...state,
                AllOrderLogList: Object.keys(action.payload).length === 0 ? {} : action.payload,
            };
        case ActionTypes.ADD_FRANCHISE_BRAND_LIST:
            const OldFranchiseBrandlist = state.franchiseBrandListAll && Array.isArray(state.franchiseBrandListAll.list) ? [...state.franchiseBrandListAll.list] : [];
            OldFranchiseBrandlist.unshift(action.payload);
            return {
                ...state,
                franchiseBrandListAll: {
                    ...state.franchiseBrandListAll,
                    list: OldFranchiseBrandlist,
                },
            }
        case ActionTypes.REMOVE_FRANCHISE_BRAND:
            const updatedList = state.franchiseBrandListAll.list?.filter((item) => item.value !== action.payload)
            return {
                ...state,
                franchiseBrandListAll: {
                    ...state.franchiseBrandListAll,
                    list: updatedList
                }
            };
        case ActionTypes.FRANCHISE_ORDER_LATEST_THREE_LIST:
            return {
                ...state,
                franchiseOrderLatestThreeListl: action.payload,
            };
        case ActionTypes.UPDATE_ORDER_MANAGEMENT_FILTER:
            const { FromDate, ToDate, SelectOrderType, SelectRegion, UserSearch, SelectDateType, is_bundle_orders, OrderSource, WarehouseName, UserLimit, CurrentPage, is_odi_courier_pin } = action.payload.Date[action.payload.modulename];

            return {
                ...state,
                OrderManagmentFilter: {
                    ...state?.OrderManagmentFilter,
                    [action.payload.modulename]: {
                        ...state?.OrderManagmentFilter?.[action.payload.modulename],
                        UserSearch: UserSearch !== undefined ? UserSearch : state.OrderManagmentFilter[action.payload.modulename].UserSearch,
                        SelectDateType: SelectDateType !== undefined ? SelectDateType : state.OrderManagmentFilter[action.payload.modulename].SelectDateType,
                        is_bundle_orders: is_bundle_orders !== undefined ? is_bundle_orders : state.OrderManagmentFilter[action.payload.modulename].is_bundle_orders,
                        FromDate: FromDate !== undefined ? FromDate : state.OrderManagmentFilter[action.payload.modulename].FromDate,
                        ToDate: ToDate !== undefined ? ToDate : state.OrderManagmentFilter[action.payload.modulename].ToDate,
                        SelectOrderType: SelectOrderType !== undefined ? SelectOrderType : state.OrderManagmentFilter[action.payload.modulename].SelectOrderType,
                        SelectRegion: SelectRegion !== undefined ? SelectRegion : state.OrderManagmentFilter[action.payload.modulename].SelectRegion,
                        WarehouseName: WarehouseName !== undefined ? WarehouseName : state.OrderManagmentFilter[action.payload.modulename].WarehouseName,
                        OrderSource: OrderSource !== undefined ? OrderSource : state.OrderManagmentFilter[action.payload.modulename].OrderSource,
                        UserLimit: UserLimit !== undefined ? UserLimit : state.OrderManagmentFilter[action.payload.modulename].UserLimit,
                        CurrentPage: CurrentPage !== undefined ? CurrentPage : state.OrderManagmentFilter[action.payload.modulename].CurrentPage,
                        is_odi_courier_pin: is_odi_courier_pin !== undefined ? is_odi_courier_pin : state.OrderManagmentFilter[action.payload.modulename].is_odi_courier_pin,
                    }
                },
            };
            case ActionTypes.POINT_VALUE_LIST:
                return {
                    ...state,
                    PointValueList: {
                        list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                        pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    },
                };
            case ActionTypes.ADD_POINT_VALUE_LIST:
                const OldPointValuelist = [...state.PointValueList.list];
                OldPointValuelist.unshift(action.payload);
                return {
                    ...state,
                    PointValueList: {
                        ...state.PointValueList,
                        list: OldPointValuelist,
                    },
                };
            case ActionTypes.UPDATE_POINT_VALUE_LIST:
                const OldPointValuelistx = [...state.PointValueList.list];
                const UpdatePointValuelisT = OldPointValuelistx.map((elm) => {
                    if (elm?.id === action.payload.id) {
                        return {
                            ...elm,
                            refurbished_point: action.payload.refurbished_point,
                            show_condition: action.payload.show_condition,
                            point_score: action.payload.point_score,
                            point_images: action.payload.point_images,
                            is_active: action.payload.is_active,
                        };
                    } else
                        return elm;
                });
                return {
                    ...state,
                    PointValueList: {
                        ...state.PointValueList,
                        list: UpdatePointValuelisT,
                    }
                };
            case ActionTypes.GET_ERP_ACCOUNT_LIST_LIST:
                return {
                    ...state,
                    erpAccountList: {
                        list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                        franchise: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise,
                        pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    }
                };
            case ActionTypes.GET_ERP_ACCOUNT_LEADGER_LIST:
                return {
                    ...state,
                    ErpLedgerReportList: {
                        list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                        franchise: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise,
                        pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                        grand_credit: Object.keys(action.payload).length === 0 ? [] : action.payload.grand_credit,
                        grand_debit: Object.keys(action.payload).length === 0 ? [] : action.payload.grand_outstanding === undefined ? action.payload.grand_debit : action.payload.grand_outstanding,
                    }
                };
            case ActionTypes.GET_ERP_GENERAL_LEADGER_LIST:
                return {
                    ...state,
                    ErpGeneralLedgerReportList: {
                        list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                        franchise: Object.keys(action.payload).length === 0 ? [] : action.payload.franchise,
                        pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                        grand_credit: Object.keys(action.payload).length === 0 ? [] : action.payload.grand_credit,
                        grand_debit: Object.keys(action.payload).length === 0 ? [] : action.payload.grand_debit,
                        grand_outstatnding: Object.keys(action.payload).length === 0 ? [] : action.payload.grand_outstatnding,
                    }
                };
            case ActionTypes.GET_REFURBISHED_SETTINGS:
                return {
                    ...state,
                    RefurbishedSettings: action.payload,
                };
            case ActionTypes.UPDATE_REFURBISHED_SETTINGS:
                return {
                    ...state,
                    RefurbishedSettings: {
                        ...state?.RefurbishedSettings,
                        geared_yes : action.payload?.geared_yes,
                        geared_yes_score : action.payload?.geared_yes_score,
                        geared_no : action.payload?.geared_no,
                        geared_no_score : action.payload?.geared_no_score,
                        invoice_yes : action.payload?.invoice_yes,
                        invoice_yes_score : action.payload?.invoice_yes_score,
                        invoice_no : action.payload?.invoice_no,
                        invoice_no_score : action.payload?.invoice_no_score,
                        suspension_yes : action.payload?.suspension_yes,
                        suspension_yes_score : action.payload?.suspension_yes_score,
                        suspension_no : action.payload?.suspension_no,
                        suspension_no_score : action.payload?.suspension_no_score,
                    },
                };
            case ActionTypes.GET_RESELLCYCLE_REPORT_LIST:
                return {
                    ...state,
                    ResellcycleReportsList: {
                        list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                        refurbished_brand: Object.keys(action.payload).length === 0 ? [] : action.payload.refurbished_brand,
                        pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    }
                };
            case ActionTypes.GET_RESELLCYCLE_CUSTOMER_REPORT_LIST:
                return {
                    ...state,
                    ResellcycleCustomerReportsList: {
                        list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                        refurbished_brand: Object.keys(action.payload).length === 0 ? [] : action.payload.refurbished_brand,
                        district: Object.keys(action.payload).length === 0 ? [] : action.payload.district,
                        pin_code: Object.keys(action.payload).length === 0 ? [] : action.payload.pin_code,
                        state: Object.keys(action.payload).length === 0 ? [] : action.payload.state,
                        pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    }
                };
            case ActionTypes.GET_NAVIGATIONS_POINT_LIST:
                return{
                    ...state,
                    pointListNaviGation: action.payload,
                };
            case ActionTypes.GET_POINTS_SETTINGS_LIST:
                return {
                    ...state,
                    PointSettingsList: {
                        list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                        pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    }
                };
            case ActionTypes.ADD_POINTS_SETTINGS_LIST:
                const OldPointSettingsList = state.PointSettingsList?.list?.length > 0 ? [...state.PointSettingsList?.list] : [];
                OldPointSettingsList.unshift(action.payload);
                return {
                    ...state,
                    PointSettingsList: {
                        ...state.PointSettingsList,
                        list: OldPointSettingsList,
                    },
                };
            case ActionTypes.UPDATE_POINTS_SETTINGS_LIST:
                const OldPointSettingslistx = [...state.PointSettingsList.list];
                const UpdatePointSettingslists = OldPointSettingslistx.map((elm) => {
                    if (elm?.id === action.payload.id) {
                        return {
                            ...elm,
                            start_point: action.payload.start_point,
                            end_point: action.payload.end_point,
                            max_percentage: action.payload.max_percentage,
                            point_score: action.payload.point_score,
                        };
                    } else
                        return elm;
                });
                return {
                    ...state,
                    PointSettingsList: {
                        ...state.PointSettingsList,
                        list: UpdatePointSettingslists,
                    }
                };
            case ActionTypes.DELETE_POINTS_SETTINGS_LIST:
                const oldPointsArray = [...state.PointSettingsList?.list];
                const DeletetPointsArray = [];
                oldPointsArray && oldPointsArray.filter((elm) => {
                    if(elm?.id !== action.payload?.request?.refurbished_point_value_id) {
                        return DeletetPointsArray.push(elm);
                    }
                }); 
                return {
                    ...state,
                    PointSettingsList: {
                        ...state.PointSettingsList,
                        list: DeletetPointsArray,
                    },
                };
            case ActionTypes.GET_AGE_VALUE_LIST:
                return {
                    ...state,
                    AgeValueList: {
                        list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                        pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    },
                };
            case ActionTypes.ADD_AGE_VALUE_LIST:
                const OldAgeValueList = state.AgeValueList?.list?.length > 0 ? [...state.AgeValueList?.list] : [];
                OldAgeValueList.unshift(action.payload);
                return {
                    ...state,
                    AgeValueList: {
                        ...state.AgeValueList,
                        list: OldAgeValueList,
                    },
                };
            case ActionTypes.UPDATE_AGE_VALUE_LIST:
                const OldAgeValueListx = [...state.AgeValueList.list];
                const UpdateOldAgeValueListx = OldAgeValueListx.map((elm) => {
                    if (elm?.id === action.payload.id) {
                        return {
                            ...elm,
                            min_day: action.payload.min_day,
                            max_day: action.payload.max_day,
                            max_percentage: action.payload.max_percentage,
                            age_score: action.payload.age_score,
                        };
                    } else
                        return elm;
                });
                return {
                    ...state,
                    AgeValueList: {
                        ...state.AgeValueList,
                        list: UpdateOldAgeValueListx,
                    }
                };
            case ActionTypes.DELETE_AGE_VALUE:
                const oldAgeValueListArray = [...state.AgeValueList?.list];
                const DeletetAgeValueListArray = [];
                oldAgeValueListArray && oldAgeValueListArray.filter((elm) => {
                    if(elm?.id !== action.payload?.request?.refurbished_age_id) {
                        return DeletetAgeValueListArray.push(elm);
                    }
                });
                return {
                    ...state,
                    AgeValueList: {
                        ...state.AgeValueList,
                        list: DeletetAgeValueListArray,
                    },
                };
            case ActionTypes.GET_RESELL_BRAND_LIST:
                return {
                    ...state,
                    ResellBrandList: {
                        list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                        pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    },
                };
            case ActionTypes.ADD_RESELL_BRAND:
                const OldResellBrandList = state.ResellBrandList?.list?.length > 0 ? [...state.ResellBrandList?.list] : [];
                OldResellBrandList.unshift(action.payload);
                return {
                    ...state,
                    ResellBrandList: {
                        ...state.ResellBrandList,
                        list: OldResellBrandList,
                    },
                };
            case ActionTypes.UPDATE_RESELL_BRAND:
                const OldResellBrandListx = [...state.ResellBrandList.list];
                const UpdateOldResellBrandListx = OldResellBrandListx.map((elm) => {
                    if (elm?.id === action.payload.id) {
                        return {
                            ...elm,
                            brand_name: action.payload.brand_name,
                            resale_percentage: action.payload.resale_percentage,
                            is_active: action.payload.is_active,
                        };
                    } else
                        return elm;
                });
                return {
                    ...state,
                    ResellBrandList: {
                        ...state.ResellBrandList,
                        list: UpdateOldResellBrandListx,
                    }
                };
            case ActionTypes.GET_ERP_API_FAILURE_LIST:
                return {
                    ...state,
                    ErpApiFailureList: {
                        list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                        erp_order_log_list: Object.keys(action.payload).length === 0 ? [] : action.payload.erp_order_log_list,
                        pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    },
                };
            case ActionTypes.GET_ERP_INVOICE_API_FAILURE_LIST:
                return {
                    ...state,
                    ErpApiInvoiceFailureList: {
                        list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                        erp_order_log_list: Object.keys(action.payload).length === 0 ? [] : action.payload.erp_order_log_list,
                        pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    },
                };
            case ActionTypes.GET_ERP_DELIVERY_NOTE_FRAME_NUMBER_FAILURE_LIST:
                return {
                    ...state,
                    ErpApiDeleiveryNoteFrameNumberFailureList: {
                        list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                        erp_order_log_list: Object.keys(action.payload).length === 0 ? [] : action.payload.erp_order_log_list,
                        pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    },
                };
            case ActionTypes.GET_ERP_PURCHASE_TRUE_LIST:
                return {
                    ...state,
                    ErpAPIPurchaseTrueList: {
                        list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                        erp_order_log_list: Object.keys(action.payload).length === 0 ? [] : action.payload.erp_order_log_list,
                        pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    },
                };
            case ActionTypes.GET_ERP_PURCHASE_FALSE_LIST:
                return {
                    ...state,
                    ErpAPIPurchaseFalseList: {
                        list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                        erp_order_log_list: Object.keys(action.payload).length === 0 ? [] : action.payload.erp_order_log_list,
                        pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    },
                };
            case ActionTypes.GET_FBA_ORDER_LIST:
                return {
                    ...state,
                    TbaOrderList: {
                        list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                        pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    },
                };
            case ActionTypes.GET_INCENTIVE_SLAB_LIST:
                return {
                    ...state,
                    IncentiveSlabList: {
                        list: Object.keys(action.payload).length === 0 ? [] : action.payload.data,
                        pagination: Object.keys(action.payload).length === 0 ? [] : action.payload.pagination,
                    },
                };
            case ActionTypes.GET_SEARCH_MODULES_OBJECT:
                return {
                    ...state,
                    SearchModulesObj: action.payload,
                }
            case ActionTypes.GET_ORDER_CANCEL_STATUS:
                return {
                    ...state,
                    orderCancelSuccess: {
                        open: action.payload?.open,
                        title: action.payload?.title,
                        message: action.payload?.message,
                    }
                }
            case ActionTypes.ADMIN_LOGOUT:
                return {
                    ...initialData,
                };
        default:
            return state;
    }
};