import { GENERATE_FRAME_EXCEL_API_URL } from "../components/Shared/constant";

const GenerateFrameExcelAPI = async (accessToken, payload) => {
    // const SoldStataus = payload?.is_sold ? "is_sold=true" : payload?.is_sold_unsold ? "is_sold_unsold=true" : "is_unsold=true";
    // const api_url = `${GENERATE_FRAME_EXCEL_API_URL}?emails=${payload?.emails}&${SoldStataus}`;

    // const responce = window.axios.get(api_url, {
    // headers: {
    //   'Authorization': 'Bearer ' + accessToken,
    // }}).then(function (result) {
    //     return {
    //       ...result.data,
    //       status:200
    //     };
    // }).catch(function (result) {
    //     return {
    //       ...result?.response?.data,
    //       status:result?.response?.status
    //     }
    // });
    // return responce;
    const responce = window.axios.post(GENERATE_FRAME_EXCEL_API_URL, payload, {
    headers: {
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/json',
      'Authorization': 'Bearer ' + accessToken,
    }}).then(function (result) {
        return {
          ...result.data,
          status:200
        };
    }).catch(function (result) {
        return {
          ...result?.response?.data,
          status:result?.response?.status
        }
    });
    return responce;
};
  
export default GenerateFrameExcelAPI;