import React, { useState } from "react";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Table } from "react-bootstrap";
import Draggable from 'react-draggable';

const CommonSplitScreenList = (props) => {
    const { splitScreenModal, setsplitScreenModal } = props;
    const [ minimizeCollapse, setminimizeCollapse ] = useState(true);

    const closeModal = () => {
        setsplitScreenModal({
            ...splitScreenModal,
            open: false,
            error: false,
            title: "",
            list: [],
        });
    };

    return(<Draggable>
    <div className={splitScreenModal?.error ? "splits_wrapper_list error" : "splits_wrapper_list"}>
        <div className="splits_wrapper_header">
            <h4>{splitScreenModal?.title}</h4>
            <div className="modal_setting">
                <i class={minimizeCollapse ? "bi bi-dash-circle" : "bi bi-plus-circle"} onClick={() => setminimizeCollapse(!minimizeCollapse)}></i>
                <i class="bi bi-x-circle" onClick={() => closeModal()}></i>
            </div>
        </div>
        {minimizeCollapse && (<div className="splits_wrapper_table">
            <div className="tableView">
                <Scrollbars 
                    style={{ height: "calc(100vh - 150px)"}} 
                    renderView={props => <div {...props} className="view"/>}
                    className="ScrollbarsSidebar"
                >
                    <Table bordered  >
                        <thead>
                            <tr>
                                <th>SNO</th>
                                <th>Email</th>
                                <th>Monthly Target</th>
                            </tr>
                        </thead>
                        <tbody>
                            {splitScreenModal?.list && splitScreenModal?.list?.map((elm, index) => {
                                return(<tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{elm?.email}</td>
                                    <td>{elm?.monthly_target}</td>
                                </tr>)
                            })}
                        </tbody>
                    </Table>
                </Scrollbars>
            </div>
        </div>)}
    </div>
    </Draggable>)
}

export default CommonSplitScreenList;