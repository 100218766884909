/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table } from 'react-bootstrap';
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link, useNavigate } from "react-router-dom";
import { WebSocketContext } from "../../App";
import { ADD_AGE_VALUE_REPORT, UPDATE_AGE_VALUE_REPORT } from "../Shared/constant";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../Common/CommonPagination";
import GetCurrentTableSRNo from "../Common/GetCurrentTableSRNo";
import DeleteModal from "../Common/DeleteModal";

const AgeValue = (props) => {
    const { RouteName } = props;
    const { AgeValueList, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ deleteCommonStateModal, setdeleteCommonStateModal ] = useState({
        title: "",
        description: "",
        open: false,
        delete_id: "",
        delete_url: ""
    });
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };


    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ReSells[3]?.Edit || PermissionsAccess?.ReSells[3]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "refurbished_age_list",
                "request" : { 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "order_by" :"-updated_at"
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    // create points
    const cmnFnct = () => {
        Navigate(ADD_AGE_VALUE_REPORT);
    };

    // delete setting points
    const DeletePoint = (data) => {
        setdeleteCommonStateModal({
            ...deleteCommonStateModal,
            title: "<i class='bi bi-trash3' style='color: red'></i> Delete Point",
            description: `Are you sure want to delete this Age Value ?`,
            open: true,
            delete_id: data?.id,
            delete_url: "Age-Value"
        });
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={""}
            searchType={"Point Name"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ReSells[3]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Age Value",
                function: cmnFnct
            }}
            searchdisable={false}
            ActiveInactiveFilter={false}
        />
        <div className="tableView">
            <Scrollbars 
                style={{ height: AgeValueList?.pagination?.total_records > 10 ? "calc(100vh - 188px)" : "calc(100vh - 198px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>Min Day</th>
                            <th>Max Day</th>
                            <th>Max %</th>
                            <th>Age Score</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ReSells[3]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {AgeValueList?.list?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, AgeValueList?.pagination?.current_page, AgeValueList?.pagination?.record_limit)}    
                                </td>
                                <td>{elm?.min_day}</td>
                                <td>{elm?.max_day}</td>
                                <td>{elm?.max_percentage}</td>
                                <td>{elm?.age_score}</td>
                                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ReSells[3]?.Edit) && (<td>
                                    <Link className="btn edittable" to={`${UPDATE_AGE_VALUE_REPORT}/${elm?.id}`}>
                                        <i className="bi bi-pencil-square"></i>
                                    </Link>
                                    <button className="btn edittable" style={{ marginLeft: "5px" }} onClick={() => DeletePoint(elm)}>
                                        <i className="bi bi-trash3"></i>
                                    </button>
                                </td>)}
                            </tr>)
                        })}
                        {AgeValueList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="7">Age Value list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {AgeValueList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={AgeValueList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        {deleteCommonStateModal?.open && (<DeleteModal setdeleteCommonStateModal={setdeleteCommonStateModal} deleteCommonStateModal={deleteCommonStateModal} />)}
    </section>)
}

export default AgeValue;