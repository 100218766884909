import React from "react";
import { Image } from "react-bootstrap";
import ProductDefaultImage from "../../assets/img/DefaultProduct.jpg";
import { FILE_AUTH_URL } from "../Shared/constant";

const AvatarSet = (props) => {
    const { url, type } = props;

    if(url !== undefined) {
        return(<div className="AvtarMain">
            {type === "user" ? (
                <Image src={url === null ? ProductDefaultImage : FILE_AUTH_URL+ url} onError={(e) => e.target.src = ProductDefaultImage} alt="avatar" />
            ) : (
                <Image src={url === null ? ProductDefaultImage : FILE_AUTH_URL + url} onError={(e) => e.target.src = ProductDefaultImage} alt="avatar" />
            )}
        </div>)
    }
}

export default AvatarSet;