/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Badge, Collapse, Image } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import OrderDataTableFilter from "../../Common/OrderDataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import MoreIcon from '../../../assets/img/more_options.png';
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import { Link } from "react-router-dom";
import { TimeSense, getColor } from "../../Common/TimeSense";
import { getTableSorting, updateOrderManagementFilter, ClearFormSetFutios } from "../../../redux/actions/adminActions";
import OrderStatusModel from "../../Common/OrderStatusModel";
import CommonExport from "../../Common/CommonExport";

const POPosting = (props) => {
    const { RouteName } = props;
    const [userType, setUserType] = useState("all");
    const { websocket } = useContext(WebSocketContext);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const today = new Date();
    const { device_id, OrderPOApprovalsList, sortingfor, selfInfo, Regionlistall, OrderManagmentFilter } = useSelector((state) => state.adminReducers);
    const [activeTableDetails, setActiveTableDetails] = useState("");
    const Dispatch = useDispatch();
    const [orderSource, setOrderSource] = useState("");
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    //order model
    const [OrderNewModel, setOrderNewModel] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });

    //order status
    const showOrder = (value) => {
        setOrderNewModel({
            ...OrderNewModel,
            open: !OrderNewModel?.open,
            title: "Order Log",
            subtitle: "",
            Data: value
        });
    };
    
    // page change
    const currentFunction = (page) => {
        Dispatch(updateOrderManagementFilter({
            SOApprovals: {
                CurrentPage: page,
            }
        }));
    };

    useEffect(() => {
        CommonSortingFct("")
    }, []);

    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[2]?.Edit || PermissionsAccess?.OrderManagement[2]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "region_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        Dispatch(updateOrderManagementFilter({
            SOApprovals: {
                CurrentPage: 1,
            }
        }));
    };

    const selectRegionFunction = (e) => {
        Dispatch(updateOrderManagementFilter({
            SOApprovals: {
                SelectRegion: e.target.value,
                CurrentPage: 1,
            }
        }));
    };

    // select start date
    const DateStartGet = (date) => {
        if (date === null) {
            setStartDate("");
            setEndDate("");
            Dispatch(updateOrderManagementFilter({
                SOApprovals: {
                    FromDate: "",
                    ToDate: "",
                }
            }));
        } else {
            Dispatch(updateOrderManagementFilter({
                SOApprovals: {
                    FromDate: date,
                }
            }));
            let selectedDate = new Date(date);
            if (selectedDate.toDateString() === today.toDateString()) {
                setStartDate(selectedDate);
                setEndDate(selectedDate);
            } else if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
        }
    };


    // select end date
    const DateEndGet = (date) => {
        Dispatch(updateOrderManagementFilter({
            SOApprovals: {
                CurrentPage: 1,
            }
        }));
        if (date === null) {
            // setEndDate("")
            let selectedDate = new Date(date);
            if (selectedDate < today) {
                let timeDiff = today.getTime() - selectedDate.getTime();
                let daysDiff = Math.round(timeDiff / (1000 * 3600 * 24))
                if (daysDiff >= 15) {
                    const fifteenDaysLater = new Date(selectedDate.getTime() + 14 * 24 * 60 * 60 * 1000);
                    setStartDate(selectedDate);
                    setEndDate(fifteenDaysLater);
                } else {
                    setStartDate(selectedDate);
                    setEndDate(today);
                }
            }
            Dispatch(updateOrderManagementFilter({
                SOApprovals: {
                    ToDate: "",
                }
            }));
        } else {
            Dispatch(updateOrderManagementFilter({
                SOApprovals: {
                    ToDate: date,
                }
            }));
        }
    };

    // get order_list all
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[2]?.Edit || PermissionsAccess?.OrderManagement[2]?.View)) {
            const { UserLimit, UserSearch, SelectRegion, OrderSource, FromDate, ToDate, CurrentPage, is_bundle_orders } = OrderManagmentFilter?.SOApprovals;

            let param = {
                "transmit": "broadcast",
                "url": "order_list",
                "request": {
                    "status": "OPEN,SO_HALF_APPROVAL",
                    "limit": UserLimit,
                    "page_no": CurrentPage,
                    "search": UserSearch.trim(),
                    "from_date": null,
                    "to_date": null,
                    "from_inv": null,
                    "to_inv": null,
                    "order_type": OrderSource,
                    "region_id": SelectRegion,
                    "order_by": sortingfor,
                    "is_bundle_orders": is_bundle_orders,
                },
                "DeviceId": device_id
            };
            if (FromDate !== "" && ToDate !== "") {
                param.request.from_date = CommonDateTime(FromDate, "YYYY-MM-DD", "hide");
                param.request.to_date = CommonDateTime(ToDate, "YYYY-MM-DD", "hide");
            }
            wsSend_request(websocket, param);
        };
    }, [websocket, sortingfor, OrderManagmentFilter?.SOApprovals]);



    // get franchise name
    const GetFranchiseName = (billing_id) => {
        const current = OrderPOApprovalsList?.franchise?.filter((elm) => elm?.id === billing_id)[0]
        return {
            name: current?.customer_name
        };
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // get total balance and Credit Limit	
    const GetCurrentOrderRate = (current) => {
        const getCurrentFranchise = OrderPOApprovalsList?.franchise?.filter((elm) => elm?.id === current?.billing_id)[0];
        const getCurrentFranchiseType = OrderPOApprovalsList?.franchise_type?.filter((elm) => elm?.id === getCurrentFranchise?.franchise_type_id)[0];

        let creditlimit = 0;
        let wallet = 0

        if (getCurrentFranchiseType?.franchise_type_credit !== null && getCurrentFranchiseType?.franchise_type_credit !== "") {
            wallet = parseInt(getCurrentFranchiseType?.franchise_type_credit);
            creditlimit = parseInt(getCurrentFranchiseType?.franchise_type_credit);
        };
        if (getCurrentFranchise?.credit_addon !== null && getCurrentFranchise?.credit_addon !== "") {
            wallet = parseInt(getCurrentFranchise?.credit_addon) + wallet;
            creditlimit = parseInt(getCurrentFranchise?.credit_addon) + creditlimit;
        };
        if (getCurrentFranchise?.advance_amount !== null && getCurrentFranchise?.advance_amount !== "") {
            wallet = wallet + parseInt(getCurrentFranchise?.advance_amount);
        };

        return {
            Wallet_balance: (getCurrentFranchise?.advance_amount === null || getCurrentFranchise?.advance_amount === "") ? 0 : parseInt(getCurrentFranchise?.advance_amount),
            credit_limit: creditlimit,
            available_balance: wallet,
        };
    };

    const CommonSortingFct = (url) => {
        if (sortingfor === url) {
            Dispatch(getTableSorting("-" + url))
        } else {
            Dispatch(getTableSorting(url))
        };
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url: "",
            action: false
        }));
    }, []);

    const GetRegionName = (id) => {
        if(id === null) {
            return "-";
        } else {
            const currentRegionData = OrderPOApprovalsList?.regionlist?.filter((elm) => elm?.id === id)[0];
            return currentRegionData?.region_name;
        }
    };

    return (<section className="Main_section">
        <div className="filtr_Form" style={{ paddingBottom: "10px", borderBottom: "1px solid #eee" }}>
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-3">
                        <Form.Group>
                            <div className="datepickField min">
                                <DatePicker
                                    selected={OrderManagmentFilter?.SOApprovals?.FromDate === "" ? "" : new Date(OrderManagmentFilter?.SOApprovals?.FromDate)}
                                    startDate={OrderManagmentFilter?.SOApprovals?.FromDate === "" ? "" : new Date(OrderManagmentFilter?.SOApprovals?.FromDate)}
                                    endDate={OrderManagmentFilter?.SOApprovals?.ToDate === "" ? "" : new Date(OrderManagmentFilter?.SOApprovals?.ToDate)}
                                    onChange={(date) => DateStartGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    maxDate={today}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter From Date"
                                    isClearable={OrderManagmentFilter?.SOApprovals?.FromDate !== "" ? true : false}
                                    name="statDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" >
                        <Form.Group>
                            <div className="datepickField">
                                <DatePicker
                                    selected={OrderManagmentFilter?.SOApprovals?.ToDate === "" ? "" : new Date(OrderManagmentFilter?.SOApprovals?.ToDate)}
                                    startDate={OrderManagmentFilter?.SOApprovals?.FromDate === "" ? "" : new Date(OrderManagmentFilter?.SOApprovals?.FromDate)}
                                    endDate={OrderManagmentFilter?.SOApprovals?.ToDate === "" ? "" : new Date(OrderManagmentFilter?.SOApprovals?.ToDate)}
                                    minDate={startDate}
                                    maxDate={endDate}
                                    onChange={(date) => DateEndGet(date)}
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    placeholderText="Enter To Date"
                                    isClearable={OrderManagmentFilter?.SOApprovals?.ToDate !== "" ? true : false}
                                    name="ToDate"
                                    autoComplete="off"
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className="col-md-3" style={{ width: "15%" }}>
                        <select className="form-control activestatusselet pe-4" id="columnfullwith" value={OrderManagmentFilter?.SOApprovals?.SelectRegion} onChange={(e) => selectRegionFunction(e)}>
                            <option value="">Select Region</option>
                            {Regionlistall?.map((elm, index) => {
                                return(<option value={elm?.id} key={index}>{elm?.region_name}</option>)
                            })}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <button className="btn exportbtn" disabled={OrderManagmentFilter?.SOApprovals?.ToDate === "" || OrderManagmentFilter?.SOApprovals?.FromDate === ""} style={{ width: "auto" }} onClick={() => CommonExport("order_list", { "FromDate": OrderManagmentFilter?.SOApprovals?.FromDate, "ToDate": OrderManagmentFilter?.SOApprovals?.ToDate, "FromInv": null, "ToInv": null, "status": "OPEN,SO_HALF_APPROVAL", "search": OrderManagmentFilter?.SOApprovals?.UserSearch.trim(), "region_id": OrderManagmentFilter?.SOApprovals?.SelectRegion , "order_type": OrderManagmentFilter?.SOApprovals?.OrderSource })}>
                            <i className="bi bi-filetype-xlsx"></i>
                            Export
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <OrderDataTableFilter
            filterType={""}
            searchType={"Order Id, Name, Customer Code"}
            userType={userType}
            setUserType={setUserType}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
            ActiveInactiveFilter={false}
        />
        <div className="tableView orderlistTable gstreporttableadded">
            <Scrollbars
                style={{ height: OrderPOApprovalsList?.pagination?.total_records > 10 ? "calc(100vh - 245px)" : "calc(100vh - 209px)" }}
                renderView={props => <div {...props} className="view" />}
                
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th></th>
                            <th>SNo</th>
                            <th className={sortingfor === "order_code" ? "active" : sortingfor === "-order_code" ? "inactive" : ""} onClick={() => CommonSortingFct("order_code")}>Order Id</th>
                            <th className={sortingfor === "customer_identity" ? "active" : sortingfor === "-customer_identity" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_identity")}>Customer Code</th>
                            <th className={sortingfor === "created_at" ? "active" : sortingfor === "-created_at" ? "inactive" : ""} onClick={() => CommonSortingFct("created_at")}>Order Date</th>
                            <th className={sortingfor === "order_source" ? "active" : sortingfor === "-order_source" ? "inactive" : ""} onClick={() => CommonSortingFct("order_source")}>Order Source</th>
                            <th className={sortingfor === "customer_name" ? "active" : sortingfor === "-customer_name" ? "inactive" : ""} onClick={() => CommonSortingFct("customer_name")}>SHIP To Name</th>
                            <th className={sortingfor === "billing_id" ? "active" : sortingfor === "-billing_id" ? "inactive" : ""} onClick={() => CommonSortingFct("billing_id")}>BILL To Name</th>
                            <th className={sortingfor === "qty" ? "active" : sortingfor === "-qty" ? "inactive" : ""} onClick={() => CommonSortingFct("qty")}>Quantity</th>
                            <th className={sortingfor === "order_priority" ? "active" : sortingfor === "-order_priority" ? "inactive" : ""} onClick={() => CommonSortingFct("order_priority")}>Order Priority</th>
                            <th className={sortingfor === "total" ? "active" : sortingfor === "-total" ? "inactive" : ""} onClick={() => CommonSortingFct("total")}>Total Value</th>
                            <th className={sortingfor === "updated_at" ? "active" : sortingfor === "-updated_at" ? "inactive" : ""} onClick={() => CommonSortingFct("updated_at")}>Pending Since </th>
                            <th className={sortingfor === "priority" ? "active" : sortingfor === "-priority" ? "inactive" : ""} onClick={() => CommonSortingFct("priority")}>Criticality</th>
                            <th className={sortingfor === "order_status" ? "active" : sortingfor === "-order_status" ? "inactive" : ""} onClick={() => CommonSortingFct("order_status")}>Status</th>
                            {/* <th>Add. Status</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {OrderPOApprovalsList?.list?.map((elm, index) => {
                            const get_color = getColor(elm);

                            return (<React.Fragment key={index}>
                                {/* <tr className={elm?.erp_bundle_id !== null ? "bundle_order_active" : ""}> */}
                                <tr>
                                    <td style={{ width: '10px' }}>
                                        <button
                                            className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                            onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={activeTableDetails === elm?.id ? true : false}
                                        >
                                            <Image src={MoreIcon} alt="more icon" />
                                        </button>
                                    </td>
                                    <td>
                                        <Link className="btn-link btn-link-color text-decoration-none" onClick={() => showOrder(elm?.id)}>
                                            {GetCurrentTableSRNo(index, OrderPOApprovalsList?.pagination?.current_page, OrderPOApprovalsList?.pagination?.record_limit)}
                                        </Link>
                                    </td>
                                    <td>
                                        {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.OrderManagement[2]?.Edit) ? (<Link className="btn-link btn-link-color text-decoration-none" to={"/so-approval/" + elm?.id}>
                                            <b className="tableBolanchor">{elm?.order_code}</b>
                                        </Link>) : (<b className="tableBolanchor">{elm?.order_code}</b>)}
                                    </td>
                                    <td>
                                        {elm?.customer_identity === null ? "-" : elm?.customer_identity}
                                    </td>
                                    <td>{CommonDateTime(elm?.created_at, "LL", "show")}</td>
                                    <td>{elm?.order_source === null ? "-" : elm?.order_source}</td>
                                    <td>{elm?.customer_name !== null ? elm?.customer_name : "-"}</td>
                                    <td>{GetFranchiseName(elm?.billing_id)?.name === undefined ? "-" : GetFranchiseName(elm?.billing_id)?.name}</td>
                                    <td>{elm?.qty}</td>
                                    <td>{elm?.order_priority ? elm?.order_priority : "-"}</td>
                                    <td>{AmountNumberFormat(parseFloat(elm?.total))}</td>
                                    <td>{TimeSense(elm?.updated_at)}</td>
                                    <td >
                                        <span className={`px-2 py-1 rounded ${get_color.color_code}`} >{get_color.text}</span>
                                    </td>
                                    <td>
                                        {elm?.order_status === "OPEN" ? (<Badge bg="danger">SO Approval Pending</Badge>) : (<Badge bg="success">SO Partially Approved</Badge>)}
                                    </td>
                                    {/* <td>
                                        {elm?.old_invoice_code === null ? (<Badge bg="success">Regular Order</Badge>) : (<Badge bg="primary">Replacement Order</Badge>)}
                                    </td> */}
                                </tr>
                                <Collapse in={activeTableDetails === elm?.id ? true : false}>
                                    <tr className="tablecollapsewraps" id={"tablecollpase" + elm?.id}>
                                        <td colSpan="12" >
                                            <Table bordered  className="table">
                                                <tbody>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Contact</th>
                                                        <td>{elm?.mobile}</td>
                                                    </tr>
                                                    {elm?.order_type === "FO" && (<>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Wallet Balance</th>
                                                            <td>{AmountNumberFormat(GetCurrentOrderRate(elm)?.Wallet_balance)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Credit Limit</th>
                                                            <td>{AmountNumberFormat(GetCurrentOrderRate(elm)?.credit_limit)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th style={{ width: "20%" }}>Remarks</th>
                                                            <td>{elm?.remark === null ? "-" : elm?.remark}</td>
                                                        </tr>
                                                    </>)}
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Attachments</th>
                                                        <td>
                                                            {elm?.attachments === null ? "-" : (<ul className="fileuploadListytable">
                                                                {elm?.attachments?.map((elm, index) => {
                                                                    return (<li key={index}>
                                                                        <a href={process.env.REACT_APP_BASE_URL + "file/" + elm?.view_file_url} download={elm?.attachment_name} target="__blank"><i className="bi bi-file-earmark-text"></i> {elm?.attachment_name}  <i className="bi bi-download downloadIcomn"></i></a>
                                                                    </li>)
                                                                })}
                                                            </ul>)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Region</th>
                                                        <td>{GetRegionName(elm?.region_id)}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>
                                </Collapse>
                            </React.Fragment>)
                        })}
                        {OrderPOApprovalsList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="12">SO Approvals order report list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {OrderPOApprovalsList?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={OrderManagmentFilter?.SOApprovals?.CurrentPage}
                paginationLength={OrderPOApprovalsList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        <OrderStatusModel setOrderNewModel={setOrderNewModel} OrderNewModel={OrderNewModel} />
    </section>)
}

export default POPosting;