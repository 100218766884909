/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { FormControl, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import Scrollbars from "react-custom-scrollbars-2";
import { Link, useNavigate, useParams } from "react-router-dom";
import wsSend_request from "../../../Api/ws/ws_request";
import { WebSocketContext } from "../../../App";
import { FRANCHISE_PENDING_ORDERS_URL, ORDER_ACCOUNT_APPROVAL_API_URL } from "../../Shared/constant";
import OrderInvoice from "../../Common/OrderInvoice";
import PostRequestCall from "../../../Api/PostRequest";
import { toast } from "react-toastify";
import CancelOrder from "../../Common/CancelOrder";
import { getStringOfErrorValues, printContent } from "../../Common/TimeSense";
import CommonLoader from "../../Common/CommonLoader";
import CommonLogout from "../../Common/CommonLogout";

const FranchisePendingOrdersEdit = () => {
    const navigate = useNavigate()
    const { OrderReportDetails, device_id, ClearFormSet, accessToken } = useSelector((state) => state.adminReducers);
    const Dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    const [loader, setLoader] = useState(false)
    const [is_approve, setIs_approve] = useState(true)
    const [cancel_reason, setCancel_reason] = useState("")
    const roomId = useParams();

    useEffect(() => {
        if(roomId?.id) {
            let param = { 
                "transmit": "broadcast", 
                "url": "order_detail",
                "request" : { 
                    "order_id" : roomId?.id,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }
    }, [ roomId?.id, websocket ]);

    
    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            Dispatch(ClearFormSetFutios({
                url:"",
                action:false
            }));
            navigate(FRANCHISE_PENDING_ORDERS_URL);
            
        };
    }, [ ClearFormSet ]);

    const callSubmitData = async () =>{
        if(!(is_approve === false && cancel_reason == "" && loader === false)){
            setLoader(true)
            // const param = {
            //     "transmit": "broadcast",
            //     "url": "ac_approval",
            //     "request" :{
            //         "order_id" : roomId?.id,
            //         "is_approve":is_approve,
            //         "cancel_reason":cancel_reason
            //     },
            //     "DeviceId" : device_id,
            // }
            // wsSend_request(websocket, param);
            // const payload = {
            //         "order_id" : roomId?.id,
            //         "is_approve":is_approve,
            //         "cancel_reason":cancel_reason
            //     };
            const formData = new FormData();
            formData.append("order_id", roomId?.id);
            formData.append("is_approve", is_approve);
            formData.append("cancel_reason", cancel_reason);
            const response = await PostRequestCall(ORDER_ACCOUNT_APPROVAL_API_URL, formData, accessToken);
            if(response?.status === 200) {
                setLoader(false)
                if(response?.msg){
                    toast.success(response?.data?.msg);
                }
                if(typeof(response?.message) == "object"){
                    toast.success(getStringOfErrorValues(response?.message));
                }
                if(typeof(response?.message) == "string"){
                    toast.success(response?.message);
                }
                navigate(FRANCHISE_PENDING_ORDERS_URL);
            } else {
                setLoader(false)
                if(typeof(response?.errors) == "object"){
                    toast.error(getStringOfErrorValues(response?.errors));
                }
                if(typeof(response?.message) == "object"){
                    toast.error(getStringOfErrorValues(response?.message));
                }
                if(typeof(response?.message) == "string"){
                    toast.error(response?.message);
                }
                if(response?.message === "User is not authorized." && response?.status === 400) {
                    CommonLogout(accessToken, Dispatch);
                    localStorage?.removeItem("access_token");
                };
            };
            
        }
    }
    return(
        <React.Fragment>
            <section className="Main_section">
                <div className="MainHeader">
                    <button onClick={()=>printContent('invoice_model1')} className='btn btn-icon p-1'>
                        <i className="bi bi-printer"></i>
                    </button>
                    <Link className="btn btn-link backbtn" to={FRANCHISE_PENDING_ORDERS_URL}><i className="bi bi-arrow-left-short"></i> Back</Link>
                </div>
                    <Scrollbars
                        style={{ height: "calc(100vh - 149px )"}} 
                        renderView={props => <div {...props} className="view"/>}
                        
                        className="ScrollbarsSidebar"
                    >
                        <OrderInvoice id={"invoice_model1"} OrderReportDetails={OrderReportDetails} />
                        <div className="row m-0 mb-4">
                            <div className="col-6">
                                <Form.Check
                                    inline
                                    label="Approve"
                                    name="is_approve"
                                    type={"radio"}
                                    value={true}
                                    onChange={(e)=>setIs_approve(true)}
                                    checked={is_approve === true?true:false}
                                    id={`inline--2`}
                                />
                            </div>
                            <div className="col-6">
                                <Form.Check
                                    inline
                                    label="Rejected with Reasons"
                                    name="is_approve"
                                    className={"mb-3"}
                                    value={false}
                                    type={"radio"}
                                    onChange={(e)=>setIs_approve(false)}
                                    checked={is_approve === false?true:false}
                                    id={`inline-2`}
                                />
                                <FormControl
                                    type="textarea"
                                    as={"textarea"}
                                    onChange={(e)=>setCancel_reason(e.target.value)}
                                    value={cancel_reason}
                                />
                            </div>
                            <div className="pt-3 text-center">
                                <button disabled={(is_approve === false && cancel_reason == "") || loader ?true:false} onClick={callSubmitData} className="btn exportbtn">Submit</button>
                                <CancelOrder className={"btn-sm"} data={OrderReportDetails} />
                            </div>
                        </div>
                    </Scrollbars>
            </section>
            {loader && (<CommonLoader />)}
        </React.Fragment>
    )
}

export default FranchisePendingOrdersEdit;