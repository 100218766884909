/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Badge, Button, Form } from 'react-bootstrap';
import CommonToggle from "../../Common/CommonToggle";
import { Link, useNavigate, useLocation, useParams  } from "react-router-dom";
import { FRANCHISE_TYPE_REPORT_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";

const AddFranchiseType = () => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ ApplicationState, setApplicationState ] = useState({
        FranchiseTypeName: "",
        FranchiseTypeCredit: "",
        Status: true
    });
    const { roomId } = useParams();
    const { ClearFormSet, FranchiseTypeList, device_id } = useSelector((state) => state.adminReducers);
    const CurrentData_ = FranchiseTypeList?.list?.filter((elm) => elm?.id === roomId)[0];
    const exceptThisCostSymbols = ["e", "E", "+", "-"];

    // error state
    const [ errorFranchiseTypeName, setErrorFranchiseTypeName ] = useState("");
    const [ errorFranchiseTypeCredit, setErrorFranchiseTypeCredit ] = useState("");

    useEffect(() => {
        if(LocationRoute === "edit-franchise-type-report") {
            setApplicationState({
                ...ApplicationState,
                FranchiseTypeName: CurrentData_?.franchise_type_name,
                FranchiseTypeCredit: CurrentData_?.franchise_type_credit,
                Status: CurrentData_?.is_active
            });
        };
    }, [ CurrentData_ ])

    // create and edit brand
    const CreateBrand = () => {
        if(ApplicationState?.FranchiseTypeName.trim() !== "" && ApplicationState?.FranchiseTypeCredit.trim() !== "") {
            if(LocationRoute === "edit-franchise-type-report") {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "franchise_type_edit",
                    "request" : { 
                        "franchise_type_id" : CurrentData_?.id,
                        "franchise_type_name" : ApplicationState?.FranchiseTypeName.trim(),
                        "franchise_type_credit" : ApplicationState?.FranchiseTypeCredit.trim(), 
                        "is_active" : ApplicationState?.Status
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            } else {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "franchise_type_add",
                    "request" : { 
                        "franchise_type_name" : ApplicationState?.FranchiseTypeName.trim(),
                        "franchise_type_credit" : ApplicationState?.FranchiseTypeCredit.trim(), 
                        "is_active" : ApplicationState?.Status
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            }
        } else {
            CheckValid(ApplicationState?.FranchiseTypeName.trim(), {type: 'FranchiseTypeName', error: setErrorFranchiseTypeName});
            CheckValid(ApplicationState?.FranchiseTypeCredit.trim(), {type: 'FranchiseTypeCredit', error: setErrorFranchiseTypeCredit})
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            Navigate(FRANCHISE_TYPE_REPORT_URL);
        };
    }, [ ClearFormSet ]);

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={FRANCHISE_TYPE_REPORT_URL} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappers">
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Franchise Type Name<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="text" 
                                value={ApplicationState?.FranchiseTypeName} 
                                onChange={(e) => setApplicationState({...ApplicationState, FranchiseTypeName: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'FranchiseTypeName', error: setErrorFranchiseTypeName})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Franchise Type Name" 
                                disabled={UploadFileLoader}
                            />
                            {errorFranchiseTypeName !== "" &&<span className="error_medotory">{errorFranchiseTypeName}</span>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Franchise Type Credit<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="number" 
                                className="inputBoxs"
                                value={ApplicationState?.FranchiseTypeCredit} 
                                onChange={(e) => setApplicationState({...ApplicationState, FranchiseTypeCredit: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'FranchiseTypeCredit', error: setErrorFranchiseTypeCredit})}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e)
                                    exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                }}
                                placeholder="Enter Franchise Type Name" 
                                disabled={UploadFileLoader}
                                min="0"
                            />
                            {errorFranchiseTypeCredit !== "" &&<span className="error_medotory">{errorFranchiseTypeCredit}</span>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Status</Form.Label>
                            <div className="ToggleMainWrap">
                                <CommonToggle 
                                    valueToggle={ApplicationState?.Status}
                                    setValueToggle={() => setApplicationState({...ApplicationState, Status: !ApplicationState?.Status})}
                                    name={""}
                                />
                                {ApplicationState?.Status ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>}
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Button className="formbtn widthfullbtn" onClick={() => CreateBrand()} variant="primary" >
                            {LocationRoute === "edit-franchise-type-report" ? "Update" : "Add"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default AddFranchiseType;