 // eslint-disable-next-line
 /* eslint-disable */
 import React from 'react'
import Logo from "../../assets/img/logo.png";
import moment from 'moment';
import ConvertAmountIntoWords from './ConvertAmountIntoWords';
import { useSelector } from "react-redux";
import AmountNumberFormat from './AmountNumberFormat';
import { useLocation } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

export default function OrderInvoice(props) {
    const { OrderReportDetails } = props;
    const Location = useLocation();
    const { selfInfo } = useSelector((state) => state.adminReducers);
    // function getGstAmt(price, gstRate) {
    //     const priceGST = getGstFreeAmt(price, gstRate)
    //     return parseFloat(parseFloat(price) - parseFloat(priceGST)).toFixed(2)
    //     // return Math.round(parseFloat(price) * parseFloat(gstRate) ) / 100;
    // }
    //  // eslint-disable-next-line
    // function getCGstAmt(price, gstRate) {
    //     const priceGST = getGstFreeAmt(price, gstRate)
    //     return parseFloat(parseFloat(price) - parseFloat(priceGST)).toFixed(2) / 2
    // }
    // function getGstFreeAmt(price, gstRate) {
    //     return Math.round(parseFloat(price) / (1 + (parseFloat(gstRate) / 100)) * 100) / 100;
    // }
    const OrderItemList = (props) =>{
        const item = props;
         // eslint-disable-next-line
        const { index } = props;
        return(
            <tr  className="ng-star-inserted">
                <td > {item?.hsn_code} </td>
                {/* <td > {parseFloat(item?.sub_total - item?.sub_gst).toFixed(2)} </td> */}
                {OrderReportDetails?.order?.ntr === "Intra" ?(
                    <React.Fragment>
                        <td > 
                            {AmountNumberFormat(parseFloat(item?.sub_total) - (parseFloat(item?.sub_sgst) + parseFloat(item?.sub_cgst)))}
                        </td>
                    </React.Fragment>
                ):(
                    <React.Fragment>
                        <td > 
                            {AmountNumberFormat(parseFloat(item?.sub_total) - parseFloat(item?.sub_gst))}
                        </td>
                    </React.Fragment>
                )}
                {OrderReportDetails?.order?.ntr === "Intra" ?(
                    <React.Fragment>
                        <td > {item?.cgst_rate} % </td>
                        <td > {item?.sub_cgst} </td>
                        <td >  {item?.sgst_rate} %</td>
                        <td > {item?.sub_cgst} </td>
                    </React.Fragment>
                ):(
                    <React.Fragment>
                        <td  colSpan="2 ">  {parseInt(item?.gst_rate)}% </td>
                        <td  colSpan="2 "> {item?.sub_gst} </td>
                    </React.Fragment>
                )}
                <td> {parseFloat(item?.sub_total - item?.sub_gst) + item?.sub_gst} </td>
            </tr>
        )
    }
 
  return (
    <div className="OrderDetailsModal invoice-details-common" id={props?.id} >
        <table className="table border-1 account-table" cellPadding="2">
            <tr >
                <td className=" w-auto bb-0 " colSpan={4}>
                    <div className="d-flex logo align-items-start">
                        <img src={Logo} alt={Logo} className='h-auto' width="180px"/>
                        <div className="text-center ">
                            <span className="tax-invoice ">
                                {(OrderReportDetails?.order?.order_type === "WO" && OrderReportDetails?.order?.billing_pin_data?.state?.id === OrderReportDetails?.order?.warehouse_pin_data?.state?.id) ? "DELIVERY CHALLAN" : "Tax Invoice"}
                            </span>
                        </div>
                        {OrderReportDetails?.order?.invoice?.qr_code && (
                            <img src={"data:image/png;base64," + OrderReportDetails?.order?.invoice?.qr_code} alt="image" width="180px"/>
                        )}
                    </div>
                </td>
            </tr>
            <tr >
                <td  colSpan="3" className="bt-0 bb-0">
                    <hr/>
                </td>
            </tr>
            {(OrderReportDetails?.order?.order_type === "WO" && OrderReportDetails?.order?.billing_pin_data?.state?.id === OrderReportDetails?.order?.warehouse_pin_data?.state?.id) ? (<></>) : (<>
                {Location?.pathname?.split("/")[1] === "pending-for-accounts" ? (<React.Fragment>
                    <tr >
                        <td className="bt-0 bb-0 br-0" style={{ width: "50%" }}> IRN: {OrderReportDetails?.order?.invoice?.irn}</td>
                        <td className="bt-0 bb-0 bl-0"> Franchise ID: {OrderReportDetails?.order?.customer_name}</td>
                    </tr>
                    <tr  >
                        <td className="bt-0 bb-0 br-0" > Ack No: {OrderReportDetails?.order?.invoice?.ack_no} </td>
                        <td className="bt-0 bb-0 bl-0" > Ack Date: {OrderReportDetails?.order?.invoice?.ack_date === null ? "-" : OrderReportDetails?.order?.invoice?.ack_date} </td>
                    </tr>
                </React.Fragment>) : (<React.Fragment>
                    <tr >
                        <td  colSpan="3" className="bt-0 bb-0"> IRN: {OrderReportDetails?.order?.invoice?.irn}</td>
                    </tr>
                    <tr  >
                        <td className="bt-0 bb-0 br-0" > Ack No: {OrderReportDetails?.order?.invoice?.ack_no} </td>
                        <td className="bt-0 bb-0 bl-0 br-0" ></td>
                        <td className="bt-0 bb-0 bl-0" > Ack Date: {OrderReportDetails?.order?.invoice?.ack_date === null ? "-" : OrderReportDetails?.order?.invoice?.ack_date} </td>
                    </tr>
                </React.Fragment>)}
            </>)}
            <tr >
                <td  colSpan="3" className="bb-0">
                    <table className="table border-1 account-table" border="1">
                        <tr >
                            <td className="" style={{overflowWrap:"anywhere"}}>
                                <h5 >
                                    <strong>Seller</strong>
                                </h5>
                                <h2 >
                                    {/* {OrderReportDetails?.order?.warehouse?.warehouse_name} */}
                                    INKODOP TECHNOLOGIES PRIVATE LIMITED - {OrderReportDetails?.order?.warehouse_pin_data?.state?.state_name}
                                </h2>
                                <p>
                                    Seller Address: {OrderReportDetails?.order?.warehouse?.address}, {OrderReportDetails?.order?.warehouse_pin_data?.district?.district_name} - {OrderReportDetails?.order?.warehouse_pin_data?.pin_code?.pin_code}, {OrderReportDetails?.order?.warehouse_pin_data?.state?.state_name}
                                </p>
                                <p>CIN: </p>
                                <p>GSTIN: {OrderReportDetails?.order?.warehouse?.gstin_no}</p>
                                <p><label>State Name: {OrderReportDetails?.order?.warehouse_pin_data?.state?.state_name}</label><label >Code: {OrderReportDetails?.order?.warehouse_pin_data?.pin_code?.pin_code}</label></p>
                                <p> Contact No: {OrderReportDetails?.order?.warehouse?.poc_number} </p>
                                <p> Email Id: {selfInfo?.website_email} </p>
                                <p>Website: {selfInfo?.website_url && selfInfo?.website_url}</p>
                                <hr/>
                                <h5 >
                                    <strong>Consignee (Ship to)</strong>
                                </h5>
                                <h2 >{OrderReportDetails?.order?.customer_name}</h2>
                                <p>
                                    {OrderReportDetails?.order?.address} {OrderReportDetails?.order?.address2}
                                </p>
                                <p>GSTIN: {(OrderReportDetails?.order?.order_type === "FO" && (OrderReportDetails?.order.shipping_id == null || OrderReportDetails?.order.shipping_id  == ""))  ? "" : OrderReportDetails?.order?.gstin_no} </p>
                                <p>
                                    <label>State Name: {OrderReportDetails?.order?.state?.state_name}</label>
                                    <label className="ps-1" >Code: {OrderReportDetails?.order?.pin_code?.pin_code}</label></p>
                                <p> Contact No: {OrderReportDetails?.order?.mobile} </p>
                                <p> Email Id: {OrderReportDetails?.order?.email}</p>
                                <hr/>
                                <h5 >
                                    <strong>Buyer (Bill to)</strong>
                                </h5>
                                <h2 >{OrderReportDetails?.order?.billing?.customer_name}</h2>
                                <p>{OrderReportDetails?.order?.billing?.address}</p>
                                <p>GSTIN: {OrderReportDetails?.order?.billing?.gst_no}</p>
                                <p><label>State Name:
                                        {OrderReportDetails?.order?.billing_pin_data?.state?.state_name}</label>
                                        <label className="ps-1" >Code: {OrderReportDetails?.order?.billing_pin_data?.pin_code?.pin_code}</label></p>
                                <p> Contact No: {OrderReportDetails?.order?.billing?.mobile} </p>
                                <p> Email Id: {OrderReportDetails?.order?.billing?.email} </p>
                            </td>
                            <td  valign="top" >
                                <p>
                                    <strong>{(OrderReportDetails?.order?.order_type === "WO" && OrderReportDetails?.order?.billing_pin_data?.state?.id === OrderReportDetails?.order?.warehouse_pin_data?.state?.id) ? "Challan No." : "Invoice No."}</strong>
                                    {OrderReportDetails?.order?.invoice_code}
                                <br/> </p>
                                {OrderReportDetails?.order?.old_invoice_code !== null && (<p>
                                    <strong>Old Invoice Code.</strong>
                                    {OrderReportDetails?.order?.old_invoice_code}
                                 </p>)}
                                <p>
                                    <strong>Delivery Note </strong>
                                    {OrderReportDetails?.order?.remarks?OrderReportDetails?.order?.remarks:"-"}
                                <br/><br/></p>
                                <p>
                                    <strong>Reference No. &amp; Date.</strong>
                                <br/>{OrderReportDetails?.order?.order_code}<br/></p>
                                <p>
                                    <strong>Buyer's Order No.</strong>
                                <br/>{moment(OrderReportDetails?.order?.dispatch?.created_at).format("ll")} </p>
                                <p>
                                    <strong>Dispatch Doc No.</strong>
                                <br/><br/></p>
                                <p>
                                    <strong>Dispatch From .</strong>
                                <br/>
                                    {OrderReportDetails?.order?.dispatch_pin_data?.district?.district_name} </p>
                                <p>
                                    <strong>Dispatch From Address.</strong><br/>
                                    {OrderReportDetails?.order?.warehouse?.warehouse_name}

                                <br/> {OrderReportDetails?.order?.dispatch?.address} , {OrderReportDetails?.order?.dispatch_pin_data?.state?.state_name},<br/>{OrderReportDetails?.order?.dispatch_pin_data?.district?.district_name}
                                    - {OrderReportDetails?.order?.dispatch_pin_data?.pin_code?.pin_code},<br/>{OrderReportDetails?.order?.dispatch?.mobile},<br/>{OrderReportDetails?.order?.dispatch?.email} </p>
                                <p>
                                    <strong>Dispatched through.</strong>
                                <br/><br/></p>
                            </td>
                            <td  valign="top" className="w-25">
                                <p>
                                    <strong>{(OrderReportDetails?.order?.order_type === "WO" && OrderReportDetails?.order?.billing_pin_data?.state?.id === OrderReportDetails?.order?.warehouse_pin_data?.state?.id) ? "Challan Date" : "Invoice Date"}</strong>
                                <br/>{OrderReportDetails?.order?.invoice_at === null ? "-" : moment(OrderReportDetails?.order?.invoice_at).format("ll")}
                                </p>
                                {OrderReportDetails?.order?.order_type === "AO" && (<p>
                                    <strong>Ecom Order Id</strong>
                                    <br/>{OrderReportDetails?.order?.billing?.ecom_order_id === null ? "-" : OrderReportDetails?.order?.billing?.ecom_order_id}
                                </p>)}
                                <p>
                                    <strong>Mode/Terms of Payment</strong>
                                <br/><br/>
                                </p>
                                <p>
                                    <strong>Other References</strong>
                                <br/><br/>
                                </p>
                                <p>
                                    <strong>Order date</strong>
                                    <br/>{moment(OrderReportDetails?.order?.created_at).format("ll")}
                                </p>
                                <p>
                                    <strong>Delivery Note Date</strong>
                                <br/><br/></p>
                                <p>
                                    <strong>Destination</strong>
                                <br/>{OrderReportDetails?.order?.district?.district_name}</p>
                                <p>
                                    <strong>Shipto Address</strong>
                                <br/>{OrderReportDetails?.order?.customer_name}<br/>{OrderReportDetails?.order?.address} {OrderReportDetails?.order?.address2},<br/> ,{OrderReportDetails?.order?.state?.state_name},<br/>{OrderReportDetails?.order?.district?.district_name} - {OrderReportDetails?.order?.pin_code?.pin_code},<br/>{OrderReportDetails?.order?.email},<br/>{OrderReportDetails?.order?.mobile}
                                </p>
                                <p>
                                    <strong>Terms of Delivery</strong>
                                <br/><br/></p>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr >
                <td  colSpan="6" className="bt-0 bb-0">
                    <table className="table border-1 account-table" border="1" cellPadding="3">
                        <tr >
                            <th  > Sl No. </th>
                            <th  className="w-60"> Description of Goods </th>
                            <th > HSN / SAC </th>
                            <th > UOM </th>
                            <th > Qty </th>
                            <th > Rate ₹ </th>
                            <th > Amount ₹ </th>
                        </tr>
                        {OrderReportDetails?.order_items_list?.map((item, index)=>(
                            <React.Fragment key={index}>
                                <tr  className="ng-star-inserted">
                                    <td > {index + 1} </td>
                                    <td > {item?.product_name} {item?.is_demo && (<Badge bg="success" className='isdemo_flags'>Demo</Badge>)} </td>
                                    <td > {item?.hsn_code} </td>
                                    <td > {item?.sku_type} </td>
                                    <td > {item?.qty} </td>
                                    
                                    {OrderReportDetails?.order?.ntr === "Intra" ?(
                                        <React.Fragment>
                                            <td>{AmountNumberFormat((parseFloat(item?.sub_total) - (parseFloat(item?.sub_sgst) + parseFloat(item?.sub_cgst))) / item?.qty)} </td>
                                            <td > 
                                                {AmountNumberFormat(parseFloat(item?.sub_total) - (parseFloat(item?.sub_sgst) + parseFloat(item?.sub_cgst)))}
                                            </td>
                                        </React.Fragment>
                                    ):(
                                        <React.Fragment>
                                            <td> {AmountNumberFormat((parseFloat(item?.sub_total) - parseFloat(item?.sub_gst)) / item?.qty)} </td>
                                            <td > 
                                                {AmountNumberFormat(parseFloat(item?.sub_total) - parseFloat(item?.sub_gst))}
                                            </td>
                                        </React.Fragment>
                                    )}
                                    
                                </tr>
                            </React.Fragment>
                        ))}
                        <tr >
                            <td ></td>
                            <td > Total </td>
                            <td ></td>
                            <td ></td>
                            <td > {OrderReportDetails?.order?.qty} </td>
                            <td ></td>
                            {OrderReportDetails?.order?.ntr === "Intra" ?(
                                <React.Fragment>
                                    <td  > ₹{AmountNumberFormat(parseFloat(OrderReportDetails?.order?.total) - (parseFloat(OrderReportDetails?.order?.total_cgst) + parseFloat(OrderReportDetails?.order?.total_sgst)))} </td>
                                </React.Fragment>
                            ):(
                                <React.Fragment>
                                    <td  > ₹{AmountNumberFormat(parseFloat(OrderReportDetails?.order?.total) - parseFloat(OrderReportDetails?.order?.total_gst))} </td>
                                </React.Fragment>
                            )}
                            
                        </tr>
                        <tr >
                            <td  colSpan="6 " > Amount Chargeable (in words): <span className="text-capitalize p-0"> 
                                {ConvertAmountIntoWords(OrderReportDetails?.order?.ntr === "Intra" ? (parseFloat(OrderReportDetails?.order?.total) - (parseFloat(OrderReportDetails?.order?.total_cgst) + parseFloat(OrderReportDetails?.order?.total_sgst))) : (parseFloat(OrderReportDetails?.order?.total) - parseFloat(OrderReportDetails?.order?.total_gst)))} 
                            </span> </td>
                            <td  colSpan="4 " align="right "> E. &amp; O.E </td>
                        </tr>
                    </table>
                </td>
                </tr>
                <tr>
                <td  colSpan="6" className="bt-0 bb-0">

                    <table  border="1" cellPadding="3 "className="ng-star-inserted w-100 table border-1 account-table">
                        <tr >
                            <th  rowSpan="2 "> HSN/SAC </th>
                            <th  rowSpan="2 ">Taxable Value</th>
                            {OrderReportDetails?.order?.ntr === "Intra" ?(
                                <React.Fragment>
                                    <th  colSpan="2 ">CGST Tax </th>
                                    <th  colSpan="2 "> SGST Tax </th>
                                </React.Fragment>
                            ):(
                                <React.Fragment>
                                    <th  colSpan="4 ">IGST Tax </th>
                                </React.Fragment>
                            )}
                            
                            <th  rowSpan="2 ">Total Amount
                            </th>
                        </tr>
                        {OrderReportDetails?.order?.ntr === "Intra" ?(
                            <tr >
                                <th > Rate </th>
                                <th >Amount</th>
                                <th > Rate </th>
                                <th >Amount</th>
                            </tr>
                        ):(
                            <tr>
                                 <th colSpan="2 " > Rate </th>
                                <th colSpan="2 " >Amount</th>
                            </tr>
                        )}
                        {OrderReportDetails?.order_items_list?.map((item, index)=>(
                            <React.Fragment key={index}>
                                <OrderItemList {...item} index={index} />
                            </React.Fragment>
                        ))}
                        <tr >
                            <td >Total</td>
                            {OrderReportDetails?.order?.ntr === "Intra" ?(
                                <React.Fragment>
                                    <td  > ₹{AmountNumberFormat(parseFloat(OrderReportDetails?.order?.total) - (parseFloat(OrderReportDetails?.order?.total_cgst) + parseFloat(OrderReportDetails?.order?.total_sgst)))} </td>
                                </React.Fragment>
                            ):(
                                <React.Fragment>
                                    <td  > ₹{AmountNumberFormat(parseFloat(OrderReportDetails?.order?.total) - parseFloat(OrderReportDetails?.order?.total_gst))} </td>
                                </React.Fragment>
                            )}
                            {OrderReportDetails?.order?.ntr === "Intra" ?(
                                <React.Fragment>
                                    <td ></td>
                                    <td > {OrderReportDetails?.order?.total_cgst} </td>
                                    <td ></td>
                                    <td >{OrderReportDetails?.order?.total_sgst}</td>
                                </React.Fragment>
                            ):(
                                <React.Fragment>
                                    <td  colSpan="2 "></td>
                                    <td  colSpan="2 "> {OrderReportDetails?.order?.total_gst} </td>
                                </React.Fragment>
                            )}
                            <td>{OrderReportDetails?.order?.total}</td>
                        </tr>
                        <tr >
                            <td  colSpan="7 ">Tax Amount (in words) : <span className="text-capitalize p-0"> {ConvertAmountIntoWords(
                               OrderReportDetails?.order?.ntr === "Intra" ?  parseFloat(OrderReportDetails?.order?.total_cgst + OrderReportDetails?.order?.total_sgst).toFixed(2) : 
                                OrderReportDetails?.order?.total_gst
                                )} </span> </td>
                        </tr>
                        <tr >
                            <td  colSpan="5 " >
                                {(OrderReportDetails?.order?.order_type === "WO" && OrderReportDetails?.order?.billing_pin_data?.state?.id === OrderReportDetails?.order?.warehouse_pin_data?.state?.id) ?  "Total Amount " : "Total Invoice Amount "}
                            </td>
                            <td  colSpan="2 ">
                                <strong style={{ padding: "0px" }}>
                                ₹<span className="p-0" > {AmountNumberFormat(OrderReportDetails?.order?.total)}</span>
                                </strong>
                            </td>
                        </tr>
                        <tr >
                            <td  colSpan="7 ">{(OrderReportDetails?.order?.order_type === "WO" && OrderReportDetails?.order?.billing_pin_data?.state?.id === OrderReportDetails?.order?.warehouse_pin_data?.state?.id) ? "Total Amount" : "Total Invoice Amount"} (in words) : <span className="text-capitalize p-0"> {ConvertAmountIntoWords(OrderReportDetails?.order?.total)} </span>  </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr >
                <td  colSpan="3 " className="bt-0">
                    <table  cellPadding="3 " border="1" className="w-100 table border-1 account-table">
                        <tr >
                            <td  valign="top " className="w-50">
                                <p><strong >Declaration </strong>
                            </p>
                                <p>
                                    We declare that this {(OrderReportDetails?.order?.order_type === "WO" && OrderReportDetails?.order?.billing_pin_data?.state?.id === OrderReportDetails?.order?.warehouse_pin_data?.state?.id) ? "challan" : "invoice"} shows the actual price of the goods
                                    described and that all particulars are true and correct.
                                </p>
                            </td>
                            <td  valign="top " className="w-50">
                                <p><strong >for INKODOP TECHNOLOGIES PRIVATE LIMITED - {OrderReportDetails?.order?.warehouse_pin_data?.state?.state_name}
                                    </strong>
                                    </p>
                                <p>Authorised Signatory
                                </p>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>
        <p> {(OrderReportDetails?.order?.order_type === "WO" && OrderReportDetails?.order?.billing_pin_data?.state?.id === OrderReportDetails?.order?.warehouse_pin_data?.state?.id) ? "This is a computer generated challan, hence doesn’t require physical signature." : "This is a computer generated invoice, hence doesn’t require physical signature."}</p>
    </div>
  )
}
