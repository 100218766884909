/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Badge } from 'react-bootstrap';
import DataTableFilter from "../../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useNavigate, Link } from "react-router-dom";
import { CREATE_WAREHOUSE_URL } from "../../Shared/constant";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../../Common/CommonPagination";
import { WebSocketContext } from "../../../App";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";

const Warehouse = (props) => {
    const { RouteName } = props;
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const { wareHouseList, device_id, citylistall, statelistall, selfInfo } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const [ userSearch, setUserSearch ] = useState("");
    const [ WHType, setWHType ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ showModal, setShowModal ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    const optionWarehouseType = [
        {key:"E-Bikes, Transportation Charges", value:"E-Bikes, Transportation Charges",label:"E-Bikes"},
        {key:"Spares, Service, Transportation Charges", value:"Spares, Service, Transportation Charges",label:"Spares"},
        {key:"Accessories, Transportation Charges", value:"Accessories, Transportation Charges",label:"Accessories"},
        {key:"Marketing, Transportation charges", value:"Marketing, Transportation charges",label:"Marketing"}
    ];

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    
    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // select warehouse type
    const SelectsetWHType = (e) => {
        setWHType(e.target.value);
    };

    // get all city list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Warehouse[0]?.Edit || PermissionsAccess?.Warehouse[0]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "district_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get all state list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Warehouse[0]?.Edit || PermissionsAccess?.Warehouse[0]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "state_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get product list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Warehouse[0]?.Edit || PermissionsAccess?.Warehouse[0]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "region_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Warehouse[0]?.Edit || PermissionsAccess?.Warehouse[0]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "warehouse_list",
                "request" : { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "wh_type" : WHType,
                    "search" : userSearch.trim()
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };
    
    // get product list
    useEffect(() => {
       APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit, WHType ]);

    // create applications
    const cmnFnct = () => {
        Navigate(CREATE_WAREHOUSE_URL);
    };

    // get pin name
    const GetPinCode = (pin_code_id) => {
        return wareHouseList?.pin_code?.filter((elm) => pin_code_id === elm?.id)[0]?.pin_code;
    };

    // get city name
    const GetCityName = (pin_code_id) => {
        const CurrentPincode = wareHouseList?.pin_code?.filter((elm) => pin_code_id === elm?.id)[0];
        return citylistall?.filter((elm) => CurrentPincode?.district_id === elm?.id)[0]?.district_name;
    };

    // get state name
    const GetStateName = (pin_code_id) => {
        const CurrentPincode = wareHouseList?.pin_code?.filter((elm) => pin_code_id === elm?.id)[0];
        const CurrentCity =  citylistall?.filter((elm) => CurrentPincode?.district_id === elm?.id)[0];
        return statelistall?.filter((elm) => CurrentCity?.state_id === elm?.id)[0]?.state_name;
    };

    // get warehouse types name
    const GetWareHouseTypeName = (warehouse_type) => {
        const CurrentWarehosueTypes = optionWarehouseType?.filter((elm) => elm?.value === warehouse_type)[0];
        return CurrentWarehosueTypes?.label === undefined ? "-" : CurrentWarehosueTypes?.label;
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);


    return(<section className="Main_section Main_section_warehouse">
        <DataTableFilter 
            filterType={"Warehouse"}
            searchType={"Warehouse Name"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            WHType={WHType}
            setWHType={setWHType}
            SelectsetWHType={SelectsetWHType}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Warehouse[0]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Warehouse",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={true}
        />
        <div className="tableView gstreporttableadded">
            <Scrollbars 
                style={{ height: wareHouseList?.pagination?.total_records > 10 ? "calc(100vh - 185px)" : "calc(100vh - 150px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>Code</th>
                            <th>Name</th>
                            <th>ERP ID</th>
                            <th>Category</th>
                            <th>PIN code</th>
                            <th>City</th>
                            <th>State</th>
                            <th>Status</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Warehouse[0]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {wareHouseList?.list?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, wareHouseList?.pagination?.current_page, wareHouseList?.pagination?.record_limit)}
                                </td>
                                <td>{elm?.warehouse_code}</td>
                                <td>{elm?.warehouse_name}</td>
                                <td>{(elm?.erp_id === null || elm?.erp_id === "") ? "-" : elm?.erp_id}</td>
                                <td>{GetWareHouseTypeName(elm?.warehouse_type)}</td>
                                <td>{GetPinCode(elm?.pin_code_id)}</td>
                                <td>{GetCityName(elm?.pin_code_id)}</td>
                                <td>{GetStateName(elm?.pin_code_id)}</td>
                                <td>{elm?.is_active ? (<Badge bg="success">Active</Badge>) : (<Badge bg="danger">Inactive</Badge>)}</td>
                                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Warehouse[0]?.Edit) && (<td>
                                    <Link className="btn edittable" to={`/edit-warehouse/${elm?.id}`}>
                                        <i className="bi bi-pencil-square"></i>
                                    </Link>
                                </td>)}
                            </tr>)
                        })}
                        {wareHouseList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="10">WareHouse list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {wareHouseList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={wareHouseList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
    </section>)
}

export default Warehouse;