/* eslint-disable */
import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import OrderInvoice from './OrderInvoice';
import { useDispatch, useSelector } from 'react-redux';
import { setStoreModalInvoiceDetails } from '../../redux/actions/adminActions';
import { printContent } from './TimeSense';

export default function ShowInvoiceModal() {
    const { ModalInvoice } = useSelector((state) => state.adminReducers);
    const dispatch = useDispatch();
    useEffect(()=>{
        close()
    },[]);
    const close  = () =>{
        dispatch(setStoreModalInvoiceDetails(null))
    }
    if(!ModalInvoice){
        return <React.Fragment/>
    }
  return (
    <Modal className='modal-invoice' size='lg' show={true} onHide={close}>
        <Modal.Header closeButton>
          <button onClick={()=>printContent('invoice_model')} className='btn btn-icon p-1'>
            <i className="bi bi-printer"></i>
          </button>
        </Modal.Header>
      <Modal.Body id='invoice_model'>
        {ModalInvoice && <OrderInvoice OrderReportDetails={ModalInvoice}/>}
      </Modal.Body>
    </Modal>
  )
}
