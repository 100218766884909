import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Table } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Select from 'react-select';
// import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../../Api/ws/ws_request";
import { WebSocketContext } from "../../../App";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import { addFranchiseBrandList, removeFranchiseBrandList } from "../../../redux/actions/adminActions";

const CreateFranchiseBrand = (props) => {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const { websocket } = useContext(WebSocketContext);
    // const LocationRoute = useLocation()?.pathname;
    const { device_id, AllDealerFranchiseTypeListAll, franchiseBrandListAll } = useSelector((state) => state.adminReducers);
    const [ApplicationState, setApplicationState] = useState({
        FranchiseName: "",
        ParentName: ""
    });

    const [optionsState, setOptionsState] = useState([])

    useEffect(() => {
        setOptionsState(AllDealerFranchiseTypeListAll?.list?.filter((elm) =>
            elm?.is_active === true &&
            elm?.parent_id === null &&
            !franchiseBrandListAll?.list.some((item) => item.label === `${elm?.franchise_code} - ${elm?.customer_name}`)
        )?.map((item) => ({
            key: item.id,
            value: item?.id,
            label: `${item?.franchise_code} - ${item?.customer_name}`
        })));
    }, [AllDealerFranchiseTypeListAll, franchiseBrandListAll]);

    useEffect(() => {
        let param = {
            "transmit": "broadcast",
            "url": "franchise_list",
            "request": {
                "status": "",
                "limit": 5000,
                "page_no": 1,
                "search": ""
            },
            "DeviceId": device_id
        };
        wsSend_request(websocket, param);

    }, [websocket, device_id]);

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px',
        }),
    };

    const removeDropdown = (value) => {
        dispatch(removeFranchiseBrandList(value))
    };

    const handleDropdownChange = (e, index) => {
        setApplicationState({ ...ApplicationState, FranchiseName: e })
    }

    const addDropdown = () => {
        if (ApplicationState.FranchiseName && !franchiseBrandListAll?.list.includes(ApplicationState.FranchiseName.label)) {
            dispatch(addFranchiseBrandList(ApplicationState.FranchiseName))
            setApplicationState({ ...ApplicationState, FranchiseName: "" });
        }
    };

    return (
        <section className="Main_section">
            <div className="formloginset">
                <div className="row justify-content-center">
                    <div className="col-md-4">
                        <Form.Group  >
                            <Form.Label>Parent Name<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                value={ApplicationState?.ParentName}
                                onChange={(e) => setApplicationState({ ...ApplicationState, ParentName: e.target.value })}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Parent Name"
                            />
                        </Form.Group>
                    </div>
                    <div className="col-md-4 ">
                        <Form.Group >
                            <Form.Label>Franchise Name<span className="mendatory_star">*</span></Form.Label>
                            <Form.Group >
                                <div className="selectMultiselect" id="selectMultiselectBrand">
                                    <Select
                                        value={ApplicationState?.FranchiseName}
                                        onChange={(e) => handleDropdownChange(e)}
                                        isClearable
                                        options={optionsState}
                                        className="godown-select-container"
                                        classNamePrefix="godown-select"
                                        styles={customStyles}
                                        placeholder="Select Franchise"
                                    />
                                </div>
                            </Form.Group>
                        </Form.Group>
                    </div>
                    <div className="col-md-1 mb-5 ">
                        <Form.Group className="add-icon-francise-brand">
                            <Button className="p-0" style={{ height: "38px" }} onClick={addDropdown}>
                                <i className="bi bi-plus AddItemBtn" style={{ height: "20px", width: "20px" }}></i>
                            </Button>
                        </Form.Group>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-9">
                        <div className="tableView">
                            <Scrollbars
                                style={{ height: "250px" }}
                                renderView={props => <div {...props} className="view" />}
                                className="ScrollbarsSidebar"
                            >
                                {franchiseBrandListAll?.list.length > 0 && (
                                    <div>
                                        <Table bordered >
                                            <thead>
                                                <tr>
                                                    <th>Sr no</th>
                                                    <th>Franchise Name</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {franchiseBrandListAll?.list?.map((value, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {GetCurrentTableSRNo(index, 1, 10)}
                                                        </td>
                                                        <td>{value?.label}</td>
                                                        <td>
                                                            <i className="bi bi-trash3 deleteItemBtn" onClick={() => removeDropdown( value?.value)}></i>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                )}
                            </Scrollbars>

                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mt-4">
                    <div className="col-2">
                        <Form.Group className="formbtn">
                            <Button className="p-0 " >
                                Submit
                            </Button>
                        </Form.Group>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CreateFranchiseBrand;
