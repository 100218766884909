/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Form, Button } from 'react-bootstrap';
import { useSelector, useDispatch,dispatch } from "react-redux";
import { WebSocketContext } from "../../../App.js";
import wsSend_request from "../../../Api/ws/ws_request.js";
import Select from 'react-select'
import CheckValid from "../../Common/CheckValid.jsx";
import  {setGetMagicCode} from "../../../redux/actions/adminActions.js"
import getMagiccodeAPI from '../../../Api/getMagiccodeAPI.jsx';

const RmQuickLogin = (props) => {
    const { RouteName } = props;
    const [ userSearch, setUserSearch ] = useState("");
    const { websocket } = useContext(WebSocketContext);
    const { userlistAll, device_id, accessToken, selfInfo } = useSelector((state) => state.adminReducers);
    const [ ApplicationState, setApplicationState ] = useState({
        userCode:""
    });
    const [ errorState, seterrorState ] = useState("");
    const dispatch = useDispatch();
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    // get product list
    const [ optionsState, SetOptionsState ] = useState([]);

    useEffect(() => {
        if(userlistAll !== undefined) {
            SetOptionsState(userlistAll?.filter((elm) => elm?.is_active === true && (elm?.user_type === "regional_manager" || elm?.user_type === "regional_sales_team_rm" || elm?.user_type === "regional_sales_team_asm") )?.map((item, index)=>({key:item.id, value:item?.id , label:`${item?.user_code} - ${item?.full_name} - (${item?.user_type === "regional_sales_team_asm" ? "ASM" : "RM"})` })));
        };
    }, [userlistAll]);

    useEffect(() => {
        let param = { 
            "transmit": "broadcast", 
            "url": "user_list",
            "request" : { 
                "status" : "", 
                "limit" : 5000,
                "page_no" : 1, 
                "search" : ""
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    }, []);

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px',
        }),
    };

    const RmValueSelect = (value) => {
        if(value !== null) {
            setApplicationState({...ApplicationState, userCode: value});
            CheckValid(value, {type: 'StateName', error: seterrorState});
        } else {
            setApplicationState({...ApplicationState, userCode: ""});
            CheckValid(value === null ? "" : value, {type: 'StateName', error: seterrorState});
        }
    };
    
    const  getMagicToken =async (value)=>{
        const rmUserCode = value.split(" - ")[0];
        rmUserCode.trim()
        if(value !== undefined ){
            const payload = {
                device_type:"web",
                device_id: device_id
            }
            const response = await getMagiccodeAPI(accessToken, payload);
            if(response?.status === 200){
                dispatch(setGetMagicCode(response?.data));
                setTimeout(() => {
                    const url = `${process.env.REACT_APP_RM_URL}/rm-login/${rmUserCode}/${response?.data}/${response?.device_id}/web`;
                    window.open(url, '_blank');
                }, 1000);
            }
        }
    }

    return(<section className="Main_section">
        <div className="formloginset">
            <div className="row">
                <div className="col-md-4">
                    <Form.Group className="mb-3 " >
                        <Form.Label>RM Code<span className="mendatory_star">*</span></Form.Label>
                        {/* <div className="col-md-6"> */}
                        <Form.Group className="mb-3" >
                            <div className="selectMultiselect" id="selectMultiselectBrand">
                                <Select 
                                    value={ApplicationState?.userCode}
                                    onChange={(e) => RmValueSelect(e)} 
                                    // defaultValue={defaultCategoryOption} 
                                    isClearable 
                                    options={optionsState}
                                    className="godown-select-container" 
                                    classNamePrefix="godown-select"
                                    styles={customStyles}
                                    placeholder="Select RM User"
                                />
                                {/* {errorState !== "" && <span className="error_medotory">{errorState}</span>} */}
                            </div>
                        </Form.Group>
                    {/* </div> */}
                    </Form.Group>
                </div>
                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.AdminTools[3]?.Edit) && (<div className="col-md-1">
                    <Form.Group className="mb-3 " >
                        <Form.Label>&nbsp;</Form.Label>
                        <Button id="whloginhbtn" className="whloginhbtn" onClick={() => getMagicToken(ApplicationState.userCode?.label)} disabled={!ApplicationState?.userCode}>
                            Login
                        </Button>
                    </Form.Group>
                </div>)}
            </div>
        </div>
    </section>)
}

export default RmQuickLogin;