/* eslint-disable react-hooks/rules-of-hooks */
import BrowserUniqueTabID from "../../components/Common/BrowserUniqueTabID";

const wsSend_request = (websocket, params)=>{
    // var tabID = sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = Math.random();
    const Updateparams = {
        ...params,
        TabId: BrowserUniqueTabID(),
    };
    if(websocket.readyState === websocket.OPEN ){
        if(Updateparams?.request !== undefined){
            setTimeout(() => {
                websocket && websocket?.send(JSON.stringify(Updateparams))
            }, 200);
        } else {
            websocket && websocket?.send(JSON.stringify(Updateparams))
        };
    }else{
        setTimeout(()=>{
            wsSend_request(websocket, Updateparams)
        },1500)
    };
};

export default wsSend_request;