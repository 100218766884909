import { GET_MAGIC_CODE_API_URL } from "../components/Shared/constant";

const getMagiccodeAPI = async (accessToken, payload) => {
    const responce = window.axios.post(GET_MAGIC_CODE_API_URL, payload, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization':`Bearer ${accessToken}`
          },
      }).then(function (result) {
          return {
            ...result.data,
            device_id: payload?.device_id,
            status:200
          };
      }).catch(function (result) {
          return {
            ...result?.response?.data,
            status:result?.response?.status
          }
      });
      return responce;
};
  
export default getMagiccodeAPI;