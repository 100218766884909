/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Badge, Collapse, Image } from 'react-bootstrap';
import DataTableFilter from "../../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useNavigate, Link } from "react-router-dom";
import { CREATE_DISPATCH_LIST_URL } from "../../Shared/constant";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../../Common/CommonPagination";
import { WebSocketContext } from "../../../App";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import MoreIcon from '../../../assets/img/more_options.png';
import CommonDateTime from "../../Common/CommonDateTime";

const DispatchList = (props) => {
    const { RouteName } = props;
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const { DispatchLists, device_id, citylistall, statelistall, selfInfo } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };
    
    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // get all city list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Warehouse[2]?.Edit || PermissionsAccess?.Warehouse[2]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "district_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get all state list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Warehouse[2]?.Edit || PermissionsAccess?.Warehouse[2]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "state_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // get region list
    useEffect(() => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Warehouse[2]?.Edit || PermissionsAccess?.Warehouse[2]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "region_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }, []);

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Warehouse[2]?.Edit || PermissionsAccess?.Warehouse[2]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "dispatch_list",
                "request" : { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch.trim()
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };
    
    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    // create applications
    const cmnFnct = () => {
        Navigate(CREATE_DISPATCH_LIST_URL);
    };

    // get pin name
    const GetPinCode = (pin_code_id) => {
        return DispatchLists?.pin_code?.filter((elm) => pin_code_id === elm?.id)[0]?.pin_code;
    };

    // get city name
    const GetCityName = (pin_code_id) => {
        const CurrentPincode = DispatchLists?.pin_code?.filter((elm) => pin_code_id === elm?.id)[0];
        return citylistall?.filter((elm) => CurrentPincode?.district_id === elm?.id)[0]?.district_name;
    };

    // get state name
    const GetStateName = (pin_code_id) => {
        const CurrentPincode = DispatchLists?.pin_code?.filter((elm) => pin_code_id === elm?.id)[0];
        const CurrentCity =  citylistall?.filter((elm) => CurrentPincode?.district_id === elm?.id)[0];
        return statelistall?.filter((elm) => CurrentCity?.state_id === elm?.id)[0]?.state_name;
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={"Dispatch"}
            searchType={"Dispatch Name"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Warehouse[2]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Dispatch",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={true}
        />
        <div className="tableView">
            <Scrollbars 
                style={{ height: DispatchLists?.pagination?.total_records > 10 ? "calc(100vh - 185px)" : "calc(100vh - 150px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th></th>
                            <th>SNO</th>
                            <th>Dispatch Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>PIN code</th>
                            <th>Warehouse</th>
                            <th>Status</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Warehouse[2]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {DispatchLists?.list?.map((elm, index) => {
                            const Warehouse = DispatchLists?.warehouses?.find((item)=>item?.id == elm.warehouse_id)
                            return(<React.Fragment key={index}>
                            <tr>
                                <td style={{ width: '10px' }}>
                                    <button 
                                        className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                        onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={activeTableDetails === elm?.id ? true : false}
                                    >
                                        <Image src={MoreIcon} alt="more icon" />
                                    </button>
                                </td>
                                <td>
                                    {GetCurrentTableSRNo(index, DispatchLists?.pagination?.current_page, DispatchLists?.pagination?.record_limit)}
                                </td>
                                <td>{elm?.dispatch_name}</td>
                                <td>{elm?.email === null ? "-" : elm?.email}</td>
                                <td>{elm?.mobile}</td>
                                <td>{GetPinCode(elm?.pin_code_id)}</td>
                                <td>{!Warehouse?.warehouse_name ? "-" : Warehouse?.warehouse_name}</td>
                                <td>{elm?.is_active ? (<Badge bg="success">Active</Badge>) : (<Badge bg="danger">Inactive</Badge>)}</td>
                                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Warehouse[2]?.Edit) && (<td>
                                    <Link className="btn edittable" to={`/edit-dispatch/${elm?.id}`}>
                                        <i className="bi bi-pencil-square"></i>
                                    </Link>
                                </td>)}
                            </tr>
                            <Collapse in={activeTableDetails === elm?.id ? true : false}>
                                <tr className="tablecollapsewraps" id={"tablecollpase"+elm?.id}>
                                    <td colSpan="9" >
                                        <Table bordered  className="table">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "20%" }}>GST Number</th>
                                                    <td>{elm?.gst_no === null ? "-" : elm?.gst_no}</td>
                                                </tr>
                                                <tr>  
                                                    <th style={{ width: "20%" }}>City</th>
                                                    <td>{GetCityName(elm?.pin_code_id)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>State</th>
                                                    <td>{GetStateName(elm?.pin_code_id)}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Address</th>
                                                    <td>{elm?.address === null ? "-" : elm?.address}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ width: "20%" }}>Created At</th>
                                                    <td>{CommonDateTime(elm.created_at, "LL", "show")}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                            </Collapse>
                        </React.Fragment>)
                        })}
                        {DispatchLists?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="9">Dispatch list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {DispatchLists?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={DispatchLists?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
    </section>)
}

export default DispatchList;