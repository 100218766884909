/* eslint-disable */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import GetRequest from './Api/GetRequest';
import { GET_MAGIC_CODE_API_URL } from './components/Shared/constant';
// import PostRequestCall from './Api/PostRequest';
import getMagiccodeAPI from './Api/getMagiccodeAPI';
import { setGetMagicCode } from './redux/actions/adminActions';

export default function PageLoad({state}) {
  const { accessToken, device_id, magic_code } = useSelector((state) => state.adminReducers);
  const dispatch = useDispatch();

    useEffect(()=>{
        if(accessToken && magic_code == ""){
            getMagicCode();
        }
    },[accessToken, magic_code == ""]);

    const getMagicCode = async () =>{
        const payload = {
            device_type:"web",
            device_id: localStorage.getItem("device_id")
        }
        // const response = await PostRequestCall(GET_MAGIC_CODE_API_URL, payload, accessToken, true);
        const response = await getMagiccodeAPI(accessToken, payload);
        if(response?.status === 200){
            dispatch(setGetMagicCode(response?.data));
            setTimeout(()=>{
                window.location.replace(process.env.REACT_APP_BASE_URL+`user/set_login/${response?.data}/${response?.device_id}/web/${location?.host}`)
            },[1000])
        };
    }
  return (
    <div>
      
    </div>
  )
}
