import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";

const AuthLayout = ({children}) => {
    const [divWidth, setDivWidth] = useState(null);

    return(<section className="adminmain">
        <Header sidebarWidth={divWidth} />
        <Sidebar setDivWidth={setDivWidth} />
        <div className={"panelwrapper active"}>
            <div className="panelContentwrapper">
                {children}
            </div>
        </div>
    </section>)
}

export default AuthLayout;