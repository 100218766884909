import { SEND_REFURBISHED_PDF_API_URL } from "../components/Shared/constant";

const SendRefurbishedPdf = async (payload) => {
    const responce = window.axios.post(SEND_REFURBISHED_PDF_API_URL, payload, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
      }).then(function (result) {
          return {
            ...result.data,
            status:200
          };
      }).catch(function (result) {
          return {
            ...result?.response?.data,
            status:result?.response?.status
          }
      });
      return responce;
};
  
export default SendRefurbishedPdf;