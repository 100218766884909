/* eslint-disable */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccessToken, getDeviceId, setGetMagicCode,getUserId ,getSelfDetails, clearRedux, checkLogin} from "../redux/actions/adminActions";
import { useNavigate,useParams } from "react-router-dom";
import { LOGIN_URL, RM_LOGIN_API_URL } from "./Shared/constant";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getMagiccodeAPI from "../Api/getMagiccodeAPI";
import { Spinner } from "react-bootstrap";

const RmLogin =() => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();

    const{usercode,magiccode,deviceid,web} = useParams()
    const payload = JSON.stringify({user_code:usercode,magic_code:magiccode,device_type:web,device_id:deviceid });
    useEffect(() => {
        window.axios.post(RM_LOGIN_API_URL, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (result) {
            toast.success(result?.data.msg);
            Dispatch(getSelfDetails(result?.data?.data));
            toast.success(result.msg);
            const payloadMagic = {
                device_id: deviceid,
                device_type:"web"
            }
            setTimeout(async () => {
                const response2 = await getMagiccodeAPI(result?.data?.data?.access_token, payloadMagic);
                if(response2?.status === 200){
                    Dispatch(setGetMagicCode(response2?.data));
                    Dispatch(getDeviceId(deviceid));
                    Dispatch(getUserId(result?.data?.data?.user?.id));
                    localStorage.setItem("access_token", result?.data?.data?.access_token);
                    localStorage.setItem("device_id",  result?.data?.data?.device_id);
                    // setLoaderStart(false);
                    
                    setTimeout(() => {
                        window.location.replace(process.env.REACT_APP_BASE_URL+`user/set_login/${response2?.data}/${response2?.device_id}/web/${location?.host}`);
                    }, 500);
                };
            }, 200);
        }).catch(function (error) {
            toast.error(error?.response?.data?.msg);
            setTimeout(() => {
                Navigate(LOGIN_URL);
                Dispatch(clearRedux());
                localStorage.clear();
                Dispatch(checkLogin('no'));
            }, 200);
        });
    }, [usercode]);

    return(<div>
        <div className="commonLoaderwaraplogin">
            <Spinner />
            <span>Please Wait...</span>
        </div>
    </div>)
}

export default RmLogin;