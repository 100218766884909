/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Badge, Button, Form } from 'react-bootstrap';
import CommonToggle from "../../Common/CommonToggle";
import { Link, useNavigate, useLocation, useParams  } from "react-router-dom";
import { PRODUCT_GROUP_URL } from "../../Shared/constant";
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";

const CreateProductGroup = () => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const { websocket } = useContext(WebSocketContext);
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ ApplicationState, setApplicationState ] = useState({
        ProductGroupName: "",
        Status: true
    });
    const { roomId } = useParams();
    const { accessToken, ClearFormSet, ProductGroupList, device_id } = useSelector((state) => state.adminReducers);
    const CurrentData_ = ProductGroupList?.list?.filter((elm) => elm?.id === roomId)[0];

    // error state
    const [ errorProductGroupName, seterrorProductGroupName ] = useState("");

    useEffect(() => {
        if(LocationRoute === "edit-product-group") {
            setApplicationState({
                ...ApplicationState,
                ProductGroupName: CurrentData_?.product_group_name,
                Status: CurrentData_?.is_active
            });
        };
    }, [ CurrentData_ ])

    // create and edit brand
    const CreateBrand = () => {
        if(ApplicationState?.ProductGroupName.trim() !== "") {
            if(LocationRoute === "edit-product-group") {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "product_group_edit",
                    "request" : { 
                        "product_group_id" : CurrentData_?.id,
                        "product_group_name" : ApplicationState?.ProductGroupName.trim(), 
                        "is_active" : ApplicationState?.Status
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            } else {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "product_group_add",
                    "request" : { 
                        "product_group_name" : ApplicationState?.ProductGroupName.trim(), 
                        "is_active" : ApplicationState?.Status
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            }
        } else {
            CheckValid(ApplicationState?.ProductGroupName.trim(), {type: 'ProductGroupName', error: seterrorProductGroupName});
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            Navigate(PRODUCT_GROUP_URL);
        };
    }, [ ClearFormSet ]);

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={PRODUCT_GROUP_URL} className="btn btn-link backbtn"><i className="bi bi-arrow-left-short"></i> Back</Link>
        </div>
        <div className="tabsWrappers">
            <div className="formloginset">
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Product Group Name<span className="mendatory_star">*</span></Form.Label>
                            <Form.Control 
                                type="text" 
                                value={ApplicationState?.ProductGroupName} 
                                onChange={(e) => setApplicationState({...ApplicationState, ProductGroupName: e.target.value})}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: 'ProductGroupName', error: seterrorProductGroupName})}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Product Group Name" 
                                disabled={UploadFileLoader}
                            />
                            {errorProductGroupName !== "" &&<span className="error_medotory">{errorProductGroupName}</span>}
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group className="mb-3" >
                            <Form.Label>Status</Form.Label>
                            <div className="ToggleMainWrap">
                                <CommonToggle 
                                    valueToggle={ApplicationState?.Status}
                                    setValueToggle={() => setApplicationState({...ApplicationState, Status: !ApplicationState?.Status})}
                                    name={""}
                                />
                                {ApplicationState?.Status ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>}
                            </div>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Button className="formbtn widthfullbtn" onClick={() => CreateBrand()} variant="primary" >
                        {LocationRoute === "edit-product-group" ? "Update" : "Add"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default CreateProductGroup;