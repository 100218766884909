/* eslint-disable */
import React, { useContext, useEffect } from "react";
import { WebSocketContext } from "../../App";
import { Modal, Button } from "react-bootstrap";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";

const DeleteModal = (props) => {
    const Dispatch = useDispatch();
    const { deleteCommonStateModal, setdeleteCommonStateModal } = props;
    const { device_id, ClearFormSet } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);

    // delete modal function
    const deletemodal = () => {
        if(deleteCommonStateModal?.delete_url === "Delete-Point") {
            let param = { 
                "transmit": "broadcast", 
                "url": "refurbished_point_value_delete",
                "request" : { 
                    "refurbished_point_value_id" : deleteCommonStateModal?.delete_id,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        } else if(deleteCommonStateModal?.delete_url === "Age-Value") {
            let param = { 
                "transmit": "broadcast", 
                "url": "refurbished_age_delete",
                "request" : { 
                    "refurbished_age_id" : deleteCommonStateModal?.delete_id,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
            OnSuccesListCall("refurbished_age_list")
        };
    };

    const OnSuccesListCall = (url) => {
        if (url === "refurbished_age_list") {
            let param = {
                "transmit": "broadcast",
                "url": url,
                "request": {
                    "status": "",
                    "limit": 10,
                    "page_no": 1,
                    // "search": ""
                },
                "DeviceId": device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // on close modal
    const onClose = () => {
        setdeleteCommonStateModal({
            ...deleteCommonStateModal,
            title: "",
            open: false,
            delete_id: "",
            delete_url: "",
        });
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && (ClearFormSet?.url === "refurbished_point_value_delete" || ClearFormSet?.url === "refurbished_age_delete")) {
            onClose();
            Dispatch(ClearFormSetFutios({
                url:"",
                action:false
            }));
        };
    }, [ ClearFormSet ]);
    
    return(<Modal show={deleteCommonStateModal?.open} onHide={onClose} className='commonModal commonDeleteModals' centered>
        <Modal.Header className='border-bottom-0 bb-0 pb-0' closeButton>
            <Modal.Title className='h5' dangerouslySetInnerHTML={{__html: deleteCommonStateModal?.title}}></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p dangerouslySetInnerHTML={{__html: deleteCommonStateModal?.description}}></p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>
                No
            </Button>
            <Button variant="primary" className="addbtncmn" onClick={deletemodal}>
                Yes
            </Button>
        </Modal.Footer>
    </Modal>)
}

export default DeleteModal;