/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Badge } from 'react-bootstrap';
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link, useNavigate } from "react-router-dom";
import { WebSocketContext } from "../../App";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../Common/CommonPagination";
import CommonewModel from "../Common/CommonewModel";
import GetCurrentTableSRNo from "../Common/GetCurrentTableSRNo";
import CommonDateTime from "../Common/CommonDateTime";

const PurchaseList = (props) => {
    const { RouteName } = props;
    const { PurchaseList, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("");
    const [ deviceType, setDeviceType ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };


    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.CRMTools[1]?.Edit || PermissionsAccess?.CRMTools[1]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "purchase_list",
                "request" : { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "device_type": deviceType,
                    "search" : userSearch.trim()
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit, deviceType ]);

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    // get pin state city details
    const GetPinStateCityDetails = (elm) => {
        const getPincode = PurchaseList?.pin_code?.filter((data_) => data_?.id === elm?.pin_code_id)[0];
        const getcity = PurchaseList?.district?.filter((data_) => data_?.id === getPincode?.district_id)[0];
        const getstate = PurchaseList?.state?.filter((data_) => data_?.id === getcity?.state_id)[0];

        return {
            pincode: getPincode?.pin_code === undefined ? "-" : getPincode?.pin_code,
            city: getcity?.district_name === undefined ? "-" : getcity?.district_name,
            state: getstate?.state_name === undefined ? "-" : getstate?.state_name,
        };
    };

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={"purchase_list"}
            searchType={"Name, Frame Number, Mobile"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            deviceType={deviceType} 
            setDeviceType={setDeviceType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
            ActiveInactiveFilter={false}
        />
        <div className="tableView gstreporttableadded">
            <Scrollbars 
                style={{ height: PurchaseList?.pagination?.total_records > 10 ? "calc(100vh - 185px)" : "calc(100vh - 150px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Product Name</th>
                            <th>Franchise Name</th>
                            <th>Frame Number</th>
                            <th>Invoice No.</th>
                            <th>Invoice Date</th>
                            <th>Mobile</th>
                            <th>State</th>
                            <th>City</th>
                            <th>Pin Code</th>
                            <th>Created At</th>
                            <th>Device Type</th>
                            <th>Email Sent</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.CRMTools[1]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {PurchaseList?.list?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, PurchaseList?.pagination?.current_page, PurchaseList?.pagination?.record_limit)}    
                                </td>
                                <td>{elm?.customer_name}</td>
                                <td>{elm?.email}</td>
                                <td>{elm?.product_name}</td>
                                <td>{elm?.franchise_name}</td>
                                <td>{elm?.frame_number}</td>
                                <td>{elm?.order === undefined ? "-" : elm?.order?.invoice_code}</td>
                                <td>{elm?.order === undefined ? "-" : CommonDateTime(elm?.order?.invoice_at, "LL", "show")}</td>
                                <td>{elm?.mobile}</td>
                                <td>{GetPinStateCityDetails(elm)?.state}</td>
                                <td>{GetPinStateCityDetails(elm)?.city}</td>
                                <td>{GetPinStateCityDetails(elm)?.pincode}</td>
                                <td>{CommonDateTime(elm.created_at, "LL", "show")}</td>
                                <td style={{ textTransform: "capitalize" }}>{elm?.device === null ? "-" : elm?.device}</td>
                                <td>
                                    {elm?.email_send ? <Badge bg="success">Yes</Badge> : <Badge bg="danger">No</Badge>}
                                </td>
                                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.CRMTools[1]?.Edit) && (<td style={{ display: "flex", alignItems: "center", columnGap: "6px" }}>
                                    <Link className="btn edittable" to={`/edit-purchase/${elm?.id}`}>
                                        <i className="bi bi-pencil-square"></i>
                                    </Link>
                                    {elm?.view_file_url !== null && (<a href={process.env.REACT_APP_BASE_URL + "file/" + elm?.view_file_url}  className="downloadaction" target="__blank">
                                        <i className="bi bi-file-earmark-arrow-down"></i>
                                    </a>)}
                                </td>)}
                            </tr>)
                        })}
                        {PurchaseList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="15">Purchase list not found </td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {PurchaseList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={PurchaseList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModalNew?.open && (<CommonewModel setCurrentPage={setCurrentPage} setShowModalNew={setShowModalNew} showModalNew={showModalNew}/>)}
    </section>)
}

export default PurchaseList;