/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import SidebarMain from "./SidebarMain";
import SidebarChild from "./SidebarChild";

const Sidebar = (props) => {
    const { setDivWidth } = props
    const myDivRef = useRef(null);
    const [menuStates, setMenuStates] = useState({
        menu1: false,
        menu2: false,
        menu3: false,
        menu4: false,
    });

    useEffect(() => {
        const updateWidth = () => {
          if (myDivRef.current) {
            setDivWidth(myDivRef.current.clientWidth);
          }
        };
        updateWidth();
        window.addEventListener('resize', updateWidth);

        return () => {
          window.removeEventListener('resize', updateWidth);
        };
    }, []); 

    return(<div className="sidebarWrapper" ref={myDivRef}>
        <SidebarMain menuStates={menuStates} setMenuStates={setMenuStates}/>
        <SidebarChild menuStates={menuStates} setMenuStates={setMenuStates}/>
    </div>)
};

export default Sidebar;