/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Badge } from 'react-bootstrap';
import DataTableFilter from "../Common/DataTableFilter";
import AvatarSet from "../Common/AvatarSet";
// import CommonToggle from "../../Common/CommonToggle";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link, useNavigate } from "react-router-dom";
import { WebSocketContext } from "../../App";
import { ADD_REGION_MASTER_URL } from "../Shared/constant";
import { ClearFormSetFutios } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../Common/CommonPagination";
import CommonewModel from "../Common/CommonewModel";
import GetCurrentTableSRNo from "../Common/GetCurrentTableSRNo";

const RegionMaster = (props) => {
    const { RouteName } = props;
    const { regionMasterList, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };


    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.CRMTools[0]?.Edit || PermissionsAccess?.CRMTools[0]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "region_list",
                "request" : { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch.trim()
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    // create applications
    const cmnFnct = () => {
        Navigate(ADD_REGION_MASTER_URL);
    };

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={"Region Master"}
            searchType={"Region"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.CRMTools[0]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Region Master",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={true}
        />
        <div className="tableView">
            <Scrollbars 
                style={{ height: regionMasterList?.pagination?.total_records > 10 ? "calc(100vh - 185px)" : "calc(100vh - 150px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>Region</th>
                            <th>Status</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.CRMTools[0]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {regionMasterList?.list?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>
                                    {GetCurrentTableSRNo(index, regionMasterList?.pagination?.current_page, regionMasterList?.pagination?.record_limit)}    
                                </td>
                                <td>{elm?.region_name}</td>
                                <td>{elm?.is_active ? (<Badge bg="success">Active</Badge>) : (<Badge bg="danger">Inactive</Badge>)}</td>
                                {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.CRMTools[0]?.Edit) && (<td>
                                    <Link className="btn edittable" to={`/edit-region-master/${elm?.id}`}>
                                        <i className="bi bi-pencil-square"></i>
                                    </Link>
                                </td>)}
                            </tr>)
                        })}
                        {regionMasterList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="7">Region Master list not found </td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {regionMasterList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={regionMasterList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        {showModalNew?.open && (<CommonewModel setCurrentPage={setCurrentPage} setShowModalNew={setShowModalNew} showModalNew={showModalNew}/>)}
    </section>)
}

export default RegionMaster;