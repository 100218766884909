

/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Form, Badge, Collapse, Image, Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableFilter from "../../Common/DataTableFilter";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import GetCurrentTableSRNo from "../../Common/GetCurrentTableSRNo";
import CommonDateTime from "../../Common/CommonDateTime";
import CommonPagination from "../../Common/CommonPagination";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import OrderDetailsViewer from "../../Common/OrderDetailsViewer";
import MoreIcon from '../../../assets/img/more_options.png';
import { useNavigate } from "react-router-dom";
import { TimeSense, getColor, getStringOfErrorValues } from "../../Common/TimeSense";
import ItemInvoiceCancel from "../../Common/ItemInvoiceCancel";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";

const CreditNoteReport = (props) => {
    const navigate = useNavigate();
    const Dispatch = useDispatch();
    const [userSearch, setUserSearch] = useState("");
    const [userType, setUserType] = useState("all");
    const [userLimit, setUserLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const { websocket } = useContext(WebSocketContext);
    const [FromDate, setFromDate] = useState("");
    const [ToDate, setToDate] = useState("");

    const [show, setShow] = useState(null)
    const today = new Date();
    const { device_id, EInvoiceFailure, ClearFormSet } = useSelector((state) => state.adminReducers);
    const [OrderViewerModal, setOrderViewerModal] = useState({
        open: false,
        Title: "Order Details",
        Data: {}
    });


    
    // select start date
    const DateStartGet = (date) => {
        if(date === null) {
            setFromDate("");
        } else {
            setFromDate(date);
        }
    };

    // select end date
    const DateEndGet = (date) => {
        setCurrentPage(1);
        if(date === null) {
            setToDate("");
        } else {
            setToDate(date);
        }
    };

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    useEffect(() => {
        if (ClearFormSet?.action === 200) {
            callFilterData()
            Dispatch(ClearFormSetFutios({
                url: "",
                action: false
            }));
        };
    }, [ClearFormSet]);

   



    return (<section className="Main_section">
        <div className="row">
            <div className="col-md-3">
                <Form.Group className="mb-3">
                    {/* <Form.Label>From</Form.Label> */}
                    <div className="datepickField">
                        <DatePicker
                            selected={FromDate}
                            startDate={FromDate}
                            endDate={ToDate}
                            onChange={(date) => DateStartGet(date)}
                            dateFormat="dd/MM/yyyy"
                            maxDate={today}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="Enter From Date"
                            isClearable={FromDate !== "" ? true : false}
                            autoComplete="off"
                        />
                    </div>
                </Form.Group>
            </div>
            <div className="col-md-3" >
                <Form.Group className="mb-3">
                    {/* <Form.Label>To</Form.Label> */}
                    <div className="datepickField">
                        <DatePicker
                            selected={ToDate}
                            startDate={FromDate}
                            endDate={ToDate}
                            minDate={FromDate}
                            onChange={(date) => DateEndGet(date)}
                            dateFormat="dd/MM/yyyy"
                            maxDate={today}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="Enter To Date"
                            isClearable={ToDate !== "" ? true : false}
                            autoComplete="off"
                        />
                    </div>
                </Form.Group>
            </div>
        </div>
        <DataTableFilter
            searchType={"Invoice ID."}
            filterType={""}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType}
            setUserType={setUserType}
            userLimit={userLimit}
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={false}
            searchdisable={true}
        />
        <div className="tableView">
            <Scrollbars
                style={{ height: "calc(100vh - 190px)" }}
                renderView={props => <div {...props} className="view" />}
                
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th>SNo</th>
                            <th>Franchise Code</th>
                            <th>Name</th>
                            <th>Inv No.</th>
                            <th>Reason Type</th>
                            <th>Reason</th>
                            <th>Amount</th>
                            <th>Remarks</th>
                            <th>Request Date</th>
                            <th>Document Slip</th>
                            <th>status</th>
                            <th>Request Date</th>
                            <th>Updated By</th>
                            <th>Updated On</th>
                            <th>Admin Remark</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.SNo}</td>
                                <td>{item.Franchise_Code}</td>
                                <td>{item.Franchise_Name}</td>
                                <td>{item.Inv_No}</td>
                                <td>{item.Reason_Type}</td>
                                <td>{item.Reason}</td>
                                <td>{item.Amount}</td>
                                <td>{item.Remarks}</td>
                                <td>{item.Request_Date}</td>
                                <td>{item.Document_Slip}</td>
                                <td>{item.Status}</td>
                                <td>{item.Request_date}</td>
                                <td>{item.Updated_By}</td>
                                <td>{item.Updated_On}</td>
                                <td>{item.Admin_Remark}</td>
                            </tr>
                        ))}
                        {(!EInvoiceFailure?.data || Data.length <= 0) && <tr style={{ textAlign: "center" }} ><td colSpan="10">Out-Standing Report list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {EInvoiceFailure?.pagination?.total_records > 10 && (<CommonPagination
                currentPage={currentPage}
                paginationLength={EInvoiceFailure?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
        <OrderDetailsViewer
            OrderViewerModal={OrderViewerModal}
            setOrderViewerModal={setOrderViewerModal}
        />
        {show !== null && (
            <Modal show={show !== null} className="errorDetailsModal" onHide={() => setShow(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Error Details</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflow: "auto" }}>
                    <p dangerouslySetInnerHTML={{ __html: responseText(show?.invoice_response) }}>
                    </p>
                </Modal.Body>
            </Modal>
        )}
    </section>)
}

export default CreditNoteReport;

