import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getOrderCancelStatus } from "../../redux/actions/adminActions";

const OrderCancelModal = () => {
    const { orderCancelSuccess } = useSelector((state) => state.adminReducers);
    const Dispatch = useDispatch();

    // order cancel modal
    const onCloseOrderCancel = () => {
        Dispatch(getOrderCancelStatus({
            open: false,
            title: "",
            message: "",
        }));
    };

    return(<Modal show={orderCancelSuccess?.open} onHide={onCloseOrderCancel} className="commonModal orderCncelModalCMN" centered>
        <Modal.Header className='border-bottom-0 bb-0 pb-0' closeButton>
            <Modal.Title className='h5'>
                <i className="bi bi-check-circle-fill"></i> {orderCancelSuccess?.title}
            </Modal.Title>
        </Modal.Header>
        <div className='p-3'>
            <div className="formloginset">
                <p>{orderCancelSuccess?.message}</p>
            </div>
            <div className='d-block'>
                <Button 
                    className="btn skipbtn" 
                    onClick={() => onCloseOrderCancel()}
                >
                    Close
                </Button>
            </div>
        </div>
    </Modal>)
}

export default OrderCancelModal;