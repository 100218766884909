import React from "react";

const CommonToggle = (props) => {
    const { valueToggle, setValueToggle, name } = props;

    return(<div className="form-group">
        {name && <label>{name}</label>}
        <div className="comnToggle" onClick={() => setValueToggle(!valueToggle)}>
            {valueToggle ? (<i className="bi bi-toggle2-on"></i>) :
            (<i className="bi bi-toggle2-off"></i>)}
        </div>
    </div>)
}

export default CommonToggle;