/* eslint-disable */
import React, { useEffect, useContext } from "react";
import { Table } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { WebSocketContext } from "../../App";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector } from "react-redux";
import AmountNumberFormat from "../Common/AmountNumberFormat";

const IncentiveSlab = (props) => {
    const { RouteName } = props;
    const { device_id, IncentiveSlabList } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);

    // api calling
    const APICall = () => {
        let param = { 
            "transmit": "broadcast", 
            "url": "salesperson_incentive_slab",
            "request" : { 
                "filter_time" : null,
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket ]);

    return(<section className="Main_section">
        <div className="tableView">
            <Scrollbars 
                style={{ height: IncentiveSlabList?.pagination?.total_records > 10 ? "calc(100vh - 185px)" : "calc(100vh - 100px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th>SNO</th>
                            <th>Slab</th>
                            <th>Range From</th>
                            <th>Range to</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {IncentiveSlabList?.list?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>
                                    {index + 1}
                                </td>
                                <td>{elm?.slab}</td>
                                <td>{elm?.range_from}</td>
                                <td>{elm?.range_to}</td>
                                <td>{AmountNumberFormat(elm?.amount)}</td>
                            </tr>)
                        })}
                        {IncentiveSlabList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="8">Sales person performance list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
        </div>
    </section>)
}

export default IncentiveSlab;