/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Table, Button, Form, Modal } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import SelectReact from "../../Common/SelectReact";
import AmountNumberFormat from "../../Common/AmountNumberFormat";
import CommonDateTime from "../../Common/CommonDateTime";
/* eslint-disable */
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CommonewModel from "../../Common/CommonewModel";
import { ClearFormSetFutios } from "../../../redux/actions/adminActions";

const StockInward = (props) => {
    const { wareHouselistall, device_id, productCategorylistall, ClearFormSet, selfInfo } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Dispatch = useDispatch();
    const { RouteName } = props;
    const [formData, setFormData] = useState({
        product_id:"",
        productData:null,
        warehouseData:null,
        batch_no:"",
        mfg_date:"",
        exp_date:"",
        qty:"",
        billing_number: "",
        attachment_file: {
            file_name: "",
            file_id: "",
        },
    });
    const [ warehouse_id, setwarehouse_id ] = useState("");
    const [stockItem, setStockItem] = useState([]);
    const [UpdateStockItem, setUpdateStockItem] = useState([]);
    const [showModalNew, setShowModalNew] = useState({
        open: false,
        title: "",
        modalType: "",
        Data: {}
    });
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    const [ PermissionStockInwardModule, setPermissionStockInwardModule ] = useState({
        open: false,
        permissionStockInward: "",
    });

    useEffect(() => {
        callWarehouseAPI();
        callProductAPI();
    }, [websocket]);

    useEffect(() => {
        setPermissionStockInwardModule({
            ...PermissionStockInwardModule,
            open: true,
            permissionStockInward: "",
        });
    }, []);

    const callWarehouseAPI = () =>{
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[0]?.Edit || PermissionsAccess?.Store[0]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "warehouse_list",
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    }

    const callProductAPI = () =>{
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[0]?.Edit || PermissionsAccess?.Store[0]?.View)) {
            let param = { 
                transmit: "broadcast",
                url: "product_list",
                request: {
                    "status" : "",
                    "limit" : 5000,
                    "page_no" : 1,
                    "search" : "",
                    "order_by" :"-updated_at",
                },
                "DeviceId" : device_id
            }
            wsSend_request(websocket, param);
        };
    }

    
    
    const optionsList = useMemo(() => {
        return wareHouselistall?.filter((elm) => {
            if(elm?.warehouse_type === PermissionStockInwardModule?.permissionStockInward && elm?.is_active === true) {
                return elm;
            };
        })?.map((item)=>({
            ...item,
            label:`${item?.warehouse_name} (${item?.warehouse_code})- (${item?.display_name})`,
            value:item?.id,
        }))
    }, [ wareHouselistall, PermissionStockInwardModule ]);

    // get category name
    const GetMainCategoryName = (sub_category_id, arrays) => {
        const CurrentSubCategory = arrays?.sub_category?.filter((elm) => elm?.id === sub_category_id)[0];
        const CurrentCategory = arrays?.category?.filter((elm) => elm?.id === CurrentSubCategory?.category_id)[0];
        return CurrentCategory?.category_name;
    };

    const optionsProduct = useMemo(() => {
        return productCategorylistall?.list?.filter((item) => {
            const PermissionStockInward = PermissionStockInwardModule?.permissionStockInward?.split(",");
            const GetCategoryName = GetMainCategoryName(item?.sub_category_id, productCategorylistall);
            if(item.is_bike === false && item?.is_active === true && PermissionStockInward?.filter((elm) => elm?.trim() === GetCategoryName?.trim())?.length > 0) {
                return item;
            };
        }).map(item => ({
                ...item,
                label: `${item.product_name} (${item.product_code})`,
                value: item.id,
            }));
    }, [ productCategorylistall?.list?.length, PermissionStockInwardModule ]);
    
    const onChange = (e, data) =>{
        const dataForm = {
            [e.target.name]:e.target.value
        }
        if(e.target.name === "product_id"){
            dataForm.productData = data;
            dataForm.batch_no = ""
            dataForm.mfg_date = ""
            dataForm.exp_date = ""
            dataForm.qty = ""
        }
        if(e.target.name == "qty"){
            let value = e.target.value;
            value = value.replace(/^0+/g, "");
            value = value.replace(/\D/g, "");
            if (value === "" || parseInt(value) < 0) {
                value = "";
            }
            dataForm[e.target.name] = value
        }
        setFormData((data)=>({
            ...data,
            ...dataForm
        }))
    };

    const onChangeWarehouse = (e, data) => {
        if(data !== null) {
            if(e.target.name === "warehouse_id"){
                setwarehouse_id(data);
            }
        } else {
            callReset();
            setwarehouse_id("");
            setUpdateStockItem([]);
            setPermissionStockInwardModule({
                ...PermissionStockInwardModule,
                open: true,
                permissionStockInward: "",
            });
        };
    };

    function generateRandomId() {
        // Generate a random number
        const randomNumber = Math.floor(Math.random() * 10000);
        
        // Get the current timestamp
        const timestamp = new Date().getTime();
        
        // Concatenate the random number and timestamp to create the ID
        const randomId = `${timestamp}-${randomNumber}`;
        
        return randomId;
    };

    const CommonOrderArray = stockItem === undefined ? [] : [...stockItem];
    const callAddProduct = (e) =>{
        e.preventDefault();
        const formDataNew = {...formData, id: generateRandomId()};
        upsertItem(CommonOrderArray, formDataNew);
        setFormData((data)=>({
            ...data,
            productData:null,
            batch_no:"",
            mfg_date:"",
            exp_date:"",
            qty:"",
            billing_number: "",
            attachment_file: {
                file_name: "",
                file_id: "",
            },
        }));
    };

    function upsertItem(arr, item) {
        // Find the index of the item with the same ID
        const index = arr.findIndex(i => i.product_id === item.product_id);
      
        if (index > -1) {
          // Replace the item if found
          arr[index] = item;
          
        } else {
          // Add the item if not found
          arr.push(item);
        }
        setStockItem(arr);
    };
    

    // Function to get unique elements based on ID
    function getUniqueById(array) {
        var seen = {}; // Object to keep track of seen IDs
        return array.filter(function(item) {
            return seen.hasOwnProperty(item.id) ? false : (seen[item.id] = true);
        });
    };
    
    // update order details
    const CommonUniqueArray = [...UpdateStockItem];
    const callUpdate = (data_) => {
        CommonUniqueArray?.push(data_);
        setUpdateStockItem(getUniqueById(CommonUniqueArray));
    };
    const UpdateProductDetails = (id, value, label) => {
        const OldUpdateStockItem = UpdateStockItem?.map((elm) => {
            if(elm?.id === id) {
                if(label === "batch_no") {
                    return{
                        ...elm,
                        batch_no: value
                    }
                } else if(label === "mfg_date") {
                    return{
                        ...elm,
                        mfg_date: value
                    }
                } else if(label === "exp_date") {
                    return{
                        ...elm,
                        exp_date: value
                    }
                } else if(label === "qty") {
                    return{
                        ...elm,
                        qty: value
                    }
                };
            } else 
            return elm;
        });
        setUpdateStockItem(OldUpdateStockItem);
    };

    // update add function
    const callUpdateProduct = () => {
        // Combine arrays
        const combinedArray = [...stockItem, ...CommonUniqueArray];

        // Ensure uniqueness by ID
        const uniqueById = combinedArray.reduce((acc, current) => {
            acc[current.id] = current; // This replaces any existing object with the same ID
            return acc;
        }, {});

        // Extract into a final array
        const uniqueArray = Object.values(uniqueById);

        setStockItem(uniqueArray);
        setFormData((data)=>({
            ...data,
            productData:null,
            batch_no:"",
            mfg_date:"",
            exp_date:"",
            qty:"",
            billing_number: "",
            attachment_file: {
                file_name: "",
                file_id: "",
            },
        }));
        setUpdateStockItem([]);
    };

    // delete order
    const DeleteItem = (item) => {
        const OldstockItem = [...stockItem];
        const NewOldstockItem = [];
        OldstockItem && OldstockItem?.filter((elm) => {
            if(elm?.id !== item.id) {
                return NewOldstockItem.push(elm);
            }
        }); 
        setStockItem(NewOldstockItem);
        toast.error(`${item?.productData?.product_name} - (${item?.productData?.product_code})` + "successfully deleted.");
    };

    const callReset = (e) =>{
        setFormData({
            product_id:"",
            productData:null,
            batch_no:"",
            mfg_date:"",
            exp_date:"",
            qty:"",
            billing_number: "",
            attachment_file: {
                file_name: "",
                file_id: "",
            },
        });
        setStockItem();
    };

    // update stock modal open
    const UpdateStockModal = () => {
        setShowModalNew({
            ...showModalNew,
            open: !showModalNew?.open,
            title: "Update Stock",
            subtitle: "Update Stock",
            modalType: "form",
            button: "Update"
        });
    };
    
    // stock inward add
    const stockInwardAdd = () => {
        const APIFormatItem = stockItem?.map((elm) => {
            return {
                product_id: elm?.product_id,
                qty: parseInt(elm?.qty),
                mfg_date: (elm?.mfg_date === null || elm?.mfg_date === "") ? "" : CommonDateTime(elm?.mfg_date, "YYYY-MM-DD", "hide"),
                exp_date: (elm?.exp_date === null || elm?.exp_date === "") ? "" : CommonDateTime(elm?.exp_date, "YYYY-MM-DD", "hide"),
                batch_no: elm?.batch_no,
            }
        });
        let param = { 
            "transmit": "broadcast",
            "url": "stock_inward_add",
            "request": {
                "warehouse_id" : warehouse_id?.id,
                "items" : APIFormatItem,
                "bill_no": formData?.billing_number,
                "bill_receipt": formData?.attachment_file?.file_name,
                "bill_receipt_id": formData?.attachment_file?.file_id
            },
            "DeviceId" : device_id
        };
        wsSend_request(websocket, param);
    };

    // Stock inward modal permission 
    const onClose = () => {
        setPermissionStockInwardModule({
            ...PermissionStockInwardModule,
            open: false,
            permissionStockInward: "",
        });
        window.history.back();
    };

    // Permission Add Stock fucntion
    const PermissionAddStock = (value) => {
        setPermissionStockInwardModule({
            ...PermissionStockInwardModule,
            open: false,
            permissionStockInward: value,
        });
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            callReset();
            setwarehouse_id("");
            setUpdateStockItem([]);
            setTimeout(() => {
                Dispatch(ClearFormSetFutios({
                    url: "",
                    action: false
                }));
            }, 1000);
        };
    }, [ ClearFormSet ]);

    return(<section className="Main_section">
        <Scrollbars 
            style={{ height: "calc(100vh - 100px)" }} 
            renderView={props => <div {...props} className="view"/>}
            
            className="ScrollbarsSidebar"
        >
            <div className="filtr_Form">
                <div className="formloginset">
                    <div className="row">
                        <div className="col-md-4 mb-4">
                            <SelectReact
                                label={"Select Warehouse"}
                                options={optionsList}
                                name={"warehouse_id"}
                                value={warehouse_id?.id}
                                onChange={onChangeWarehouse}
                                isClearable
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <SelectReact
                                label={"Search By FsCode"}
                                options={optionsProduct}
                                name={"product_id"}
                                value={formData?.product_id}
                                onChange={onChange}
                                disabled={(warehouse_id === "" || warehouse_id === null) ? true : false}
                                isClearable
                            />
                        </div>
                    </div>
                    {formData?.productData && (
                        <React.Fragment>
                            <div className="HeaderDivider">
                                <h6 className="subtitleForm">Search Result:</h6>
                                <div className="formsearchList">
                                    <div className="row">
                                        <div className="col-md-3" style={{ width: "23%" }}>
                                            <Form.Group>
                                                <Form.Label>FsCode</Form.Label>
                                                <div className="formData">{formData?.productData?.product_name} - ({formData?.productData?.product_code})</div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-1" style={{ padding: "0px", width: "10%" }}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Purchase Price</Form.Label>
                                                <div className="formData">
                                                    {formData?.productData?.is_bike ? formData?.productData?.demo_rate : formData?.productData?.franchise_rate}
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-2" style={{ width: "15%" }}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Batch No</Form.Label>
                                                <Form.Control type="text" name="batch_no" onChange={onChange} value={formData?.batch_no} onKeyDown={EmptySpaceFieldValid} placeholder="Enter Batch No" />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-2" style={{ width: "17%" }}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Mfg Date</Form.Label>
                                                <div className="datepickField">
                                                    <DatePicker 
                                                        selected={formData?.mfg_date} 
                                                        onChange={(date) => setFormData((data)=>({...data, mfg_date:date}))} 
                                                        dateFormat="dd/MM/yyyy"
                                                        peekNextMonth 
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        placeholderText="Enter Date"
                                                        // maxDate={new Date()}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-2" style={{ width: "17%" }}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Expiry Date</Form.Label>
                                                <div className="datepickField">
                                                    <DatePicker 
                                                        selected={formData?.exp_date} 
                                                        onChange={(date) => setFormData((data)=>({...data, exp_date:date}))} 
                                                        dateFormat="dd/MM/yyyy"
                                                        peekNextMonth 
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        placeholderText="Enter Date"
                                                        // minDate={new Date()}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-2">
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Quantity</Form.Label>
                                                <Form.Control name="qty" onChange={onChange} value={formData?.qty} onKeyDown={EmptySpaceFieldValid} type="text" placeholder="Enter quantity" />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                <div hidden className="formsearchList">
                                    <div className="row">
                                        <div className="col-md-3" style={{ width: "23%" }}>
                                            <Form.Group>
                                                <Form.Label>FsCode</Form.Label>
                                                <div className="formData">WH001- (testing)</div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-1" style={{ padding: "0px", width: "10%" }}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Purchase Price</Form.Label>
                                                <div className="formData">3997</div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-2" style={{ width: "15%" }}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Batch No</Form.Label>
                                                <Form.Control type="text" onKeyDown={EmptySpaceFieldValid} placeholder="Enter Batch No" />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-2" style={{ width: "17%" }}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Mfg Date</Form.Label>
                                                <div className="datepickField">
                                                    <DatePicker 
                                                        // selected={DateSelect} 
                                                        // onChange={(date) => setDateSelect(date)} 
                                                        dateFormat="dd/MM/yyyy"
                                                        peekNextMonth 
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        placeholderText="Enter Date"
                                                        // maxDate={newDate}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-2" style={{ width: "17%" }}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Expiry Date</Form.Label>
                                                <div className="datepickField">
                                                    <DatePicker 
                                                        // selected={DateSelect} 
                                                        // onChange={(date) => setDateSelect(date)} 
                                                        dateFormat="dd/MM/yyyy"
                                                        peekNextMonth 
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        placeholderText="Enter Date"
                                                        // maxDate={newDate}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-2">
                                            <Form.Group className="mb-3" >
                                                <Form.Label>qty</Form.Label>
                                                <Form.Control type="text" onKeyDown={EmptySpaceFieldValid} placeholder="Enter qty" />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                                <div className="btnGroupswraps">
                                    <Button className="Addbtn" disabled={formData?.qty === "" ? true : false} onClick={callAddProduct}> Add</Button>
                                    <Button className="Resetbtn" onClick={callReset}>Reset</Button>
                                </div>
                            </div>
                        </React.Fragment>
                    )}

                    {UpdateStockItem?.length > 0 && (<React.Fragment>
                        <div className="HeaderDivider">
                            <h6 className="subtitleForm">Edit</h6>
                            {UpdateStockItem && UpdateStockItem?.map((elm, index) => {
                                return(<React.Fragment key={index}>
                                <div className="formsearchList">
                                    <div className="row">
                                        <div className="col-md-3" style={{ width: "23%" }}>
                                            <Form.Group>
                                                <Form.Label>FsCode</Form.Label>
                                                <div className="formData">{elm?.productData?.product_name} - ({elm?.productData?.product_code})</div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-1" style={{ padding: "0px", width: "10%" }}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Purchase Price</Form.Label>
                                                <div className="formData">
                                                    {elm?.productData?.is_bike ? elm?.productData?.demo_rate : elm?.productData?.franchise_rate}
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-2" style={{ width: "15%" }}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Batch No</Form.Label>
                                                <Form.Control type="text" name="batch_no" onChange={(e) => UpdateProductDetails(elm?.id, e?.target?.value, "batch_no")} onKeyDown={EmptySpaceFieldValid} value={elm?.batch_no} placeholder="Enter Batch No" />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-2" style={{ width: "17%" }}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Mfg Date</Form.Label>
                                                <div className="datepickField">
                                                    <DatePicker 
                                                        selected={elm?.mfg_date} 
                                                        onChange={(date) => UpdateProductDetails(elm?.id, date, "mfg_date")} 
                                                        dateFormat="dd/MM/yyyy"
                                                        peekNextMonth 
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        placeholderText="Enter Date"
                                                        // maxDate={new Date()}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-2" style={{ width: "17%" }}>
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Expiry Date</Form.Label>
                                                <div className="datepickField">
                                                    <DatePicker 
                                                        selected={elm?.exp_date} 
                                                        onChange={(date) => UpdateProductDetails(elm?.id, date, "exp_date")}
                                                        dateFormat="dd/MM/yyyy"
                                                        peekNextMonth 
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        placeholderText="Enter Date"
                                                        // minDate={new Date()}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-2">
                                            <Form.Group className="mb-3" >
                                                <Form.Label>Quantity</Form.Label>
                                                <Form.Control name="qty" onChange={(e) => UpdateProductDetails(elm?.id, e?.target?.value, "qty")} value={elm?.qty} onKeyDown={EmptySpaceFieldValid} type="text" placeholder="Enter quantity" />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>)})}
                            <div className="btnGroupswraps">
                                <Button className="Addbtn" onClick={callUpdateProduct}>Update</Button>
                                {/* <Button className="Resetbtn" onClick={callUpdateReset}>Reset</Button> */}
                            </div>
                        </div>
                    </React.Fragment>)}
                </div>
            </div>
            {stockItem?.length >0 ?(
                <React.Fragment>
                    <div className="tableView">
                        <Table bordered  >
                            <thead>
                                <tr>
                                    <th>SNO</th>
                                    <th>Product Code</th>
                                    <th>Product Name</th>
                                    <th>qty</th>
                                    <th>Unit Price</th>
                                    <th>Total Price</th>
                                    <th>Batch No</th>
                                    <th>Mfg Date</th>
                                    <th>Expiry Date</th>
                                    <th style={{ width: "70px" }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {stockItem?.map((item, index)=> {
                                return(
                                    <React.Fragment key={index?.toString()}>
                                         <tr>
                                             <td>{index + 1}</td>
                                             <td>{item?.productData?.product_name} - ({item?.productData?.product_code})</td>
                                             <td>{item?.productData?.label}</td>
                                             <td>{item?.qty}</td>
                                             <td>{AmountNumberFormat(item?.productData?.mrp)}</td>
                                             <td>{AmountNumberFormat(item?.productData?.mrp * item?.qty)}</td>
                                             <td>{item?.batch_no === "" ? "-" : item?.batch_no}</td>
                                             <td>{(item?.mfg_date === null || item?.mfg_date === "") ? "-" : CommonDateTime(item?.mfg_date, "LL", "show")}</td>
                                             <td>{(item?.exp_date === null || item?.exp_date === "") ? "-" : CommonDateTime(item?.exp_date, "LL", "show")}</td>
                                             <td>
                                                 <Button className="btn edittable" onClick={(e) => callUpdate(item)}>
                                                     <i className="bi bi-pencil-square"></i>    
                                                 </Button>
                                                 <Button className="btn edittable" onClick={(e) => DeleteItem(item)} style={{ marginLeft: "7px" }}>
                                                     <i className="bi bi-trash" style={{ color: "red" }}></i> 
                                                 </Button>
                                             </td>
                                         </tr>
                                    </React.Fragment>
                                )
                            })}
                            </tbody>
                        </Table>
                        <div className="btnGroupswraps">
                            {/* <Button className="Addbtn" onClick={() => stockInwardAdd()}>Update Stock</Button> */}
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.Store[0]?.Edit) && (
                                <Button className="Addbtn" onClick={() => UpdateStockModal()}>Update Stock</Button>
                            )}
                            <Button className="Resetbtn">Reset</Button>
                        </div>
                    </div>
                </React.Fragment>
            ):(
                <React.Fragment/>
            )}
        </Scrollbars>

        {showModalNew?.open && (<CommonewModel 
            setShowModalNew={setShowModalNew} 
            showModalNew={showModalNew} 
            UpdateInwardFunctions={stockInwardAdd}
            formData={formData}
            setFormData={setFormData}
        />)}

        {PermissionStockInwardModule?.open && (<Modal show={PermissionStockInwardModule?.open} onHide={onClose} className='commonModal commonModalStockInward' centered>
            <Modal.Header className='border-bottom-0 bb-0 pb-0' closeButton>
                <Modal.Title className='h5'>Choose Inward Type <span className='text-danger' >*</span></Modal.Title>
            </Modal.Header>
            <div className='p-3 btn-groups-main' id="btn-groups-main-id">
                <button className="btn" onClick={() => PermissionAddStock("Spares, Service, Transportation Charges")}><i className="bi bi-bicycle"></i> Spares</button>
                <button className="btn" onClick={() => PermissionAddStock("Accessories, Transportation Charges")}><i className="bi bi-bicycle"></i> Accessories</button>
                <button className="btn" onClick={() => PermissionAddStock("Marketing, Transportation charges")}><i className="bi bi-bicycle"></i> Marketing</button>
            </div>
        </Modal>)}
    </section>)
}

export default StockInward;