/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Table, Badge, Image, Collapse } from 'react-bootstrap';
import DataTableFilter from "../Common/DataTableFilter";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link, useNavigate } from "react-router-dom";
import { WebSocketContext } from "../../App";
import { ADD_POINT_VALUE_REFURBISHED_REPORT, EDIT_POINT_VALUE_REFURBISHED_REPORT, FILE_AUTH_URL } from "../Shared/constant";
import { ClearFormSetFutios  } from "../../redux/actions/adminActions";
import wsSend_request from "../../Api/ws/ws_request";
import { useSelector, useDispatch } from "react-redux";
import CommonPagination from "../Common/CommonPagination";
import GetCurrentTableSRNo from "../Common/GetCurrentTableSRNo";
import MoreIcon from '../../assets/img/more_options.png';
import DefaultImage from "../../assets/img/DefaultProduct.jpg";

const Pointlist = (props) => {
    const { RouteName } = props;
    const { PointValueList, device_id, selfInfo } = useSelector((state) => state.adminReducers);
    const { websocket } = useContext(WebSocketContext);
    const Navigate = useNavigate();
    const Dispatch = useDispatch();
    const [ userSearch, setUserSearch ] = useState("");
    const [ userType, setUserType ] = useState("");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ activeTableDetails, setActiveTableDetails ] = useState("");
    const PermissionsAccess = selfInfo?.user?.permissions_access;

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    // show more user details
    const ViewMoreDetails = (id) => {
        setActiveTableDetails(id);
    };

    // hide more user details
    const ElseMoreDetails = () => {
        setActiveTableDetails("");
    };

    // api calling
    const APICall = () => {
        if(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.ReSells[4]?.Edit || PermissionsAccess?.ReSells[4]?.View)) {
            let param = { 
                "transmit": "broadcast", 
                "url": "refurbished_point_list",
                "request" : { 
                    "status" : userType === "active" ? true : userType === "inactive" ? false : "", 
                    "limit" : userLimit,
                    "page_no" : currentPage, 
                    "search" : userSearch.trim()
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    // when page load api calling
    useEffect(() => {
        APICall();
    }, [ websocket, userSearch, userType, currentPage, userLimit ]);

    // create points
    const cmnFnct = () => {
        Navigate(ADD_POINT_VALUE_REFURBISHED_REPORT);
    };

    useEffect(() => {
        Dispatch(ClearFormSetFutios({
            url:"",
            action:false
        }));
    }, []);

    return(<section className="Main_section">
        <DataTableFilter 
            filterType={""}
            searchType={"Point Name"}
            userSearch={userSearch}
            setUserSearch={setUserSearch}
            userType={userType} 
            setUserType={setUserType}
            userLimit={userLimit} 
            setUserLimit={setUserLimit}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            SelectUserTypeFnct={SelectUserTypeFnct}
            buttonvisible={(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ReSells[4]?.Edit) ? true : false}
            ButtonSet={{
                buttontitle: "Create Point",
                function: cmnFnct
            }}
            searchdisable={true}
            ActiveInactiveFilter={false}
        />
        <div className="tableView">
            <Scrollbars 
                style={{ height: PointValueList?.pagination?.total_records > 10 ? "calc(100vh - 233px)" : "calc(100vh - 198px)"}} 
                renderView={props => <div {...props} className="view"/>}
                className="ScrollbarsSidebar"
            >
                <Table bordered  >
                    <thead>
                        <tr>
                            <th></th>
                            <th>SNO</th>
                            <th>Point Name</th>
                            <th>Show Condition</th>
                            <th>Point Score</th>
                            <th>Status</th>
                            {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ReSells[4]?.Edit) && (<th>Action</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {PointValueList?.list?.map((elm, index) => {
                            return(<React.Fragment key={index}>
                                <tr>
                                    <td style={{ width: '10px' }}>
                                        <button 
                                            className={activeTableDetails === elm?.id ? "btn actionbtn active" : "btn actionbtn"}
                                            onClick={() => activeTableDetails === elm?.id ? ElseMoreDetails() : ViewMoreDetails(elm?.id)}
                                            aria-controls="example-collapse-text"
                                            aria-expanded={activeTableDetails === elm?.id ? true : false}
                                        >
                                            <Image src={MoreIcon} alt="more icon" />
                                        </button>
                                    </td>
                                    <td>
                                        {GetCurrentTableSRNo(index, PointValueList?.pagination?.current_page, PointValueList?.pagination?.record_limit)}    
                                    </td>
                                    <td>{elm?.refurbished_point}</td>
                                    <td style={{ textTransform: "capitalize" }}>{elm?.show_condition === null ? "-" : elm?.show_condition}</td>
                                    <td>{elm?.point_score === null ? "-" : elm?.point_score}</td>
                                    <td>{elm?.is_active ? (<Badge bg="success">Active</Badge>) : (<Badge bg="danger">Inactive</Badge>)}</td>
                                    {(selfInfo?.user?.user_type === 'super_admin' || PermissionsAccess?.ReSells[4]?.Edit) && (<td>
                                        <Link className="btn edittable" to={`${EDIT_POINT_VALUE_REFURBISHED_REPORT}/${elm?.id}`}>
                                            <i className="bi bi-pencil-square"></i>
                                        </Link>
                                    </td>)}
                                </tr>
                                <Collapse in={activeTableDetails === elm?.id ? true : false}>
                                    <tr className="tablecollapsewraps" id={"tablecollpase"+elm?.id}>
                                        <td colSpan="8" >
                                            <Table bordered  className="table">
                                                <tbody>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Point Images Allow:</th>
                                                        <td>{elm?.is_image ? (<Badge bg="success">Yes</Badge>) : (<Badge bg="danger">No</Badge>)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Point Images Labels:</th>
                                                        <td>
                                                            <ul style={{ margin: "0px" }}>
                                                                {elm?.point_images === null ? "-" : JSON.parse(elm?.point_images?.replace(/'/g, '"'))?.map((elm, index) => {
                                                                    return(<li key={index}>{elm}</li>)
                                                                })}
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th style={{ width: "20%" }}>Reference Images With Range:</th>
                                                        <td>
                                                            {elm?.reference_images === null ? "-" : (
                                                                <ul className="uploade_images_wrapper_list" style={{ margin: "0px" }}>
                                                                    {JSON.parse(elm?.reference_images?.replace(/'/g, '"'))?.map((elm) => {
                                                                        return(<li>
                                                                            <div className="wrapps_upload_img">
                                                                                    <span>Range : {elm?.range}</span>
                                                                                    <div className="image_preview_wraps" key={index}>
                                                                                    <img src={FILE_AUTH_URL + elm?.images?.view_file_url} onError={(e) => e.target.src = DefaultImage} alt="image_preiew" />
                                                                                </div>
                                                                            </div>
                                                                        </li>)
                                                                    })}
                                                                </ul>
                                                            )}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </td>
                                    </tr>
                                </Collapse>
                            </React.Fragment>)
                        })}
                        {PointValueList?.list?.length === 0 && <tr style={{ textAlign: "center" }} ><td colSpan="7">Point list not found</td></tr>}
                    </tbody>
                </Table>
            </Scrollbars>
            {/* pagination */}
            {PointValueList?.pagination?.total_records > 10 && (<CommonPagination 
                currentPage={currentPage}
                paginationLength={PointValueList?.pagination}
                currentFunction={currentFunction}
            />)}
        </div>
    </section>)
}

export default Pointlist;