/* eslint-disable */
import React, { useState } from "react";
import { Modal, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import CheckValid from "./CheckValid";
import GenerateFrameExcelAPI from "../../Api/GenerateFrameExcelAPI";
import { useSelector } from "react-redux";

const SendMailModal = (props) => {
    const { sendEmailModal, setsendEmailModal } = props;
    const { accessToken } = useSelector((state) => state.adminReducers);
    const [ ApplicationState, setApplicationState ] = useState({
        Emails: [],
        is_sold: false,
        is_unsold: false,
        is_sold_unsold: false,
    }); 
    const [ Loader, setLoader ] = useState(false);

    // regex 
    const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/; // eslint-disable-line
    const EmailEmotaradRegex = /^[a-zA-Z0-9._%+-]+@(emotorad\.in|emotorad\.com)$/;
    
    // error state
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorEmailArray, setErrorEmailArray ] = useState("");
    const [ errorType, setErrorType ] = useState("");

    // statging and live status
    const stagingLiveAccess = process.env.REACT_APP_ACCESS_URL;

    const [ ImageLabelState, setImageLabelState ] = useState("");
    let IsImageVar = [...ApplicationState?.Emails];

    // statging and live stattus wise email validation change
    let CheckEmailRgex = stagingLiveAccess === "REACT_APP_STAGING_ACCESS" ? EmailRegex : EmailEmotaradRegex;

    // add mail function
    const AddImageLbFct = () => {
        if(ImageLabelState !== "") {
            const ApplicationStatelet = ApplicationState?.Emails?.filter((elm) => elm.toLowerCase() === ImageLabelState.toLowerCase());
            if(ApplicationStatelet?.length === 0) {
                if(CheckEmailRgex.test(ImageLabelState) === true) {
                    IsImageVar?.push(ImageLabelState);
                    setApplicationState({
                        ...ApplicationState,
                        Emails: IsImageVar
                    });
                    setImageLabelState("");
                    setErrorEmail("");
                    setErrorEmailArray("");
                } else {
                    CheckValid(ImageLabelState, {type: stagingLiveAccess === "REACT_APP_STAGING_ACCESS" ? 'email' :'email_emotorad', error: setErrorEmail })
                };
            } else {
                toast.error("Email allready exist");
            };
        };
    };

    // send mail checkbox
    const MainCheckboxChange = (e, url) => {
        if(e.target.checked) {
            setApplicationState({
                ...ApplicationState,
                is_sold: url === "is_sold" ? e.target.checked : false,
                is_unsold: url === "is_unsold" ? e.target.checked : false,
                is_sold_unsold: url === "is_sold_unsold" ? e.target.checked : false
            });
            setErrorType("");
        } else {
            // setErrorType("Please select Type");
        };
    };

    // on enter mail add
    const onEnterSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            AddImageLbFct();
        };
    };

    // delete mail function
    const deleteImageLael = (label) => {
        const UpdateIsImageVar = IsImageVar?.filter((elm) => {
            if(elm !== label) {
                return elm;
            }
        });
        setApplicationState({
            ...ApplicationState,
            Emails: UpdateIsImageVar
        });
    };

    // send mail function call
    const SendMailFct = async () => {
        if(ApplicationState?.Emails?.length > 0 && (ApplicationState?.is_sold || ApplicationState?.is_sold_unsold || ApplicationState?.is_unsold)) {
            setErrorEmailArray("");
            setErrorType("");
            setLoader(true);
            const GetMails = ApplicationState?.Emails?.toString()?.replaceAll("[","")?.replaceAll("]","");
            const formData = new FormData();
            formData.append("emails", GetMails);
            
            if(ApplicationState?.is_sold) {
                formData.append("is_sold", ApplicationState?.is_sold);
            } else if(ApplicationState?.is_unsold) {
                formData.append("is_unsold", ApplicationState?.is_unsold);
            } else{
                formData.append("is_sold_unsold", ApplicationState?.is_sold_unsold);
            };
            
            const response = await GenerateFrameExcelAPI(accessToken, formData);
            // const formData = {
            //     emails: GetMails,
            //     is_sold: false,
            //     is_unsold: false,
            //     is_sold_unsold: false,
            // };
            
            // if(ApplicationState?.is_sold) {
            //     formData.is_sold = ApplicationState?.is_sold;
            // } else if(ApplicationState?.is_unsold) {
            //     formData.is_unsold = ApplicationState?.is_unsold;
            // } else{
            //     formData.is_sold_unsold = ApplicationState?.is_sold_unsold;
            // };
            
            // const response = await GenerateFrameExcelAPI(accessToken, formData);
            if(response?.status === 200) {
                setLoader(false);
                onClose();
                toast.success(response?.message);
            } else {
                toast.error(response?.message);
                setLoader(false);
            };
        } else {
            setLoader(false);
            if(ApplicationState?.Emails?.length === 0) {
                setErrorEmailArray("Please add email !");
            } else {
                setErrorEmailArray("");
            };
            if(!ApplicationState?.is_sold && !ApplicationState?.is_sold_unsold && !ApplicationState?.is_unsold) {
                setErrorType("Please select Type");
            } else {
                setErrorType("");
            };
        };
    };

    // modal close 
    const onClose = () => {
        if(!Loader) {
            setsendEmailModal({
                ...sendEmailModal,
                open: false,
                title: "",
                is_sold: false,
                is_unsold: false,
                is_sold_unsold: false,
            });
            setErrorEmail("");
            setErrorEmailArray("");
            setErrorType("");
            setImageLabelState("");
        };
    };

    return(<Modal show={sendEmailModal?.open} onHide={onClose} keyboard={false} backdrop="static" className='commonModal commonModalStockInward commonModalStockInwardEmails' centered>
        <Modal.Header className='border-bottom-0 bb-0 pb-0' closeButton>
            <Modal.Title className='h5'>{sendEmailModal?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "0px" }}>
            <div className='p-3 btn-groups-main' id="btn-groups-main-id">
                <div className="col-md-12" style={{ display: "flex", columnGap: "12px" }}>
                    <Form.Group className="mb-1" style={{ width: "88%" }}>
                        <Form.Label>Email<span className="mendatory_star">*</span></Form.Label>
                        <div className="add_images_wraper">
                            <Form.Control
                                type="text" 
                                value={ImageLabelState} 
                                onChange={(e) => setImageLabelState(e.target.value)}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e);
                                    onEnterSearch(e);
                                }}
                                onKeyUp={(e) => CheckValid(e.target.value, {type: stagingLiveAccess === "REACT_APP_STAGING_ACCESS" ? 'email' :'email_emotorad', error: setErrorEmail })}
                                placeholder="Enter Email"
                            />
                        </div>
                        {(ImageLabelState !== "" && errorEmail) && (<span className="error_medotory"><i className="bi bi-x-circle-fill"></i> {errorEmail}</span>)}
                        {(errorEmailArray !== "" && ApplicationState?.Emails?.length === 0) && (<span className="error_medotory"><i className="bi bi-x-circle-fill"></i> {errorEmailArray}</span>)}
                    </Form.Group>
                    <Form.Group className="mb-1" style={{ width: "12%" }}>
                        <Form.Label>&nbsp;</Form.Label>
                        <button className="btn_add" onClick={() => AddImageLbFct()}>
                            <i className="bi bi-plus-circle-fill"></i>
                        </button>
                    </Form.Group>
                </div>
                {ApplicationState?.Emails?.length > 0 && (<div className="col-md-12">
                    <ul className="images_labels_wrapper">
                        {ApplicationState?.Emails?.map((elm, index) => {
                            return(<li key={index}>{elm} <i className="bi bi-x-lg" onClick={() => deleteImageLael(elm)}></i></li>)
                        })}
                    </ul>
                </div>)}
                <div className="col-md-12" style={{ display: "flex", columnGap: "12px" }}>
                    <Form.Group className="mb-1" style={{ width: "88%" }}>
                        <Form.Label>Type<span className="mendatory_star">*</span></Form.Label>
                        <div className="type_checkbox_wrapper">
                            <Form.Label>
                                Sold
                                <Form.Check
                                    type="radio"
                                    value={"Sold"}
                                    name="type"
                                    checked={ApplicationState.is_sold}
                                    onChange={(e) => MainCheckboxChange(e, "is_sold")}
                                />
                            </Form.Label>
                            <Form.Label>
                                Un-Sold
                                <Form.Check
                                    type="radio"
                                    value={"Un-Sold"}
                                    name="type"
                                    checked={ApplicationState.is_unsold}
                                    onChange={(e) => MainCheckboxChange(e, "is_unsold")}
                                />
                            </Form.Label>
                            <Form.Label>
                                Both
                                <Form.Check
                                    type="radio"
                                    value={"Both"}
                                    name="type"
                                    checked={ApplicationState.is_sold_unsold}
                                    onChange={(e) => { MainCheckboxChange(e, "is_sold_unsold"); }}
                                />
                            </Form.Label>
                        </div>
                        {errorType !== "" && (<span className="error_medotory"><i className="bi bi-x-circle-fill"></i> {errorType}</span>)}
                    </Form.Group>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className="btn permissionbtn" onClick={() => SendMailFct()}>
                {Loader ? <Spinner /> : <i className="bi bi-envelope"></i>}&nbsp;&nbsp;Send
            </button>
        </Modal.Footer>
    </Modal>)
}

export default SendMailModal;